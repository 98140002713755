import React, { Component } from "react";
import LakeCityLogo from "../../images/portfolio/lakecity-logo";
import LakeCityBg from "../../images/portfolio/lakecity-bg.gif";

class PortfolioLakeCity extends Component {
  constructor(props) {
    super(props);
    const { isHovered } = this.props;
    this.state = {
      isHovered: isHovered
    };
  }
  componentDidUpdate(prevProps) {
    const { isHovered } = this.props;
    if (isHovered !== prevProps.isHovered) {
      this.setState({ isHovered });
    }
  }
  render() {
    return (
      <div className="portfolio-img animate-item">
        <div className="portfolio-thumb">
          <div id="lakecity-logo">
            <LakeCityLogo isHovered={this.state.isHovered} />
          </div>
        </div>
        <div className="portfolio-full">
          <img src={LakeCityBg} alt="Lakecity" />
        </div>
      </div>
    );
  }
}

export default PortfolioLakeCity;
