import React, { Component } from "react";

const logoBottomStyle = {
  transition: "stroke-dashoffset 2.5s ease-in-out 0s",
  strokeDasharray: "186.877, 186.877",
  strokeDashoffset: "0"
};

const logoTopStyle = {
  transition: "stroke-dashoffset 2.5s ease-in-out 0s",
  strokeDasharray: "187.293, 187.293",
  strokeDashoffset: "0"
};

class Logo extends Component {
  state = {};
  render() {
    return (
      <svg
        version="1.1"
        id="Layer_1"
        x="0px"
        y="0px"
        viewBox="0 0 75 75"
        enableBackground="new 0 0 75 75"
        xmlSpace="preserve"
      >
        <g>
          <defs>
            <rect
              id="LOGOSVGID_1_"
              x="4"
              y="29.251"
              width="72"
              height="46.785"
            />
          </defs>
          <clipPath id="LOGOSVGID_2_">
            <use xlinkHref="#LOGOSVGID_1_" overflow="visible" />
          </clipPath>
          <path
            className="logo-bottom"
            clipPath="url(#LOGOSVGID_2_)"
            fill="none"
            stroke="#000000"
            strokeWidth="3.9509"
            strokeMiterlimit="10"
            d="M36.407,34.23
        h13.788c5.934,0,10.745,4.811,10.745,10.745l0,0c0,5.934-4.811,10.744-10.745,10.744H10.48
        C16.239,66.023,27.255,72.99,39.899,72.99c18.6,0,33.678-15.078,33.678-33.678c0-3.585-0.56-7.039-1.598-10.28l-0.164-0.458"
            style={logoBottomStyle}
          />
        </g>
        <g>
          <defs>
            <rect
              id="LOGOSVGID_3_"
              x="4"
              y="3.964"
              width="72"
              height="46.305"
            />
          </defs>
          <clipPath id="LOGOSVGID_4_">
            <use xlinkHref="#LOGOSVGID_3_" overflow="visible" />
          </clipPath>
          <path
            className="logo-top"
            clipPath="url(#LOGOSVGID_4_)"
            fill="none"
            stroke="#000000"
            strokeWidth="3.9509"
            strokeMiterlimit="10"
            d="M43.476,45.071
        H29.689c-5.934,0-10.744-4.81-10.744-10.744l0,0c0-5.934,4.81-10.745,10.744-10.745h39.714
        C63.644,13.278,52.628,6.311,39.985,6.311c-18.6,0-33.679,15.079-33.679,33.678c0,3.586,0.56,7.04,1.598,10.28l0.345,0.838"
            style={logoTopStyle}
          />
        </g>
      </svg>
    );
  }
}

export default Logo;
