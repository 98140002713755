import React, { Component } from "react";
import { Helmet } from "react-helmet";
import ScrollMagic from "scrollmagic";
import TrackVisibility from "react-on-screen";
// import ReactGA from "react-ga";
import TagManager from "react-gtm-module";
import CTA from "./common/cta";
import Collapsible from "react-collapsible";

class Career extends Component {
  constructor(props) {
    super(props);
    this.controller = new ScrollMagic.Controller();
  }
  state = {
    label: ""
  };

  componentDidMount() {
    // ReactGA.pageview("/careers");
    const tagManagerArgs = {
      dataLayer: {
        event: "pageviewCustomEvent",
        pagePath: "/careers",
        pageTitle: "Careers"
      },
      dataLayerName: "PageDataLayer"
    };
    TagManager.dataLayer(tagManagerArgs);

    var self = this;
    new ScrollMagic.Scene({
      triggerElement: ".career",
      duration: 400 // scroll distance
    })
      .on("enter", function(e) {
        self.updateLabel("Careers");
      })
      .addTo(this.controller);
    new ScrollMagic.Scene({
      triggerElement: ".developer",
      duration: 400 // scroll distance
    })
      .on("enter", function(e) {
        self.updateLabel("Web Developer");
      })
      .addTo(this.controller);
    new ScrollMagic.Scene({
      triggerElement: ".designer",
      duration: 400 // scroll distance
    })
      .on("enter", function(e) {
        self.updateLabel("UI & UX Designer");
      })
      .addTo(this.controller);
    new ScrollMagic.Scene({
      triggerElement: ".project",
      duration: 400 // scroll distance
    })
      .on("enter", function(e) {
        self.updateLabel("Project Executive");
      })
      .addTo(this.controller);
    new ScrollMagic.Scene({
      triggerElement: ".internship",
      duration: 400 // scroll distance
    })
      .on("enter", function(e) {
        self.updateLabel("Internship");
      })
      .addTo(this.controller);
  }
  updateLabel = label => {
    this.setState({ label });
  };

  componentWillUnmount() {
    this.controller.destroy();
  }

  render() {
    return (
      <div id="page-career">
        <Helmet>
          <title>Careers - Simplicitic Innovations Sdn Bhd</title>
          <meta
            name="description"
            content="Digital agency based in Subang Jaya, Malaysia. We offer website design, app development, ecommerce setup and wordpress development"
          />
        </Helmet>
        <div className="label-container">
          <div className="label">{this.state.label}</div>
        </div>
        <TrackVisibility once>
          {({ isVisible }) => (
            <section
              className={`masthead text-center career wrapper ${
                isVisible ? "in-view" : ""
              }`}
            >
              <h1 className="animate-item">Join our team</h1>
              <p className="animate-item">
                If you like to deliver 'simple' solutions for complex business
                challenges, we welcome you to join us.
              </p>
            </section>
          )}
        </TrackVisibility>
        <section className="wrapper job-posts">
          <TrackVisibility once>
            {({ isVisible }) => (
              <div className={`${isVisible ? "in-view" : ""}`}>
                <Collapsible
                  trigger="Web & Mobile App Developer"
                  className="developer animate-item"
                  triggerTagName="div"
                >
                  <h4>Responsibilities:</h4>
                  <ul>
                    <li>
                      Develop functional and appealing web and mobile-based
                      applications based on usability
                    </li>
                    <li>
                      Be involved in establishing new ideas for web and mobile
                      solutions
                    </li>
                    <li>
                      Able to do entire development life cycle (concept, design,
                      build, deploy, test and support)
                    </li>
                    <li>
                      Working with the designer to build the interface with
                      focus on usability features
                    </li>
                    <li>
                      Develop code, maintain existing code bases,
                      troubleshooting, debugging and testing
                    </li>
                    <li>
                      Write high-quality, clean, maintainable and performance
                      driven codes
                    </li>
                  </ul>
                  <h4>Requirements:</h4>
                  <ul>
                    <li>
                      Hands-on experience in React, ReactNative, Firebase or
                      NodeJs
                    </li>
                    <li>
                      Working knowledge of PHP, MySQL, WordPress, and other CMS
                      development
                    </li>
                    <li>
                      Must have strong skills in HTML, JavaScript, CSS and JS
                      Frameworks
                    </li>
                    <li>Strong understanding of RESTful APIs</li>
                    <li>Familiar with Xcode, iOS SDK and Android SDK</li>
                    <li>
                      Ability to stay flexible in day-to-day work and meet
                      deadlines.
                    </li>
                  </ul>
                </Collapsible>
              </div>
            )}
          </TrackVisibility>
          <TrackVisibility once>
            {({ isVisible }) => (
              <div className={`${isVisible ? "in-view" : ""}`}>
                <Collapsible
                  trigger="UI & UX Designer"
                  className="designer animate-item"
                  triggerTagName="div"
                >
                  <p>
                    As UI & UX Designer, you will be deeply involved in design
                    and UX process from concept to outcome, high-level
                    discussions to fine detail. You’ll be the kind of person
                    that sees every task as being a part of a bigger picture,
                    from designing a great looking header to how it harmonizes
                    with the rest of the page and to how it contributes to the
                    full user journey.
                  </p>
                  <h4>Responsibilities</h4>
                  <ul>
                    <li>
                      Create creative design that are user-friendly, effective
                      and appealing
                    </li>
                    <li>
                      Create concepts, wireframes, and designs that deliver
                      compelling user experience, drive user behaviour and
                      communicate key ideas.
                    </li>
                    <li>
                      Liaise with Web Developers and Project Coordinator to
                      drive web development initiatives
                    </li>
                    <li>
                      Working with a variety of design and optimisation tools
                    </li>
                  </ul>
                  <h4>Requirements</h4>
                  <ul>
                    <li>
                      Strong Visual design talent and experience for different
                      digital mediums (web, mobile and others)
                    </li>
                    <li>
                      Proficient with design and wireframing/prototyping tools
                      such as Illustrator, Photoshop and others
                    </li>
                    <li>
                      Ability to both criticize and offer convincing
                      alternatives to existing designs
                    </li>
                    <li>
                      Ability to work collaboratively with design, development,
                      and marketing management
                    </li>
                    <li>
                      Broad knowledge of the web industry and software
                      development project lifecycles
                    </li>
                  </ul>
                </Collapsible>
              </div>
            )}
          </TrackVisibility>
          <TrackVisibility once>
            {({ isVisible }) => (
              <div className={`${isVisible ? "in-view" : ""}`}>
                <Collapsible
                  trigger="Project Executive"
                  className="project animate-item"
                  triggerTagName="div"
                >
                  <p>
                    Project executive is the person in charge of a specific
                    digital marketing/web project(s). As a project executive,
                    you are in charge for the whole production, manage and lead
                    in terms of project and controlling. Project executive work
                    closely with production team to make sure that the scope and
                    direction of each project is on schedule
                  </p>
                  <h4>Responsibilities</h4>
                  <ul>
                    <li>
                      Conceptualise and assist in managing digital marketing/web
                      solutions for client needs
                    </li>
                    <li>
                      Preparing of project requirements, UAT and any relevant
                      documentations.
                    </li>
                    <li>
                      Provide day-to-day monitoring to assigned project(s), to
                      ensure timely and accurate project execution
                    </li>
                    <li>Generate new ideas that would meet client's needs</li>
                    <li>
                      Work closely with production team to ensure the project
                      progress smoothly
                    </li>
                    <li>
                      Troubleshoot the problems and take necessary actions and
                      reporting
                    </li>
                    <li>Assist in new project pitches</li>
                  </ul>
                </Collapsible>
              </div>
            )}
          </TrackVisibility>
          <TrackVisibility once>
            {({ isVisible }) => (
              <div className={`${isVisible ? "in-view" : ""}`}>
                <Collapsible
                  trigger="Internship for Web Designer & Developer"
                  className="internship animate-item"
                  triggerTagName="div"
                >
                  <p>
                    We are always on the lookout for hardworking, enthusiastic
                    interns to assist on design, and development projects.
                  </p>
                  <h4>Responsibilities</h4>
                  <ul>
                    <li>Design and develop website</li>
                    <li>
                      Maintain website to ensure all contents are accurate and
                      up-to-date
                    </li>
                    <li>Design and develop e-newsletter</li>
                    <li>
                      Working with a variety of design and optimisation tools
                    </li>
                    <li>
                      Keeping up with the latest design software and
                      technologies
                    </li>
                  </ul>
                </Collapsible>
              </div>
            )}
          </TrackVisibility>
        </section>
        <section className="wrapper our-team">
          <TrackVisibility once>
            {({ isVisible }) => (
              <div className={isVisible ? "in-view" : ""}>
                <h3 className="text-center animate-item">How to apply?</h3>
                <p className="text-center animate-item">
                  Interested candidates may send your resume to{" "}
                  <b>hello@simplicitic.com</b>
                </p>
              </div>
            )}
          </TrackVisibility>
        </section>
        <CTA />
      </div>
    );
  }
}

export default Career;
