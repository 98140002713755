import React, { Component } from "react";
import Clock from "./clock";
import { workingHours } from "./workingHours";
import { Link } from "react-router-dom";

class CTA extends Component {
  renderMsg() {
    if (workingHours())
      return (
        <React.Fragment>
          <span className="mode">Online</span> Let's talk. Send us your
          requirement now
        </React.Fragment>
      );
    return (
      <React.Fragment>
        <span className="mode">Offline</span> We are closed now. Leave us a
        message
      </React.Fragment>
    );
  }

  render() {
    return (
      <div className="cta">
        <div className="wrapper">
          <div className="call-title">
            <Link to={"/contact"}>
              {this.renderMsg()} <span className="arrow"></span>
            </Link>
          </div>
          <div className="clock">
            <span>Time in Malaysia:</span> <Clock format={"dddd, h:mm a"} />
          </div>
        </div>
      </div>
    );
  }
}

export default CTA;
