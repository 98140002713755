import React, { Component } from "react";

class Member2 extends Component {
  state = {};
  render() {
    return (
      <svg
        id="Layer_1"
        x="0px"
        y="0px"
        viewBox="0 0 80 80"
        style={{ enableBackground: "new 0 0 80 80" }}
        xmlSpace="preserve"
      >
        <g className="member">
          <path
            d="M37.6,36.3c0.1,0,0.2,0.1,0.3,0.1c0.5,0.8,2.7,0.7,3.4,0.3c0.2-0.1,2.4-1.4,0.7-1.4c0,0,0,0,0,0c-0.9,0-2.9,0-3.8,0.2
           C37.6,35.4,36.9,36,37.6,36.3z"
          />
          <polygon points="50.1,48.2 50.1,48.2 50.1,48.2 	" />
          <polygon points="37.2,28 37.2,28 37.2,28 	" />
          <polygon points="48.4,28.9 48.4,28.9 48.4,28.9 	" />
          <path d="M45,25.8c0.7-0.9-1.6,0.3-1.8,0.5C41.8,28.2,44.4,26.7,45,25.8z" />
          <path d="M46.6,26.2c-0.4,0-2.4,1.3-1.9,1.9C45.3,28.8,47.4,26.3,46.6,26.2z" />
          <path d="M34.2,26.5c-0.2,0-2.1,1.7-1.7,2.1C33.1,29.2,34.8,26.5,34.2,26.5z" />
          <path d="M32.8,26c0.5-0.6-2.2,0.4-1.4,1.2C31.7,27.5,32.5,26.3,32.8,26z" />
          <path
            d="M29.9,23.6c0.1,0,0.2,0,0.2,0c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.3,0c1.5,0,2.9,0.1,4.4,0.2c0.2,0.1,0.4,0.1,0.6,0.1
           c0.3,0.3,0.5,0.2,0.6-0.3c-0.2-0.2-0.4-0.4-0.7-0.6c-1.6-1-3.5-0.9-5.3-0.5c-0.2,0-0.4,0-0.6,0.1C29.5,22.8,29.4,23.5,29.9,23.6z"
          />
          <path d="M9.6,69.4c0,0,0,0.1,0,0.1C9.5,69.5,9.6,69.4,9.6,69.4z" />
          <path
            d="M73.1,75.4c-0.9-6-1.3-14.7-7.5-17.6c-2.8-1.8-5.7-3.2-8.8-4.3c-0.3-0.2-0.6-0.5-0.9-0.7c0.1-0.1,0.1-0.3,0.1-0.4
           c0.1-0.6-2.9-2.9-3.4-3.5c-1-1-2.3-2.4-3.9-2.1c-0.4-3.1,0.1-6.2,1.1-9.2c0.8-1.6,1.5-3.3,2-4.9c3.2,0.6,6.5-6,4.7-8.2
           c0.6-0.7,0.5-1.4,0.6-2.3c0.1-1.7,0.1-3,0.4-4.7c0.6-2.8-0.3-4.6-2.7-6c-0.3-0.3-0.5-0.7-0.7-1.1c-0.1-0.7-0.1-1.2-0.5-1.8
           c-0.9-2-3.6-2.7-5.5-2.3c-0.2,0-0.4-0.1-0.6-0.2c-0.1,0-0.2-0.1-0.3-0.1c-1.6-1.4-2-3.2-4.4-3.8c-1.5-0.6-3.4-0.1-4.5,1.1
           c-0.4,0.3-0.6,0.6-0.9,1c-0.2,0.2-0.3,0.4-0.4,0.6c0,0,0,0,0,0c0,0,0,0,0,0c-0.1,0-0.1,0-0.2,0c-3.4-2.7-7.8,1-9.4,3.8
           c-0.9,1.2-0.8,2.5-1.3,3.8c-0.5,1.2-1.1,1.7-2,2.7c-3.4,3.1,0.1,5.7,1.6,8.2c-4.7-1-1.2,7.9,2.5,8.2c0.5,2.4,0.8,4.9,2.4,6.9
           c-0.6,0.8,0.4,3,0.5,3.8c0.2,1.8,0.2,3.6,0,5.4c-1.7,0.9-5.2,1.7-6.3,3.3c-0.5,0.5-1,1-1.3,1.6c-4.1,4-11.7,4-14.3,9.8
           c-0.2,1.2-0.5,2.3-0.7,3.5c0,0,0,0,0,0c-0.1,0.6-0.2,1.2-0.3,1.7c0,0,0,0,0,0c-0.5,3-0.7,6.1-1.2,9.1c2.4,2,2.5-5.6,2.6-6.8
           c0,0,0,0,0,0c0-0.1,0-0.1,0-0.2c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0-0.1,0-0.2,0-0.3c0,0.1,0,0.2,0,0.2
           c0.3-2.7,0.8-5.3,2-7.7c3-3.3,7.4-4.1,11-6.4c0.7-0.1,1.3-0.7,2-0.8c0.2,0,0.3,0,0.5,0c2,2,4.4,3.5,6.4,5.6c2.3,2.5,2,0.5,3.9-1.9
           c0.5,1.6,1.1,2.9,2.8,3.3c-0.7,1.3-1.5,5.5-1.4,6.9c-0.2,0.7-2.7,9.9,0.4,8c1-0.6,0.7-4.9,0.9-5.9c0.5-2.9,1.1-5.9,1.8-8.8
           c0.1,0,0.2,0,0.3,0c0,0.1,0,0.2,0,0.3c-0.8,0.9,1,6.4,1.2,7.8c0.2,1.7,0.4,3.7,1,5.3c0.2,0.8,1.2,0.9,1.7,0.5c0.7-0.6,0-2-0.1-2.8
           c-0.4-1.9-0.7-3.7-1.1-5.6c0.1-1.9-0.7-4.1-1.3-5.9c0.8-0.3,1.4-0.9,1.9-1.5c1.3,1.6,2.2,1.4,3.8,0.3c2.6-1.8,5-4.3,7.4-6.3
           c0.3,0.9,2.2,1.2,3,1.5c3.3,1.7,7.9,2.9,10.1,6c2.5,3.5,2.6,9.7,3.2,13.9c0,0.1,0,0.3,0.1,0.4C71.1,77.8,73.4,77.3,73.1,75.4z
            M31.4,6.6c0.6-0.3,1.2-0.3,1.9-0.2c0.7-0.2,1.5-0.3,2.2-0.2c0.2,0.1,0.4,0.2,0.6,0.2c0.2,0.1,0.3,0.2,0.5,0.2
           c0.3,0,0.6-0.1,0.9-0.3C37.8,6.2,38,6.1,38,5.8c0.2-0.3,0.4-0.6,0.5-1c1.2-1.3,3.1-1.7,4.7-0.9c1.7,1.4,1.2,3.6,3.6,4.3
           c1.8,0.5,3.4-1,5.1,0.8c0,0,0.1,0.1,0.1,0.1c0.5,1.5,0.4,2.6,1.6,3.8c0.7,0.7,1.7,0.7,2.4,1.5c0.1,0.1,0.2,0.3,0.3,0.5
           c0,0.1,0,0.1,0.1,0.2c0,1.3-1,2.2-1.3,3.5c-0.3,1.5,0.3,2.7,0.2,4.1c0,0.1,0,0.1,0,0.2c0.1-0.1-0.6,0.9-0.7,1.1
           c-0.4,0.1-0.9,0.3-1.1,0.7c-0.6-2.1-1.9-2.4-3.6-3c-0.1-0.1-0.3-0.1-0.4-0.2c-0.5-0.5-0.7-1.2-1.1-1.8c-0.6-1.5-2.1-1.6-3.4-1.3
           c-0.4-0.1-0.7-0.3-1-0.5c0,0,0,0-0.1-0.1c0-0.1-0.1-0.1-0.1-0.2c-0.5-1.4-0.2-2.2-1.4-3.2c-1-0.8-2.7-0.8-3.8-1.5c0,0,0,0-0.1-0.1
           c0,0,0-0.1,0-0.1c0-0.1-0.1-0.2-0.1-0.3c0-0.9-0.7-2.7-1.9-1.4c-0.1,0.1-0.1,0.2-0.2,0.2c-0.1,0.2-0.2,0.4-0.2,0.6c0,0,0,0.1,0,0.1
           c-0.1,0.2-0.2,0.4-0.4,0.6c-0.1,0-0.1,0.1-0.2,0.1c-1.7,0.6-3.7-0.3-4.8,1.7c-0.8,1.3,0.1,2.4-0.4,3.7c0,0.1-0.1,0.1-0.1,0.2
           c-0.1,0.1-0.1,0.1-0.2,0.2c-0.8,0.5-1.7,0.5-2.3,1.2c-0.1,0.2-0.2,0.3-0.3,0.5c-0.3,1-0.2,2,0,3c-0.1-0.1-0.1-0.2-0.2-0.2
           c-0.8-1.9-3.4-3.1-2.6-5.4c0.1-0.2,0.2-0.4,0.3-0.5c1.6-1.8,2.3-2.7,3-5c0.7-1.9,1.7-4.2,3.4-5.4C31.3,6.6,31.4,6.6,31.4,6.6z
            M28.8,20.6c2.6-1.8,2.6-1.8,2.8-4.9c0,0,0-0.1,0-0.1c0-0.1,0.1-0.2,0.1-0.2c1.2-1.4,3.3-0.5,4.6-1.6c0.8,1.8,3.3,2.3,5.1,2.6
           c0.1,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0.5,1.4,0.4,2.8,2.1,3.2c1.1,0.6,1.7,0.3,2.9,0.4c0.1,0,0.2,0,0.3,0.1
           c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0.1,0.1,0.2,0.3,0.2,0.4c-0.1,0.8,0.2,1.6,0.8,2.1c-0.7-0.3-1.7-0.3-2.4-0.3
           c-0.9-0.1-2.6-0.3-3.2,0.4c-1.2,1.4,2.5,0.8,3,0.9c-0.2,0,5.5,0.9,3.3-0.7c0.3,0.1,0.5,0.1,0.8,0.1c0.4,0.1,0.7,0.1,1.1,0.2
           c0.2,0.1,0.3,0.2,0.5,0.3c0.1,0.1,0.2,0.2,0.3,0.4c0.1,0.6,0,1.1,0,1.7c0,0-0.1,0-0.1,0c0,0,0,0,0,0c-0.6,0.1-1.4-0.1-2-0.1
           c0-0.4-0.5-1.9-1-1c-0.6-0.2-1.1-0.2-1.7-0.2c-1.7-0.3-5.4-1.4-5.6,1.1c-1.1-0.2-2.4-0.2-3.5-0.2c0-0.1,0-0.2,0-0.2
           c0-0.3-0.1-0.9-0.6-0.8c-1.7-0.2-7.8-1.6-8.1,1c-0.2,0-0.5,0.1-0.7,0c-0.1-0.4-0.1-0.8-0.2-1.2c0-0.1,0-0.3,0-0.4
           c0.2-0.9,0.1-1.8,0.4-2.6C28.5,20.9,28.6,20.7,28.8,20.6z M48.5,26.9c0,0.4-0.1,0.7-0.2,1.1c-0.3,0.5-0.8,1.1-1.4,1.4
           c-1.2,0.4-2.9,0.3-4-0.5c-0.6-0.5-0.7-1.4-0.8-2.1c0,0,0,0,0,0c0-0.6,0-1.1,0-1.7c1-0.1,2.1-0.2,3.1-0.1c1,0.2,2.2,0.5,3.2,0.4l0,0
           c0,0.3,0,0.5,0,0.8c-0.1,0.2,0,0.3,0,0.5C48.5,26.7,48.5,26.8,48.5,26.9z M41.9,25.1L41.9,25.1C41.9,25.1,41.9,25.1,41.9,25.1
           C41.9,25.1,41.9,25.1,41.9,25.1z M36.8,27.3c0,0.3-0.1,0.6-0.3,0.9c-2.3,2.5-6.2,1.9-6.8-1.7c0.3-0.3,0.3-0.7,0-1
           c0-0.2,0-0.4,0.1-0.6c0.3,0,0.7,0,1-0.1c0,0,0,0,0,0c2-0.3,4,0,6,0.2c0.1,0.3,0.1,0.5,0.2,0.8c0,0,0,0.1,0,0.1
           c-0.1,0.1-0.1,0.3,0,0.4C36.9,26.6,36.8,26.9,36.8,27.3z M28.5,27c0-0.1,0-0.1,0-0.2c0.2,0,0.4-0.1,0.5-0.1
           c0.6,4.3,5.5,5.5,7.8,1.8c0.2-0.1,0.4-0.3,0.4-0.5c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0,0.1c0.2-0.4,0.4-0.8,0.4-1.3
           c0.2,0,0.4,0,0.7,0c-0.4,1.7-2,5.2-0.7,6.7c1.4,1.6,4.8,0.6,1.1-0.7c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1-0.1-0.1-0.1-0.2-0.2
           c0,0,0-0.1,0-0.1c0-1.6,0.8-3.9,1.2-5.5c0.2,0,0.5,0.1,0.7,0.1c0.3,0.1,0.7,0.2,1.1,0.1c0.6,3.6,5,5,7.3,1.9c0,0,0,0,0,0
           c0,0,0,0,0-0.1c0,0,0,0,0,0c0.5-0.6,0.7-1.2,0.8-2c0.7,0,1.3,0,2,0.1c0.1,0,0.1,0,0.2,0c0,0.1,0,0.2,0,0.3c-0.8,0.9-0.7,3.2-1,4.4
           c-0.5,1.8-1.2,3.5-2,5.2c-2.1,4-4.1,5.3-8.3,6.2c-3.9-0.1-7.5-4.1-9.2-7.3C29.9,32.9,29.1,30,28.5,27z M35.9,53.9
           c-1.4-0.9-2.6-1.9-3.8-3.1c0.1-0.3,0.1-0.6,0.2-0.9c1.6-2.7,1-6.2,0.4-9.1c1.6,1.3,3.2,2.7,5.1,3.5c0.6,1.5,4.5,0.4,5.4,0.2
           c0.3,0,0.6,0,0.8-0.1c0.2,0,0.5-0.1,0.7-0.3c1-0.4,1.7-1.1,2.3-2c-0.3,1.8-0.9,6.3,0.4,7.9c0,0.1,0,0.1,0,0.2c0,0,0,0,0,0
           c-1.3,1.4-3.4,2.4-5,3.4c-0.9,0.5-2.8,1-3.4,1.8c-0.9-0.4-1.9-0.8-2.8-1.3C36.1,54,36,54,35.9,53.9z M55.7,26.9c-0.3,2-1.8,3-3.4,4
           c0.2-0.8,0.9-2.5,0.5-3.4c0.1-0.2,0.2-0.4,0.3-0.7c0.3-0.2,0.4-0.4,0.4-0.7c0.2-0.3,0.4-0.5,0.7-0.8c0.1,0,0.1-0.1,0.2-0.1
           c0.2-0.1,0.3-0.1,0.5-0.1c0.1,0.1,0.3,0.1,0.4,0.1c0,0,0,0,0.1,0.1c0,0.1,0.1,0.1,0.1,0.2C55.7,25.9,55.7,26.4,55.7,26.9z
            M26.7,29.1c-1.1-0.9-2.3-2-2.2-3.5c0-0.2,0.1-0.3,0.1-0.5c0-0.1,0.1-0.1,0.1-0.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1-0.1,0.2-0.1
           c0.1,0,0.3-0.1,0.4-0.1c1,0.2,1.5,1.3,1.8,2.2c0.1,1,0.2,2,0.4,3C27.4,29.6,27,29.4,26.7,29.1z M34.3,57.9
           c-0.6,0.7-1.2,1.5-1.6,2.3c-1.6-3-5.5-4-7.1-6.9c0-0.1,0-0.1-0.1-0.2c0-0.1,0-0.3,0-0.4c0-0.2,0.1-0.5,0.3-0.7c0.5-0.3,1-0.6,1.5-1
           c1-0.3,2-0.8,2.9-1.3c-0.2,0.2-0.3,0.3-0.3,0.5c-0.2,1,1,1.9,1.6,2.5c0,0-0.1-0.1-0.1-0.1c0.1,0,0.1,0.1,0.2,0.1c0,0,0,0,0,0
           c1.7,1.3,3.5,2.2,5.3,3.2C35.9,56.5,35.1,57,34.3,57.9z M42,59.4c-0.2,0.3-0.6,0.6-0.9,0.8c-0.3,0.1-0.6,0.1-1,0.2
           c-0.7,0-1.4-0.1-2.1-0.3c-0.1,0-0.2-0.1-0.3-0.2c-0.1-0.1-0.2-0.2-0.3-0.3C37,59,37.1,58,36.6,57.4c0.5-0.3,1-0.6,1.6-0.8
           c0.6,0.3,1.3,0.5,1.9,0.5c0.2,0,0.5,0,0.7-0.1c0.6,0.6,1.1,1.1,1.6,1.8C42.2,59,42.1,59.2,42,59.4z M53.4,53.5
           c-1.2,0.9-2.4,1.9-3.6,2.8c0,0,0.1-0.1,0.1-0.1c-1.4,1.1-2.9,2.8-4.6,3.6c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0
           c0,0-0.1,0-0.1-0.1c-1.1-0.9-1.8-2.3-2.8-3.3c0.8-0.4,1.5-0.9,2.2-1.5c1.3-0.9,4.8-2.7,4.8-4.6c0.6-0.5,0.4-2.6-0.6-2.8
           c0,0,0,0,0,0c0.5,0.1,1,0.4,1.4,0.7c0,0,0,0-0.1-0.1c0,0,0.1,0.1,0.1,0.1c0,0,0,0,0,0c1.6,1.3,2.5,3.2,4.4,4.2
           C54.2,52.8,53.8,53.2,53.4,53.5z"
          />
          <path d="M31.7,53C31.7,52.9,31.7,52.9,31.7,53C31.7,52.9,31.7,52.9,31.7,53z" />
          <path d="M31.6,52.9C31.6,52.9,31.6,52.9,31.6,52.9C31.6,52.9,31.6,52.9,31.6,52.9C31.6,52.9,31.6,52.9,31.6,52.9z" />
          <path d="M35.7,73.8C35.7,73.8,35.7,73.7,35.7,73.8C35.7,73.7,35.7,73.8,35.7,73.8z" />
        </g>
      </svg>
    );
  }
}

export default Member2;
