import React, { Component } from "react";
import TrackVisibility from "react-on-screen";
// import ReactGA from "react-ga";
import TagManager from "react-gtm-module";
// import { finalConfig } from "../../config";

class Portfolio extends Component {
  constructor() {
    super();
    this.state = {
      isHovered: false
    };
    this.handleHover = this.handleHover.bind(this);
  }
  handleHover() {
    this.setState(prevState => ({
      isHovered: !prevState.isHovered
    }));
  }
  handleClick = (link, name) => {
    // const tagManagerArgs = {
    //   // dataLayer: {
    //   //   events: {
    //   //     category: "Portfolio",
    //   //     action: "View Project - " + name
    //   //   }
    //   // },
    //   // dataLayerName: "PageDataLayer"
    //   events: {
    //     sendUserInfo: "userInfo"
    //   }
    // };
    // TagManager.dataLayer(tagManagerArgs);
    const tagManagerArgs = {
      // gtmId: finalConfig.gtmId,
      dataLayer: {
        event: "portfolioclickCustomEvent",
        projectName: name,
        projectURL: link
      },
      dataLayerName: "PageDataLayer"
    };
    TagManager.dataLayer(tagManagerArgs);
    // TagManager.initialize(tagManagerArgs);
    window.open(link, "_blank");
    // ReactGA.event({
    //   category: "Portfolio",
    //   action: "View Project - " + name
    // });
  };
  render() {
    const { id, name, portfolio, desc, link, className } = this.props;
    const containerClass = this.state.isHovered ? "active" : "";
    return (
      <TrackVisibility once partialVisibility>
        {({ isVisible }) => (
          <div
            id={`portfolio-${id}`}
            className={`portfolio-container ${className} ${containerClass} 
            ${link ? "clickable" : ""} 
            ${isVisible ? "in-view" : ""}`}
            onMouseEnter={this.handleHover}
            onMouseLeave={this.handleHover}
            onClick={() => link && this.handleClick(link, name)}
          >
            {portfolio}
            <div className="portfolio-content animate-item">
              <h4 className="portfolio-title">{name}</h4>
              <div className="portfolio-desc">{desc}</div>
              {link && <div className="btn btn-secondary">View Project</div>}
            </div>
          </div>
        )}
      </TrackVisibility>
    );
  }
}

export default Portfolio;
