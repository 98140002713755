import React, { Component } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import NaturalOrientThumb from "../../images/portfolio/natural-orient-thumb.jpg";
import NaturalOrient1 from "../../images/portfolio/natural-orient-1.jpg";
import NaturalOrient2 from "../../images/portfolio/natural-orient-2.jpg";
import NaturalOrient3 from "../../images/portfolio/natural-orient-3.jpg";
import NaturalOrient4 from "../../images/portfolio/natural-orient-4.jpg";
import NaturalOrient5 from "../../images/portfolio/natural-orient-5.jpg";

class PortfolioNaturalOrient extends Component {
  state = {};
  render() {
    return (
      <div className="portfolio-img animate-item">
        <div className="portfolio-thumb">
          <img src={NaturalOrientThumb} alt="Natural Orient" />
        </div>
        <div className="portfolio-full">
          <Carousel
            autoPlay={true}
            showThumbs={false}
            stopOnHover={false}
            showIndicators={false}
            interval={2000}
            infiniteLoop={true}
            showStatus={false}
            showArrows={false}
          >
            <div>
              <img src={NaturalOrient1} alt="Natural Orient" />
            </div>
            <div>
              <img src={NaturalOrient2} alt="Natural Orient" />
            </div>
            <div>
              <img src={NaturalOrient3} alt="Natural Orient" />
            </div>
            <div>
              <img src={NaturalOrient4} alt="Natural Orient" />
            </div>
            <div>
              <img src={NaturalOrient5} alt="Natural Orient" />
            </div>
          </Carousel>
        </div>
      </div>
    );
  }
}

export default PortfolioNaturalOrient;
