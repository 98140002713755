import React, { Component } from "react";

class Illustrator extends Component {
  render() {
    return (
      <svg
        version="1.1"
        id="Layer_1"
        x="0px"
        y="0px"
        viewBox="0 0 550 180"
        style={{ enableBackground: "new 0 0 550 180" }}
        xmlSpace="preserve"
      >
        <g id="Icon">
          <radialGradient
            id="AI_GID_"
            cx="-189.0349"
            cy="18.2646"
            r="5.625280e-02"
            gradientTransform="matrix(2286.3789 0 0 -2213.6306 432268.8125 40475.7109)"
            gradientUnits="userSpaceOnUse"
          >
            <stop
              offset="0"
              style={{ stopColor: "#423325", stopOpacity: "0.98" }}
            />
            <stop offset="1" style={{ stopColor: "#1C0A00" }} />
          </radialGradient>
          <path fill="url(#AI_GID_)" d="M7.7,7.7h169.2v164.6H7.7V7.7z" />
          <path
            fill="#FF7F18"
            d="M7.7,7.7h169.2v164.6H7.7V7.7z M0,180h184.6V0H0V180z M120.9,64.2c0-0.6,0.2-0.9,0.9-0.9h12.1
		c0.6,0,0.9,0.2,0.9,0.9v60.7c0,0.6-0.2,0.9-0.9,0.9H122c-0.8,0-1-0.4-1-1L120.9,64.2L120.9,64.2L120.9,64.2z M120.1,46.7
		c0-4.9,3.5-7.8,7.8-7.8c4.7,0,7.8,3.2,7.8,7.8c0,5.1-3.3,7.8-8,7.8C123.3,54.5,120.1,51.8,120.1,46.7z M85.5,89.5
		c-2.2-8.5-7.2-27.2-9.2-36.2h-0.2c-1.6,9-5.7,24.2-8.9,36.2H85.5z M64.1,101.9L58,125c-0.2,0.6-0.4,0.8-1.2,0.8H45.5
		c-0.8,0-0.9-0.2-0.8-1.2l21.8-76.5c0.4-1.4,0.6-2.6,0.8-6.4c0-0.5,0.2-0.8,0.6-0.8h16.2c0.5,0,0.8,0.2,0.9,0.8l24.5,83
		c0.2,0.6,0,1-0.6,1H96.2c-0.6,0-1-0.2-1.2-0.7l-6.3-23.2H64.1L64.1,101.9z"
          />
        </g>
        <g id="Text">
          <path
            fill="#1D1D1B"
            className="darkFill"
            d="M227,58.4l-5.3,16.1h-6.8l17.4-51.2h8l17.5,51.2h-7.1l-5.5-16.1H227z M243.8,53.2l-5-14.7
		c-1.1-3.3-1.9-6.4-2.7-9.3H236c-0.8,3-1.6,6.2-2.6,9.3l-5,14.8H243.8z"
          />
          <path
            fill="#1D1D1B"
            className="darkFill"
            d="M295.8,20.5V65c0,3.3,0.1,7,0.3,9.5h-6l-0.3-6.4h-0.2c-2.1,4.1-6.5,7.2-12.5,7.2c-8.9,0-15.7-7.5-15.7-18.7
		c-0.1-12.2,7.5-19.8,16.5-19.8c5.6,0,9.4,2.7,11.1,5.6h0.2v-22H295.8z M289.2,52.7c0-0.8-0.1-2-0.3-2.8c-1-4.3-4.6-7.8-9.7-7.8
		c-6.9,0-11,6.1-11,14.2c0,7.4,3.6,13.6,10.9,13.6c4.5,0,8.6-3,9.8-8c0.2-0.9,0.3-1.8,0.3-2.9V52.7z"
          />
          <path
            fill="#1D1D1B"
            className="darkFill"
            d="M340.2,55.8c0,13.6-9.4,19.5-18.3,19.5c-10,0-17.6-7.3-17.6-18.9c0-12.3,8.1-19.5,18.2-19.5
		C333.1,36.9,340.2,44.6,340.2,55.8z M311,56.2c0,8.1,4.6,14.1,11.2,14.1c6.4,0,11.2-6,11.2-14.3c0-6.2-3.1-14.1-11-14.1
		C314.5,41.9,311,49.2,311,56.2z"
          />
          <path
            fill="#1D1D1B"
            className="darkFill"
            d="M348.4,74.5c0.2-2.5,0.3-6.2,0.3-9.5V20.5h6.6v23.1h0.2c2.4-4.1,6.6-6.8,12.5-6.8c9.1,0,15.6,7.6,15.5,18.8
		c0,13.1-8.3,19.7-16.5,19.7c-5.3,0-9.6-2.1-12.3-6.9h-0.2l-0.3,6.1H348.4z M355.3,59.8c0,0.8,0.2,1.7,0.3,2.4
		c1.3,4.6,5.2,7.8,10,7.8c7,0,11.2-5.7,11.2-14.1c0-7.4-3.8-13.7-10.9-13.7c-4.6,0-8.8,3.1-10.2,8.2c-0.2,0.8-0.4,1.7-0.4,2.7V59.8z
		"
          />
          <path
            fill="#1D1D1B"
            className="darkFill"
            d="M395.6,57.3c0.2,9,5.9,12.8,12.6,12.8c4.8,0,7.7-0.8,10.2-1.9l1.1,4.8c-2.4,1.1-6.4,2.3-12.2,2.3
		c-11.3,0-18.1-7.4-18.1-18.5c0-11.1,6.5-19.8,17.3-19.8c12,0,15.2,10.6,15.2,17.3c0,1.4-0.2,2.4-0.2,3.1H395.6z M415.2,52.5
		c0.1-4.3-1.7-10.9-9.3-10.9c-6.8,0-9.7,6.2-10.3,10.9H415.2z"
          />
          <path
            fill="#1D1D1B"
            className="darkFill"
            d="M227.7,105.3v51.2h-9.3v-51.2H227.7z"
          />
          <path
            fill="#1D1D1B"
            className="darkFill"
            d="M238.5,102.5h9.4v54h-9.4V102.5z"
          />
          <path
            fill="#1D1D1B"
            className="darkFill"
            d="M258,102.5h9.4v54H258V102.5z"
          />
          <path
            fill="#1D1D1B"
            className="darkFill"
            d="M310.7,145.4c0,4.4,0.2,8.1,0.3,11.1h-8.2l-0.5-5.6h-0.2c-1.6,2.7-5.2,6.5-11.9,6.5c-6.8,0-12.9-4-12.9-16.1
		v-21.7h9.3v20.1c0,6.2,2,10.1,6.9,10.1c3.7,0,6.2-2.7,7.1-5c0.3-0.8,0.5-1.8,0.5-2.9v-22.3h9.3V145.4z"
          />
          <path
            fill="#1D1D1B"
            className="darkFill"
            d="M320.3,147.9c2.1,1.3,6.2,2.7,9.5,2.7c4.1,0,5.9-1.7,5.9-4.1c0-2.5-1.5-3.8-6.1-5.4
		c-7.2-2.5-10.3-6.5-10.2-10.8c0-6.5,5.4-11.6,14-11.6c4.1,0,7.7,1.1,9.8,2.2l-1.8,6.6c-1.6-0.9-4.6-2.1-7.8-2.1
		c-3.3,0-5.2,1.6-5.2,3.9c0,2.4,1.7,3.5,6.5,5.2c6.7,2.4,9.8,5.9,9.9,11.3c0,6.7-5.2,11.6-15,11.6c-4.5,0-8.5-1.1-11.2-2.6
		L320.3,147.9z"
          />
          <path
            fill="#1D1D1B"
            className="darkFill"
            d="M363.2,108.9v10.6h8.9v7h-8.9v16.3c0,4.5,1.2,6.8,4.8,6.8c1.6,0,2.8-0.2,3.6-0.5l0.2,7.1
		c-1.4,0.5-3.8,0.9-6.8,0.9c-3.4,0-6.3-1.1-8.1-3c-2-2.1-2.9-5.5-2.9-10.3v-17.4h-5.3v-7h5.3v-8.4L363.2,108.9z"
          />
          <path
            fill="#1D1D1B"
            className="darkFill"
            d="M379.1,131.4c0-5-0.1-8.6-0.3-11.9h8.1l0.3,7.1h0.3c1.8-5.2,6.2-7.9,10.1-7.9c0.9,0,1.4,0.1,2.2,0.2v8.8
		c-0.8-0.2-1.6-0.3-2.7-0.3c-4.5,0-7.5,2.9-8.4,7.1c-0.2,0.8-0.3,1.8-0.3,2.9v19.2h-9.3V131.4z"
          />
          <path
            fill="#1D1D1B"
            className="darkFill"
            d="M426.3,156.5l-0.6-4.1h-0.2c-2.3,2.9-6.2,4.9-10.9,4.9c-7.4,0-11.6-5.4-11.6-11c0-9.3,8.3-14.1,22-14v-0.6
		c0-2.4-1-6.5-7.5-6.5c-3.6,0-7.4,1.1-10,2.7l-1.8-6.1c2.7-1.7,7.5-3.3,13.4-3.3c11.9,0,15.3,7.5,15.3,15.6v13.4
		c0,3.3,0.2,6.6,0.5,8.9H426.3z M425.1,138.4c-6.6-0.2-12.9,1.3-12.9,6.9c0,3.6,2.4,5.3,5.3,5.3c3.7,0,6.5-2.4,7.3-5.1
		c0.2-0.7,0.3-1.4,0.3-2.1V138.4z"
          />
          <path
            fill="#1D1D1B"
            className="darkFill"
            d="M454.4,108.9v10.6h8.9v7h-8.9v16.3c0,4.5,1.2,6.8,4.8,6.8c1.6,0,2.8-0.2,3.6-0.5l0.2,7.1
		c-1.4,0.5-3.8,0.9-6.8,0.9c-3.4,0-6.3-1.1-8.1-3c-2-2.1-2.9-5.5-2.9-10.3v-17.4h-5.3v-7h5.3v-8.4L454.4,108.9z"
          />
          <path
            fill="#1D1D1B"
            className="darkFill"
            d="M505.1,137.6c0,13.6-9.6,19.7-19,19.7c-10.5,0-18.5-7.2-18.5-19.1c0-12.2,8-19.6,19.2-19.6
		C497.7,118.6,505.1,126.4,505.1,137.6z M477.2,138c0,7.1,3.5,12.5,9.2,12.5c5.3,0,9-5.2,9-12.7c0-5.8-2.6-12.4-9-12.4
		C479.8,125.5,477.2,131.9,477.2,138z"
          />
          <path
            fill="#1D1D1B"
            className="darkFill"
            d="M512.7,131.4c0-5-0.1-8.6-0.3-11.9h8.1l0.3,7.1h0.3c1.8-5.2,6.2-7.9,10.1-7.9c0.9,0,1.4,0.1,2.2,0.2v8.8
		c-0.8-0.2-1.6-0.3-2.7-0.3c-4.5,0-7.5,2.9-8.4,7.1c-0.2,0.8-0.3,1.8-0.3,2.9v19.2h-9.3V131.4z"
          />
        </g>
      </svg>
    );
  }
}

export default Illustrator;
