import moment from "moment-timezone";

export function workingHours() {
  const WORKING_HOUR = {
    start: moment("09:30:00", "hh:mm:ss"),
    end: moment("18:30:00", "hh:mm:ss")
  };
  if (
    moment()
      .tz("Asia/Kuala_Lumpur")
      .day() < 1 ||
    moment()
      .tz("Asia/Kuala_Lumpur")
      .day() > 5
  )
    return false;
  if (
    !moment()
      .tz("Asia/Kuala_Lumpur")
      .isBetween(WORKING_HOUR.start, WORKING_HOUR.end)
  )
    return false;
  return true;
}
