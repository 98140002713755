import React, { Component } from "react";

class LakeCityLogo extends Component {
  constructor(props) {
    super(props);
    const { isHovered } = this.props;
    this.state = {
      isHovered: isHovered
    };
  }
  componentDidUpdate(prevProps) {
    const { isHovered } = this.props;
    if (isHovered !== prevProps.isHovered) {
      this.setState({ isHovered, initiated: true });
    }
  }
  render() {
    const { isHovered, initiated } = this.state;
    if (isHovered) {
      this.svgAnimate.beginElement();
    } else {
      if (initiated === true) this.svgAnimate.endElement();
    }
    return (
      <svg
        x="0px"
        y="0px"
        viewBox="0 0 340 240"
        style={{ enableBackground: "new 0 0 340 240" }}
      >
        {/* <style type="text/css">
			.st0{fill:#FFFFFF;}

	  		.lakecity-logo-stop1 { stop-color: #a25437; }
	        .lakecity-logo-stop2 { stop-color: #ffffff; }
	        .lakecity-logo-stop3 { stop-color: #a25437; }
		</style> */}
        <defs>
          <linearGradient
            id="lakecity-gradient"
            x1="0%"
            y1="0%"
            gradientUnits="userSpaceOnUse"
            gradientTransform="rotate(0)"
            x2="100%"
            y2="100%"
          >
            <stop className="lakecity-logo-stop1" offset="0%" />
            <stop className="lakecity-logo-stop2" offset="50%">
              {" "}
              <animate
                id="lakecity-logo-animate"
                attributeName="offset"
                dur="3s"
                values="0;1;0"
                repeatCount="indefinite"
                begin="indefinite"
                ref={svgAnimate => {
                  this.svgAnimate = svgAnimate;
                }}
              />
            </stop>
            <stop className="lakecity-logo-stop3" offset="100%" />
          </linearGradient>
          <mask id="lakecity-mask">
            <g>
              <path
                id="XMLID_28_"
                className="st0"
                d="M31.7,187.4c-0.4,1-0.8,1.8-1.4,2.6c-0.8,1.1-1.9,1.9-3.1,2.4c-1.3,0.5-2.9,0.8-4.7,0.8
						c-1.8,0-3.2-0.3-4.3-0.8c-1.1-0.6-1.9-1.4-2.4-2.7c-0.3-0.7-0.5-1.6-0.6-2.7c-0.1-1.1-0.2-2.8-0.2-5v-14.5c0-1.8,0.1-3.1,0.3-3.9
						c0.2-0.8,0.5-1.5,1.1-2.1c0.4-0.4,0.9-0.8,1.5-1c0.5-0.2,1.3-0.3,2.1-0.4v-0.6h-15v0.6c0.8,0,1.5,0.2,2.1,0.4c0.5,0.2,1,0.5,1.5,1
						c0.5,0.5,0.9,1.2,1.1,2.1c0.2,0.8,0.3,2.2,0.3,3.9v18.5c0,1.7-0.1,3-0.3,3.8c-0.2,0.8-0.5,1.5-1,2.1c-0.9,1-2.1,1.4-3.7,1.4v0.6
						h28.1l0.3-10.3l-0.6,0C32.4,185.2,32.1,186.5,31.7,187.4z"
              />
              <path
                className="st0"
                d="M49.4,191.3c-0.3-0.5-0.4-1.1-0.4-1.7c0-1.1,0.4-2.5,1.1-4.3l0.6-1.6l1.2-3.5h10.4l2.3,5.9l0.4,1.1
						c0.4,1.2,0.7,2.1,0.7,2.8c0,1.2-0.5,2.1-1.5,2.7c-0.5,0.4-1.5,0.6-2.9,0.8v0.6h15.5v-0.6c-1-0.1-1.7-0.3-2.2-0.4
						c-0.5-0.2-0.9-0.4-1.3-0.7c-0.5-0.4-1-1.1-1.5-1.9c-0.5-0.9-1.1-2.3-1.8-4.3l-10.2-26.4h-1.3l-8.6,23.7c-0.8,2.2-1.4,3.8-1.9,4.9
						c-0.5,1.1-1,2-1.5,2.7c-0.6,0.9-1.2,1.5-1.8,1.9c-0.6,0.4-1.4,0.6-2.2,0.7v0.6h10.5v-0.6c-1-0.1-1.7-0.4-2.4-0.8
						C50,192.3,49.7,191.9,49.4,191.3z M56.9,166.2l4.9,13h-9.6L56.9,166.2z"
              />
              <path
                id="XMLID_25_"
                className="st0"
                d="M219.1,189.5c-0.8,1.2-1.9,2.1-3.3,2.8c-1.4,0.7-3,1-4.8,1c-3.6,0-6.4-1.3-8.6-4
						c-1.2-1.4-2.1-3.2-2.7-5.3c-0.6-2.1-0.9-4.5-0.9-7.1c0-3.1,0.4-5.9,1.3-8.3c0.6-1.7,1.4-3.3,2.4-4.6c1-1.3,2.1-2.4,3.2-3.1
						c1.3-0.8,2.8-1.2,4.6-1.2c1.2,0,2.2,0.2,3.2,0.6c0.9,0.4,1.8,1.1,2.7,1.9c1.2,1.2,2,2.5,2.7,3.9c0.3,0.8,0.8,2,1.3,3.6
						c0.1,0.3,0.2,0.6,0.4,1.1h0.5l-0.3-11.2h-0.6c-0.2,0.4-0.3,0.7-0.4,0.8c-0.3,0.4-0.6,0.5-1,0.5c-0.3,0-0.6-0.1-1-0.2l-1.9-0.7
						c-2.1-0.8-4.1-1.2-6-1.2c-2,0-4,0.4-6,1.1c-2,0.7-3.7,1.7-5.1,2.9c-1.8,1.6-3.3,3.7-4.3,6.2c-1,2.6-1.6,5.4-1.6,8.5
						c0,4,0.9,7.5,2.7,10.3c1.8,2.9,4.2,4.9,7.4,6c2,0.7,4,1,6.2,1c2.3,0,4.4-0.4,6.1-1.2c1.8-0.8,3.1-1.9,4.2-3.4c1-1.6,1.7-3.7,2-6.3
						l-0.5-0.1C220.7,186.2,220.1,188.1,219.1,189.5z"
              />
              <polygon
                id="XMLID_24_"
                className="st0"
                points="233.3,219.5 225.4,219.5 225.4,213.3 224,213.3 224,227.2 225.4,227.2 225.4,220.8 
						233.3,220.8 233.3,227.2 234.7,227.2 234.7,213.3 233.3,213.3 		"
              />
              <path
                id="XMLID_23_"
                className="st0"
                d="M244.1,164.5c0.1-1.2,0.5-2.1,1.1-2.8c0.4-0.5,0.9-0.8,1.5-1.1c0.6-0.2,1.3-0.3,2.2-0.4v-0.6
						h-15v0.6c0.9,0,1.6,0.2,2.2,0.4c0.6,0.2,1.1,0.6,1.5,1.1c0.5,0.5,0.8,1.2,1,2c0.2,0.8,0.3,2.1,0.3,3.8v18.5c0,1.7-0.1,3-0.3,3.8
						c-0.2,0.8-0.5,1.5-1,2c-0.4,0.5-0.9,0.8-1.5,1c-0.6,0.2-1.3,0.4-2.2,0.4v0.6h15v-0.6c-1.1-0.1-1.9-0.3-2.6-0.6
						c-0.6-0.3-1.2-0.8-1.6-1.5c-0.3-0.5-0.5-1.1-0.6-1.9c-0.1-0.8-0.2-1.9-0.2-3.3v-18.5C244,166.5,244,165.4,244.1,164.5z"
              />
              <rect
                id="XMLID_22_"
                x="246.9"
                y="220.9"
                className="st0"
                width="33.9"
                height="0.7"
              />
              <path
                className="st0"
                d="M196.6,220.6c0.5-0.2,0.9-0.5,1.2-0.8c0.3-0.3,0.6-0.7,0.8-1.1c0.2-0.4,0.3-0.9,0.3-1.5
						c0-0.6-0.1-1.2-0.4-1.7c-0.3-0.5-0.6-0.9-1.1-1.2c-0.5-0.3-1-0.5-1.6-0.7c-0.6-0.2-1.3-0.2-1.9-0.2h-3.3v13.9h1.4v-5.8h1
						c0.1,0,0.3,0,0.4,0c0.1,0,0.3,0,0.4,0l4.7,5.8h1.7l-5-6.1C195.7,221,196.2,220.8,196.6,220.6z M193.1,220.2h-1.1v-5.7h1.9
						c0.5,0,0.9,0.1,1.4,0.1c0.4,0.1,0.8,0.3,1.2,0.5c0.3,0.2,0.6,0.5,0.8,0.8c0.2,0.3,0.3,0.8,0.3,1.2c0,0.5-0.1,1-0.4,1.4
						c-0.2,0.4-0.6,0.7-1,0.9c-0.4,0.2-0.9,0.4-1.4,0.6C194.2,220.1,193.6,220.2,193.1,220.2z"
              />
              <path
                id="XMLID_19_"
                className="st0"
                d="M260.3,159.7l-0.4,9.3l0.5,0c0.4-2,0.9-3.6,1.6-4.8c0.8-1.3,1.7-2.2,2.9-2.7
						c1.2-0.5,3-0.8,5.4-1c1.1-0.1,2.2-0.1,3.2-0.1v25.6c0,1.7-0.1,3-0.3,3.8c-0.2,0.8-0.5,1.5-1,2c-0.4,0.5-0.9,0.8-1.5,1.1
						c-0.6,0.2-1.3,0.4-2.2,0.4v0.6h15v-0.6c-0.9,0-1.6-0.2-2.2-0.4c-0.6-0.2-1.1-0.6-1.5-1.1c-0.5-0.5-0.8-1.2-1-2.1
						c-0.2-0.8-0.3-2.1-0.3-3.8v-25.6c3.5,0,6,0.2,7.4,0.7c1.7,0.5,3,1.4,3.9,2.6c0.9,1.2,1.6,3,2,5.3l0.5,0l-0.4-9.3H260.3z"
              />
              <path
                id="XMLID_18_"
                className="st0"
                d="M324,159.7v0.6c1.2,0,2.1,0.3,2.7,0.9c0.6,0.6,1,1.4,1,2.5c0,0.9-0.3,1.9-0.9,2.9l-0.6,1
						l-5.5,9.1l-6.4-10.7c-0.4-0.7-0.8-1.3-0.9-1.8c-0.2-0.5-0.3-0.9-0.3-1.3c0-1.1,0.4-1.8,1.3-2.2c0.4-0.2,1.2-0.3,2.5-0.4v-0.6
						h-15.3v0.6c0.7,0,1.3,0.1,1.6,0.2c0.4,0.1,0.8,0.3,1.2,0.5c0.8,0.5,1.6,1.3,2.4,2.6l1.1,1.8l6.1,9.8c0.9,1.5,1.5,2.7,1.8,3.7
						c0.3,0.9,0.4,1.6,0.4,2.3l0,4.9c0,1.7-0.1,3-0.3,3.8c-0.2,0.8-0.5,1.5-1,2c-0.4,0.5-0.9,0.8-1.5,1.1c-0.6,0.2-1.3,0.4-2.2,0.4v0.6
						h15v-0.6c-1.6,0-2.8-0.5-3.7-1.5c-0.5-0.6-0.8-1.2-1-2c-0.2-0.8-0.2-2.1-0.2-3.9V180c0-1.2,0.1-2,0.2-2.5c0.1-0.5,0.5-1.2,1.2-2.3
						l5.4-8.7c1.3-2.1,2.5-3.7,3.6-4.8c0.5-0.4,0.9-0.8,1.3-0.9c0.4-0.2,1.1-0.3,1.9-0.4v-0.6H324z"
              />
              <rect
                id="XMLID_17_"
                x="58.9"
                y="220.9"
                className="st0"
                width="33.9"
                height="0.7"
              />
              <path
                id="XMLID_16_"
                className="st0"
                d="M135.3,189.9c-0.2,0.8-0.5,1.5-1,2.1c-0.4,0.5-0.9,0.8-1.5,1.1c-0.6,0.2-1.3,0.4-2.2,0.4v0.6
						h28.1l0.3-10l-0.5,0c-0.3,1.5-0.6,2.7-1,3.6c-0.4,0.9-0.8,1.7-1.4,2.5c-0.8,1.1-1.8,1.9-3.1,2.4c-1.3,0.5-2.8,0.8-4.7,0.8
						c-1.8,0-3.2-0.3-4.3-0.8c-1.1-0.6-1.9-1.4-2.4-2.7c-0.3-0.7-0.5-1.6-0.6-2.6c-0.1-1.1-0.2-2.7-0.2-5v-5.8h1.3
						c1.2,0,2.1,0.1,2.9,0.4c0.8,0.2,1.4,0.6,1.8,1.2c0.5,0.6,0.9,1.2,1.1,2c0.3,0.8,0.5,1.8,0.6,3.1h0.5l0.1-13.5l-0.5,0
						c-0.1,1.3-0.3,2.3-0.5,2.9c-0.2,0.7-0.5,1.2-1,1.7c-0.5,0.5-1,0.9-1.8,1.1c-0.7,0.2-1.8,0.3-3.2,0.3h-1.4v-15.1h5.4
						c1.7,0,3,0.1,4,0.4c1,0.2,1.9,0.7,2.6,1.2c0.9,0.7,1.6,1.5,2,2.4c0.5,0.9,0.8,2.1,1,3.5l0.5,0l-0.4-8.3h-25.3v0.6
						c0.9,0,1.6,0.2,2.2,0.4c0.6,0.2,1,0.6,1.5,1.1c0.5,0.5,0.8,1.2,1,2c0.2,0.8,0.3,2.1,0.3,3.8v18.5
						C135.6,187.8,135.5,189.1,135.3,189.9z"
              />
              <path
                className="st0"
                d="M180.4,215.2c-0.6-0.7-1.3-1.2-2.1-1.6c-0.8-0.4-1.8-0.6-2.8-0.6c-1.1,0-2,0.2-2.8,0.6
						c-0.8,0.4-1.5,0.9-2.1,1.6c-0.6,0.7-1,1.4-1.3,2.3c-0.3,0.9-0.4,1.8-0.4,2.7c0,1,0.1,1.9,0.4,2.7c0.3,0.9,0.7,1.6,1.3,2.3
						c0.6,0.7,1.3,1.2,2.1,1.6c0.8,0.4,1.8,0.6,2.8,0.6c1.1,0,2-0.2,2.8-0.6c0.8-0.4,1.5-0.9,2.1-1.6c0.6-0.7,1-1.4,1.3-2.3
						c0.3-0.9,0.4-1.8,0.4-2.7c0-1-0.1-1.9-0.4-2.7C181.3,216.6,180.9,215.9,180.4,215.2z M180.3,222.6c-0.2,0.7-0.6,1.4-1,1.9
						c-0.5,0.5-1,1-1.6,1.3c-0.6,0.3-1.4,0.5-2.2,0.5c-0.8,0-1.6-0.2-2.2-0.5c-0.6-0.3-1.2-0.7-1.6-1.3c-0.4-0.5-0.8-1.2-1-1.9
						c-0.2-0.7-0.4-1.5-0.4-2.3c0-0.8,0.1-1.6,0.4-2.3c0.2-0.7,0.6-1.4,1-1.9c0.4-0.6,1-1,1.6-1.3c0.6-0.3,1.4-0.5,2.2-0.5
						c0.8,0,1.6,0.2,2.2,0.5c0.6,0.3,1.2,0.8,1.6,1.3c0.5,0.5,0.8,1.2,1,1.9c0.2,0.7,0.3,1.5,0.3,2.3
						C180.7,221.1,180.6,221.8,180.3,222.6z"
              />
              <polygon
                id="XMLID_13_"
                className="st0"
                points="125.5,213.3 124.1,213.3 124.1,227.2 131.3,227.2 131.3,226 125.5,226 		"
              />
              <polygon
                id="XMLID_12_"
                className="st0"
                points="206.2,214.5 210.4,214.5 210.4,227.2 211.8,227.2 211.8,214.5 216,214.5 216,213.3 
						206.2,213.3 		"
              />
              <polygon
                id="XMLID_11_"
                className="st0"
                points="159,224.9 150.8,213.3 149.5,213.3 149.5,227.2 150.8,227.2 150.8,215.6 159,227.2 
						160.4,227.2 160.4,213.3 159,213.3 		"
              />
              <path
                id="XMLID_10_"
                className="st0"
                d="M117.4,192c-1.1-0.7-2.1-1.8-3.1-3.1l-1.3-1.8l-10.8-14.8l7.6-7.8c1.5-1.5,2.5-2.5,3.2-3
						c0.5-0.4,1.1-0.7,1.5-0.9c0.5-0.2,1-0.3,1.7-0.4v-0.6h-10.1v0.6c0.9,0.1,1.6,0.2,2,0.4c0.8,0.4,1.1,1,1.1,1.9
						c0,0.5-0.1,0.9-0.4,1.2c-0.2,0.4-0.9,1.1-1.8,2.1L97,176.5v-8.9c0-1.7,0.1-3,0.3-3.8c0.2-0.8,0.5-1.5,1-2c0.4-0.5,0.9-0.8,1.5-1.1
						c0.6-0.2,1.3-0.3,2.2-0.4v-0.6h-15v0.6c0.9,0,1.6,0.2,2.2,0.4c0.6,0.2,1.1,0.6,1.5,1.1c0.5,0.5,0.8,1.2,1,2
						c0.2,0.8,0.3,2.1,0.3,3.8v18.5c0,1.7-0.1,3-0.3,3.8c-0.2,0.8-0.5,1.5-1,2c-0.4,0.5-0.9,0.8-1.5,1c-0.6,0.2-1.3,0.4-2.2,0.4v0.6h15
						v-0.6c-0.9,0-1.6-0.2-2.2-0.4c-0.6-0.2-1-0.6-1.5-1c-0.5-0.5-0.8-1.2-1-2.1c-0.2-0.8-0.3-2.1-0.3-3.8v-8.3l1.5-1.6l5.3,7
						c0.6,0.8,1.3,1.8,2.2,3c0.9,1.2,1.5,2.1,1.8,2.6c0.6,1,1,1.9,1,2.6c0,0.6-0.3,1.1-0.8,1.4c-0.5,0.3-1.4,0.5-2.5,0.6v0.6H121v-0.6
						c-0.7-0.1-1.4-0.2-1.8-0.4C118.7,192.8,118.1,192.5,117.4,192z"
              />
              <polygon
                id="XMLID_9_"
                className="st0"
                points="115.9,213.3 114.1,213.3 108,220 108,213.3 106.6,213.3 106.6,227.2 108,227.2 
						108,220.3 114.5,227.2 116.4,227.2 109.6,220.2 		"
              />
              <path
                id="XMLID_8_"
                className="st0"
                d="M177,131c-39.3,4.6-42.8,4.3-63.5,2c0,0,15.7,6.4,52.7,2c36.3-4.4,46.1-6.1,62.9-5.5
						C229,129.5,216.7,126.4,177,131z"
              />
              <path
                className="st0"
                d="M162.1,128.7c53.2-6.9,74.5-6.2,98-1c0,0-25-11.2-55.8-11.7V92.7l-12.7-7.4V68l-8.9-5.2V23.4l-19-11.1v27.3
						l-17.1-10v40.8l-8.3,4.8v46.2c-18.5,0.8-37.2-0.5-55.8-6.9C82.5,114.5,108.9,135.6,162.1,128.7z M191.6,87.5l10.8,6.3V116
						c-3.5,0-7.1,0.1-10.8,0.4V87.5z M183.4,65.4l6.3,3.7v47.4c-5,0.5-10.1,1-15.2,1.6v-58l5.5,3.2l0.8,0.5l1.9,1.1L183.4,65.4z
						 M165.6,15.6l15.2,8.9v37.2l-0.4-0.3l-7.8-4.6v61.5c-2.3,0.3-4.6,0.5-7,0.8V15.6z M148.4,32.9l15.2,8.9v77.6
						c-5,0.5-10.1,1-15.2,1.4V32.9z M140.2,76.3l6.4-3.7V121c-2.1,0.1-4.2,0.3-6.4,0.4V76.3z M101.7,121.4c31.9,6,61,0.2,82.5-2.3
						c29.2-3.3,56.1,3.7,56.1,3.7c-19.7-2.1-28.6-3.4-70.5,2.6C123.9,131.9,101.7,121.4,101.7,121.4z"
              />
            </g>
          </mask>
        </defs>
        <rect
          x="0"
          y="0"
          width="340"
          height="300"
          fill="url('#lakecity-gradient')"
          mask="url(#lakecity-mask)"
        />
      </svg>
    );
  }
}

export default LakeCityLogo;
