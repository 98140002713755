import React, { Component } from "react";

class Member5 extends Component {
  state = {};
  render() {
    return (
      <svg
        x="0px"
        y="0px"
        viewBox="0 0 80 80"
        style={{ enableBackground: "new 0 0 80 80" }}
        xmlSpace="preserve"
      >
        <g className="member">
          <path
            d="M67.8,70.4c-0.3-2.4-1.2-4.6-2.8-6.5c-1.6-1.8-3.9-3.3-6-4.4c1.5-2.8-0.3-5-1.9-7.2c-1.9-2.6-1-4.2-0.2-7.1
           c1.3-5-3.6-8.4-2.6-12.9c2.8,0.2,1.4-13.9,0.6-15.7c-1.1-3.4-4.1-7.5-8.1-7c0,0-0.1,0-0.1,0c0.3-8-11.5-7.9-16.2-5
           c-5.6,3.4-8.7,10.7-9.2,17c-0.2,3.3,1,5.5,1.5,8.5c0.6,3.6-1.3,6.3-2.5,9.6c-1.6,4.4-0.5,7.9,2.7,11.1c3.9,3.8,0.3,5.3-1.3,9.5
           c-1.2,1.2-9.2,5.6-9.3,10.8c0,2-1.1,5.3,0.2,7.1c0.2,0.3,0.6,0.5,0.9,0.4c0.5-0.2,0.6-0.8,0.6-1.4c0-1.9-0.3-3.8-0.1-5.6
           c0.1-0.6,0.7-4.4,1.7-4.4c0.2,0,1.3-1.3,1.6-1.5c0.9-0.7,1.9-1.5,2.9-2.1c-0.2,1.6,1.1,3.1,2.4,3.8c0,3.4-5.1,9.2-1.2,12.1
           c1.2,0.6,1.4,0,1.2-1.2c-0.5-1.9-0.8-3.3-0.3-5.2c0.4-1.7,1.3-3.4,1.4-5.2c0,0.3,2,0.7,2.3,0.9c1.4,0.8,1.6,2.4,1.5,3.9
           c0.6-0.3,0.6-1.6,1.1-2.2c1-1.3,2.6-2.2,3.6-3.5c2.8,1.4,5.2,2.1,7.2,4.7c0.2,1.4,1.2-0.5,1.3-0.5c1.5-0.9,2.9-2.6,4.4-3.6
           c2.6-1.7,5.4-3.3,7.6-5.6c-0.3,2.4,3,17,4.7,16.7c2.5-0.5-3.7-15.7-3.8-17.7c0.2-0.3,0.4-0.6,0.6-1c0.2,0,0.2-0.1,0.1-0.3
           c0.1-0.2,0.1-0.4,0.2-0.6c0.1-0.2,3.9,1.9,4.2,2c2,1.1,4.3,2.3,5.5,4.4c1.4,2.3,1.5,5.6,1.4,8.3c-0.1,1.2-0.3,2.5-0.1,3.7
           c0,0.3,0.1,0.6,0.4,0.7c0.4,0.3,1-0.2,1.2-0.7C67.9,75,68.1,72.6,67.8,70.4z M22.6,77.6C22.6,77.6,22.6,77.6,22.6,77.6
           C22.6,77.6,22.6,77.6,22.6,77.6z M53.7,41.9c0.3,2.7-1.5,5.1-1.6,7.7c-0.2,3.5,2.3,4.7,3.1,7.6c-0.5-0.3-0.9-0.6-1.3-0.9
           c-0.2-0.7-1.9-1.8-2.3-2.4c0.2-0.1-1.2-7.5-1.1-8.7c0.3-2.6,1.4-5.4,0-7.9c0.2-0.5,0.4-1,0.6-1.6c0.2-0.2,0.4-0.4,0.5-0.6
           C51.5,37.7,53.3,39.4,53.7,41.9z M48.7,55.2c-1.7,2.1-4.6,3.7-6.9,5.2c-0.5-1-1.6-0.5-2.3,0c-0.2-0.1-0.4-0.2-0.6-0.3
           c-0.1-1.5-5-3.6-6.2-4.7c-0.4-2.1,1.5-4.2,2-6.1c0.7-2.4,0.5-5-0.4-7.3c4.4,4.2,8.6,4.5,13-0.1c-0.7,4.1-0.3,8.9,1.9,12.5
           C49.1,54.7,48.9,55,48.7,55.2z M40.1,62.7C40.1,62.8,40,62.9,40,63c0,0,0,0,0.1-0.1c0,0,0,0.1-0.1,0.1c-0.4-0.5-0.7-1-1-1.5
           c0.2,0.1,0.4,0.2,0.6,0.2c0.5,0,0.9-0.6,1.4-0.8c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0.1,0,0.1,0,0.2c-0.1,0.7-0.5,1.2-0.9,1.7
           C40.1,62.8,40.1,62.8,40.1,62.7z M40.9,60.8C40.9,60.8,40.9,60.8,40.9,60.8L40.9,60.8C40.9,60.8,40.9,60.8,40.9,60.8z M41,60.8
           C41,60.8,41,60.9,41,60.8L41,60.8C41,60.9,41,60.8,41,60.8z M41,60.9L41,60.9C41,60.9,41,60.9,41,60.9C41,60.9,41,60.9,41,60.9z
            M31.9,52.8c-0.3-2.1-1.2-3.9-1-6.1c0.3-2.9,1-5,0.6-8c0.3,0.5,0.7,1,1.1,1.5c-0.1,0.5,0.8,1.3,0.9,1.8c0.5,1.3,0.7,2.8,0.6,4.2
           C34.1,48.7,32.8,50.6,31.9,52.8z M37.1,15c0.4,0.1,0.8,0.1,1.2,0.1c0.5,0.1,1,0.4,1.5,0.7c0.4,0.4,1.7,1.1,2.3,1
           c0.3,0,0.8-0.1,1-0.3c0.4-0.5,5.3,3.9,5.7,4.5c1.7,2.5,1.3,5.7,1.4,8.5c-0.3,1.5-0.5,3-0.7,4.5c0-0.1,0-0.2,0-0.2
           c-0.5,4.1-4.4,9.5-8.7,10.3c-3.5,0.3-7.3-4.3-8.9-7c-2.4-4-4.3-8.6-2.9-13.3C30.3,20,33.1,15.8,37.1,15z M49.1,49.6
           c-0.9-3.4-0.7-6.7,0.1-10.1c0.2-0.3,0.4-0.7,0.6-1.1c1.4,4.3-1.7,8.7,0.1,13.3c-0.1,0.2-0.1,0.4,0,0.6
           C49.6,51.4,49.3,50.5,49.1,49.6z M51.6,32.3C51.7,32.2,51.7,32.2,51.6,32.3c0.2-1.5,0.3-2.9,0.5-4.3c0.2-0.4,0.6-0.8,1-1.1
           c0.1,0,0.3,0,0.4-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0.2,0.3,0.3,0.5,0.3,0.8C54.2,29.6,52.9,31,51.6,32.3z
            M30.1,6.3c4.3-3.1,16.4-4.4,15.3,4.1c-1.4-2.8-4.4-5.5-7.6-6.2c-3.2-0.7-5.8,1.1-8.4,2.7C29.6,6.7,29.8,6.5,30.1,6.3z M23.4,49.1
           c-6.1-5.1,1.2-12.1,0.8-18c1.2,3.8,1.3,6-0.1,9.7c-1.4,3.8-1.5,5.7,0.6,9.3C24.3,49.8,23.8,49.5,23.4,49.1z M25.4,56.9
           c0.5-0.6,0.9-1.4,1-2.2c0,0.1,0,0.2,0.1,0.3c0,0,0,0,0,0c0,0,0,0,0,0l0,0c0.4,4.4-3.8,6.8-1.3,11.4c0,0-0.1,0-0.1-0.1c0,0,0,0,0,0
           C21.1,63.4,22.9,60.3,25.4,56.9z M27.4,68.4c-0.4-1.7-1.9-3.2-2-4.9c-0.1-1.8,1.1-3.3,1.8-4.9c0.6-1.6,0.7-3.4,0.2-5.1
           c-0.9-2.9-3.4-5.2-3.3-8.2c0-2.3,1.6-4.3,2.2-6.5c1.1-4.2-1.3-8.4-1.9-12.7c-0.7-4.3,0.5-9,3.2-12.4C29,11.7,31,10,33.3,9.2
           c2.3-0.8,4.9-0.8,7.1,0.3c1.2,0.6,2.3,1.5,3.5,1.5c-2-2.8-5.9-3.9-9.3-3.2c-3.4,0.7-6.3,3-8.3,5.8c-2.8,4-3.9,9.1-3,13.9
           c-2.2-5.6-0.1-12.1,3.7-16.8c1.9-2.3,4.3-4.4,7.2-5.1c2.9-0.8,6.3-0.1,8.2,2.2c0.9,1.1,1.5,2.6,2.9,3.2c0.8,0.3,1.8,0.2,2.7,0.3
           c2.4,0.3,4.1,2.4,5.1,4.6c1.4,3.1,1.8,6.7,1.1,10c0.5-4.9-1.6-10-5.4-13.2c-1-0.9-2.7-1.6-3.7-0.6c2.8,0.9,5.2,2.9,6.6,5.5
           c1.4,2.6,1.6,5.8,0.7,8.6c0.7-3.6-1.3-7.2-3.6-10c-0.7-0.8-1.4-1.6-2.3-2c-1-0.4-2.2-0.3-2.8,0.5c2.9,0.8,5.4,2.9,6.7,5.6
           c-1.4-1.7-3-3.2-4.8-4.4c-0.5-0.3-1-0.6-1.5-0.6c-0.4,0-0.8,0.2-1.3,0.2c-1,0.1-1.9-0.6-2.9-1c-2.4-1-5.2,0-7.3,1.6
           c-2,1.5-3.6,3.6-4.6,5.9c-1.2,2.8-0.7,4.7,0,7.6c0.4,1.7,0.9,3.4,1.5,5.1c0.3,0.8,1,2,1,2.8c0.5,5.1-0.9,9.7,0.1,14.7
           c0.6,3-0.3,5.8-0.3,8.8c0,2.9-0.4,4.3-1.7,6.8c-0.1-1.3-0.7-2.5-0.8-3.7c-0.1-2.1,1.1-3.5,1.8-5.4c1-2.7,0.6-5-0.8-7.5
           c-1.4-2.5-1.5-3.9-0.9-6.7c1.1-4.7,1.9-8.1-0.2-12.6c-2.1-4.6-2.3-10.6,0.7-14.9c2.7-3.8,7.9-5.8,12.3-3.7c0.9,0.4,1.8,1,2.8,0.8
           c-1.3-1.9-3.7-2.9-6-2.9c-2.3,0-4.6,1-6.4,2.3c-4.4,3.2-6.7,9-5.7,14.4c0.7,4.1,3.2,7.9,2.8,12.1c-0.3,2.7-1.7,5.2-1.5,7.9
           c0.3,3.1,2.7,5.7,2.4,8.8c-0.2,2.4-2.2,4.5-2.2,7c0,2,1.4,4.1,0.4,5.9C27.5,69.1,27.4,68.7,27.4,68.4z M30.7,66.6
           c-0.3,0.3-0.6,0.7-0.9,0.9c2-3.8,1.1-7,1.9-11C32.9,60.6,34,63,30.7,66.6z M53.7,58.4c-3.8,4.8-10.3,7-13.8,12
           c-1.8-2.5-4.2-3.6-7-4.5c2.3-3.4,1.3-5.9,0.2-9.4c0.5,0.4,1,0.8,1.5,1.2c0,0,0,0,0,0c1,0.7,1.9,1.5,2.9,2.2c0,0-0.1,0-0.1-0.1
           c0.2,0.1,0.4,0.3,0.5,0.4c-1.1,0.8,0.8,3.7,1.7,3.9c1,1.6,2.4-2.7,2.4-2.9c2.7-1.6,5.8-3.6,7.9-5.9C50.9,56.6,52.3,57.5,53.7,58.4
           C53.8,58.4,53.8,58.4,53.7,58.4z M56.4,58c0.7-2-1.9-3.9-2.6-5.5c-1-2.2-0.3-4.2,0.3-6.4c0.5-1.9,1-3.4,0.4-5.4
           c-0.8-2.5-2.4-4-2-6.8c0.2-0.2,0.3-0.3,0.5-0.5c0.1,2.4,1.7,4.3,2.4,6.6c0.8,2.7,0.2,4-0.6,6.5c-0.8,2.6-0.8,4.2,0.8,6.4
           c1.3,1.8,3.3,3.3,2,5.8C57.2,58.4,56.8,58.2,56.4,58z M57.5,59.9C57.5,59.9,57.5,59.9,57.5,59.9C57.5,59.9,57.5,59.9,57.5,59.9
           L57.5,59.9z"
          />
          <polygon points="26.5,55.1 26.5,55.1 26.5,55.1 	" />
          <path
            d="M36.4,26.4c-0.2-0.8-3.6-1.4-4.5-1.3c-2.2-0.1-3.2,1.6-0.6,1c1-0.1,3.8,1.2,4.4,0.7c0,0,0,0,0,0c0,0,0,0,0,0l0,0
           C36,26.8,36.5,26.8,36.4,26.4z"
          />
          <path
            d="M42.4,27C42.4,27,42.4,27,42.4,27C42.4,27,42.4,27,42.4,27c0.3,0.1,0.7-0.2,0.9-0.3c0.3-0.1,0.6-0.1,1-0.2c0,0,0,0,0,0
           c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0.3-0.1,0.7-0.1,1-0.1c-0.2,0.2-0.3,0.4-0.4,0.6c-0.1,0-0.1,0-0.2,0C44.7,27,44,26.3,44,26.9
           c0,0.1,0,0.2,0.1,0.3c-0.1,0-0.1,0-0.1,0c-0.2,0-0.4,0-0.6,0c-0.2,0-0.6-0.3-0.8-0.2c-0.3,0.3,0.3,0.6,0.5,0.7
           c-0.1,0.1-1.2,1.1-0.6,1.5c0.3,0.1,0.5,0,0.6-0.2c0.4,0.1,0.7-0.4,1-0.5c0.3,1.2,2.4,1.1,2.4-0.2c0.2,0.1,0.4,0.2,0.6,0.3
           c0.5,0.8,1.3,0.1,0.7-0.5c0.3,0,0.4-0.2,0.4-0.4c-0.1-0.2-0.6,0-0.7,0.1c-0.1-0.2-0.3-0.3-0.4-0.4c0.2-0.1,0.3-0.3,0.2-0.5
           c-0.2-0.4-0.7,0.1-1,0.2c-0.1,0-0.3,0-0.4-0.1c0.1-0.2,0.1-0.5-0.1-0.6c0.7,0,2.1,0.3,2.8,0c0.9-0.3-0.5-0.8-0.7-0.9
           c-0.4-0.7-3.6-0.1-4.2,0.1C42.5,25.9,41.5,27.2,42.4,27z"
          />
          <path d="M44.3,26.6C44.3,26.5,44.3,26.5,44.3,26.6C44.3,26.5,44.3,26.5,44.3,26.6z" />
          <path
            d="M36.2,27.9c-0.1-0.2-0.2-0.2-0.4-0.2c0,0,0,0,0,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0c0,0-0.1-0.1-0.1-0.1
           c0.4-1-0.4-0.7-0.9-0.4c0,0-0.1,0-0.1,0c-0.1-0.8-0.6-0.8-0.8,0c-0.2,0-0.3,0-0.5,0c-0.1-0.1-0.3-0.3-0.4-0.4c0,0,0,0,0,0
           c-0.4-0.4-0.6,0.2-0.5,0.5c-0.2,0.1-0.5,0.1-0.8,0.1c-0.1-0.1-0.4-0.3-0.5,0c0,0.3,0.2,0.4,0.5,0.5c-0.1,0.1-1.5,1.3-0.6,1.3
           c0.3,0,0.6-0.2,0.8-0.4c0.2,0,0.8-0.4,0.8-0.4c0,0.4,0.3,0.8,0.7,1c0.3,0.2,0.7,0.3,1,0.2c0.4-0.2,0.7-0.5,0.8-1
           c0.2,0.1,0.4,0.2,0.6,0.3c0.2,0.3,0.9,0.4,0.7-0.1c0-0.2,0-0.4-0.2-0.5C36.1,28.2,36.2,28.1,36.2,27.9z M35.3,27.7
           C35.3,27.7,35.3,27.7,35.3,27.7C35.3,27.7,35.3,27.7,35.3,27.7C35.3,27.7,35.3,27.7,35.3,27.7z"
          />
          <path
            d="M38.5,35c1,0.8,3.2,0.3,1.9-0.5c-0.1,0-0.1,0-0.2-0.1c-0.1,0-0.2,0-0.4,0c-0.3,0-0.6-0.1-0.8-0.3c0,0,0,0,0,0
           c0,0,0-0.1,0-0.1c0-0.1,0-0.1-0.1-0.2c0-0.6,0.6-5.1-0.2-5.1C38.6,28.7,37.7,34.3,38.5,35z"
          />
          <path
            d="M36.4,37.2c-0.1,0.2,0,0.4,0.2,0.5c0,0,0,0-0.1-0.1c0.5,0.4,0.9,0.9,1.5,1.2c0.1,0.1,0.1,0.1,0.2,0.1
           c0.9,0.8,3,0.7,3.9-0.2c0.5,0,1.2-1,1.4-1.3c0.6-0.6-0.4-0.8-0.8-0.7c-0.1,0-0.1,0-0.2,0c-0.9-0.2-1.8-0.6-2.5,0.3
           c-0.7-0.3-0.9-0.6-1.6-0.3c-0.4,0-0.7,0.1-1.1,0.2C37,37,36.5,36.9,36.4,37.2z"
          />
        </g>
      </svg>
    );
  }
}

export default Member5;
