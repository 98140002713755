import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import ScrollMagic from "scrollmagic";
import TrackVisibility from "react-on-screen";
// import ReactGA from "react-ga";
import TagManager from "react-gtm-module";
import CTA from "./common/cta";
import Portfolio from "./common/portfolio";
import PortfolioAddPointment from "./common/portfolio-addpointment";
import PortfolioMoodboard from "./common/portfolio-moodboard";
import PortfolioNaturalOrient from "./common/portfolio-natural-orient";
import PortfolioLakeCity from "./common/portfolio-lakecity";
import PortfolioWisdomPark from "./common/portfolio-wisdom-park";
import PortfolioVSI from "./common/portfolio-vsi";
import PortfolioTechbond from "./common/portfolio-techbond";
import PortfolioKakiseni from "./common/portfolio-kakiseni";

class Work extends Component {
  constructor() {
    super();
    this.state = {
      isHovered: false,
      isHovered2: false,
      label: ""
    };
    this.handleHover = this.handleHover.bind(this);
    this.handleHover2 = this.handleHover2.bind(this);
    this.controller = new ScrollMagic.Controller();
  }
  handleHover() {
    this.setState(prevState => ({
      isHovered: !prevState.isHovered
    }));
  }
  handleHover2() {
    this.setState(prevState => ({
      isHovered2: !prevState.isHovered2
    }));
  }
  componentDidMount() {
    // ReactGA.pageview("/");
    const tagManagerArgs = {
      dataLayer: {
        event: "pageviewCustomEvent",
        pagePath: "/",
        pageTitle: "Home"
      },
      dataLayerName: "PageDataLayer"
    };
    TagManager.dataLayer(tagManagerArgs);

    var self = this;
    new ScrollMagic.Scene({
      triggerElement: ".masthead",
      duration: 400 // scroll distance
    })
      .on("enter", function(e) {
        self.updateLabel("Welcome");
      })
      .addTo(this.controller);
    new ScrollMagic.Scene({
      triggerElement: "#portfolio-addpointment",
      duration: 400 // scroll distance
    })
      .on("enter", function(e) {
        self.updateLabel("Web & Mobile App");
      })
      .addTo(this.controller);
    new ScrollMagic.Scene({
      triggerElement: "#portfolio-moodboard",
      duration: 400 // scroll distance
    })
      .on("enter", function(e) {
        self.updateLabel("E-commerce");
      })
      .addTo(this.controller);
    new ScrollMagic.Scene({
      triggerElement: "#portfolio-natural-orient",
      duration: 400 // scroll distance
    })
      .on("enter", function(e) {
        self.updateLabel("Corporate Website");
      })
      .addTo(this.controller);
    new ScrollMagic.Scene({
      triggerElement: "#portfolio-lakecity",
      duration: 400 // scroll distance
    })
      .on("enter", function(e) {
        self.updateLabel("Wordpress");
      })
      .addTo(this.controller);
    new ScrollMagic.Scene({
      triggerElement: "#portfolio-wisdom-park",
      duration: 400 // scroll distance
    })
      .on("enter", function(e) {
        self.updateLabel("Website Design");
      })
      .addTo(this.controller);
    new ScrollMagic.Scene({
      triggerElement: "#portfolio-vsi",
      duration: 400 // scroll distance
    })
      .on("enter", function(e) {
        self.updateLabel("Wordpress");
      })
      .addTo(this.controller);
    new ScrollMagic.Scene({
      triggerElement: "#portfolio-techbond",
      duration: 400 // scroll distance
    })
      .on("enter", function(e) {
        self.updateLabel("Wordpress");
      })
      .addTo(this.controller);
    new ScrollMagic.Scene({
      triggerElement: "#portfolio-kakiseni",
      duration: 400 // scroll distance
    })
      .on("enter", function(e) {
        self.updateLabel("System Development");
      })
      .addTo(this.controller);
  }
  updateLabel = label => {
    this.setState({ label });
  };

  componentWillUnmount() {
    this.controller.destroy();
  }

  render() {
    return (
      <div id="page-home">
        <Helmet>
          <title>
            Simplicitic - Web Design & App Development Agency in Malaysia
          </title>
          <meta
            name="description"
            content="Digital agency based in Subang Jaya, Malaysia. We create websites, mobile apps, eCommerce platforms, online campaigns and much more"
          />
        </Helmet>
        <div className="label-container">
          <div className="label">{this.state.label}</div>
        </div>
        <TrackVisibility once partialVisibility>
          {({ isVisible }) => (
            <section
              className={`masthead text-center wrapper  ${
                isVisible ? "in-view" : ""
              }`}
            >
              <h1 className="animate-item">
                <span>We make</span> digital experience <b>simple!</b>
              </h1>
              <p className="animate-item">
                We are a creative digital agency based in Petaling Jaya,
                Malaysia. We create websites, mobile apps, eCommerce platforms,
                online campaigns and much more.
              </p>
              <Link to="/about" className="btn btn-secondary animate-item">
                More About Us
              </Link>
            </section>
          )}
        </TrackVisibility>
        <section className="portfolio wrapper">
          <Portfolio
            id="addpointment"
            name="AddPointment"
            desc="AddPointment is a cloud-based system to manage appointments and walk-in customers"
            link="https://addpointment.com/"
            className="portfolio-odd"
            portfolio={<PortfolioAddPointment />}
          />
          <Portfolio
            id="moodboard"
            name="Moodboard"
            desc="One of the most experienced shoe traders in Malaysia"
            link="https://moodboardstore.com/"
            className="portfolio-even"
            portfolio={<PortfolioMoodboard />}
          />
          <Portfolio
            id="natural-orient"
            name="Natural Orient"
            desc="Food solution provider and marketing company specialising in natural ingredients, food processing machinery and technology."
            link="http://naturalorient.com.my"
            className="portfolio-odd"
            portfolio={<PortfolioNaturalOrient />}
          />
          <div onMouseEnter={this.handleHover} onMouseLeave={this.handleHover}>
            <Portfolio
              id="lakecity"
              name="Lake City"
              desc="Lake City represents an extraordinary integrated mixed development with magnificent lake view, with its acronym which stands for Hotels, Offices, Parks, Shopping Malls, Connectivity and Apartments."
              link="https://lakecitykl.com/"
              className="portfolio-even"
              portfolio={<PortfolioLakeCity isHovered={this.state.isHovered} />}
            />
          </div>
          <div
            onMouseEnter={this.handleHover2}
            onMouseLeave={this.handleHover2}
          >
            <Portfolio
              id="wisdom-park"
              name="Wisdom Park"
              desc="Wisdom Park is an integrated holistic educational facility for the community."
              link="https://wisdompark.org"
              className="portfolio-odd"
              portfolio={
                <PortfolioWisdomPark isHovered={this.state.isHovered2} />
              }
            />
          </div>
          <Portfolio
            id="vsi"
            name="V.S. I"
            desc="V.S. Industry Berhad is a leading integrated Electronics Manufacturing Services (EMS) provider in the region."
            link="http://www.vs-i.com"
            className="portfolio-even"
            portfolio={<PortfolioVSI />}
          />
          <Portfolio
            id="techbond"
            name="Techbond"
            desc="Techbond is an adhesive manufacturer that supplies great products and services worldwide."
            link="http://www.techbond.com.my"
            className="portfolio-odd"
            portfolio={<PortfolioTechbond />}
          />
          <Portfolio
            id="kakiseni"
            name="Kakiseni"
            desc="Judging system for BOH Cameronian Arts Awards"
            className="portfolio-even"
            portfolio={<PortfolioKakiseni />}
          />
        </section>
        <CTA />
      </div>
    );
  }
}

export default Work;
