import React, { Component } from "react";
import AddPointmentThumb from "../../images/portfolio/addpointment-thumb.png";
// import AddPointmentFull from "../../images/portfolio/addpointment.gif";
import { Fade } from "react-slideshow-image";
import Addpointment1 from "../../images/portfolio/addpointment1.png";
import Addpointment2 from "../../images/portfolio/addpointment2.png";
import Addpointment3 from "../../images/portfolio/addpointment3.png";
import Addpointment4 from "../../images/portfolio/addpointment4.png";
import Addpointment5 from "../../images/portfolio/addpointment5.png";
import Addpointment6 from "../../images/portfolio/addpointment6.png";

class PortfolioAddPointment extends Component {
  state = {};
  render() {
    return (
      <div className="portfolio-img animate-item">
        <div className="portfolio-thumb">
          <img src={AddPointmentThumb} alt="AddPointment" />
        </div>
        <div className="portfolio-full">
          <Fade
            duration={2000}
            transitionDuration={500}
            infinite={true}
            indicators={false}
            autoplay={true}
            arrows={false}
          >
            <div>
              <img src={Addpointment1} alt="Addpointment - Dashboard" />
            </div>
            <div>
              <img src={Addpointment2} alt="Addpointment - Today" />
            </div>
            <div>
              <img src={Addpointment3} alt="Addpointment - Walk-in" />
            </div>
            <div>
              <img
                src={Addpointment4}
                alt="Addpointment - Confirm Appointment"
              />
            </div>
            <div>
              <img src={Addpointment5} alt="Addpointment - Now Serving" />
            </div>
            <div>
              <img
                src={Addpointment6}
                alt="Addpointment - Appointment History"
              />
            </div>
          </Fade>
        </div>
      </div>
    );
  }
}

export default PortfolioAddPointment;
