import React, { Component } from "react";
import KakiseniThumb from "../../images/portfolio/kakiseni-thumb.jpg";

class PortfolioKakiseni extends Component {
  state = {};
  render() {
    return (
      <div className="portfolio-img animate-item">
        <div className="portfolio-thumb">
          <img src={KakiseniThumb} alt="Kakiseni" />
        </div>
        <div className="portfolio-full">
          <div className="kakiseni">
            <b>BOH</b>
            <span>Cameronian</span>
            <div>Arts Awards</div>
          </div>
        </div>
      </div>
    );
  }
}

export default PortfolioKakiseni;
