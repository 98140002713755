import React, { Component } from "react";

class Mailchimp extends Component {
  render() {
    return (
      <svg
        version="1.1"
        id="Layer_1"
        x="0px"
        y="0px"
        viewBox="0 0 7429.5 2500"
        style={{ enableBackground: "new 0 0 7429.5 2500" }}
        xmlSpace="preserve"
      >
        <g id="Text">
          <polyline
            id="Fill-1"
            points="3754.3,1547.7 3754.3,1547.7 3754.3,1547.7 3754.3,1547.7"
            fill="#0A0A08"
            className="darkFill"
          />
          <path
            id="Fill-2"
            fill="#0A0A08"
            className="darkFill"
            d="M7105.7,1732.3l-0.8-1.4l-1.6-5l0.7-1.5c49.6-102.1,106.3-160.6,155.7-160.6h0.5
		c55.8,0.4,94.4,50.6,93.8,122c-0.3,47.7-21.4,75.1-38.9,89.7c-20,16.7-46.7,26.2-73.2,26.2
		C7176.8,1801.3,7131.9,1763.8,7105.7,1732.3z M7092.2,2117.7c-8,10.6-19.1,16-33,16c-16-0.1-28-5.7-36.1-16.6
		c-22.8-30.5-16.4-102.3,20.9-232.7c4.4-15.5,9.3-31.2,14.4-46.5l4.3-13l3.8,13.2C7110,1988.5,7118.7,2082.6,7092.2,2117.7z
		 M5495,1424.8c1.5-32.5,4.2-64.1,8.1-94c25.2-192.7,77.7-327.4,127.8-327.4h0.2c23.9,0.2,35.8,18.2,35.6,53.7
		c-0.7,87-55.8,211.6-163.9,370.3l-8.5,12.4L5495,1424.8z M7247.7,1864.4l1.8,0c105.2,0,179.2-72.9,180-177.2
		c0.4-51.6-14.5-98.9-42-133.1c-29.1-36.1-69.7-55.4-117.7-55.8l-1,0c-64.7,0-124.7,49.1-183.6,150.1l-4.7,8l-3-8.8
		c-14.9-43.3-28.1-79.3-36-100.9c-5.5-15.1-8-21.8-8.6-24c-2.8-10.9-12.1-17.7-24.4-17.7c-7.5,0-15.5,2.6-23.1,7.4
		c-7.5,4.8-11.7,10.6-13.2,18.4l-0.4,1.2c-58.9,183.8-107.5,273-148.6,273c-6,0-10.7-2-14.2-5.9c-10.8-11.9-8.1-40.3-4.5-79.5
		c2.7-28.8,5.7-61.4,4.5-96.1c-2.4-66.5-38.8-106.5-97.4-107c-74.4,0-122.6,79.2-144.9,126.5l-6.6,14.1l-1.5-15.5
		c-5.6-58.5-25.2-128.3-87.1-128.8c-72.2,0-127.3,84.8-160.9,156l-9,19.1l0.9-21.1c4-94.9,7.3-150.9,9.8-166.5
		c2.5-15.6,1.1-26.1-3.9-32c-4.8-5.6-13.9-7.7-27.3-6.9c-23.1,1.5-35,15.5-52.7,62.7c-29.3,77.5-103.7,257.8-163.1,257.8
		c-11.8-0.1-21.1-4.1-27.9-11.9c-24.9-28.6-15-105.8-1.2-212.6l1-8.1c6.5-50.6-4.6-61.4-32.1-66.4c-3-0.5-5.8-0.8-8.3-0.8
		c-21.9,0-32.2,21-52.7,79.4c-36.5,103.9-83.4,213.2-138.2,213.2c-2,0-4-0.2-6-0.5c-29.1-4.9-28.4-48.1-27.6-102.7
		c0.4-29.5,0.9-63-3.6-93.3c-8.2-54.2-40.2-88.1-83.4-88.5c-86.4,0-144.6,110.6-171.7,176.5l-6.1,14.9l-2-16
		c-3.6-28.6-8.4-73.9-10.5-127.5l-0.1-1.6l1-1.2c161.6-203.5,234.2-350.3,235.1-476.1c0.6-80.4-36.5-128.6-99.2-129.1
		c-45.8,0-154.9,38.2-192.8,392c-7,65.6-10.9,132.9-11.5,200l0,1.3l-0.7,1.1c-73.9,107-231.8,291.1-386.3,291.1
		c-139.6,0-251-112.9-251-312.3c0-267.3,189.2-431.4,331.6-431.4l1.2,0c30.9,0.2,57.5,8.9,76.7,25c20,16.7,30.4,40.2,30.2,67.8
		c-0.3,36.9-12.3,55.9-23.9,74.2c-3,4.7-6,9.4-8.7,14.4c-2.7,5-5.3,12.3-1.6,18.5c3.7,6.4,13.3,10.4,24.9,10.5
		c33.9,0,85.1-49,85.7-123c0.6-77.4-64.6-157.1-180.8-157.1c-175.9,0-412.9,193.3-412.9,506.5c0,221.6,138.9,374.7,327.1,374.7
		c133.6,0,265.7-95.6,382.7-255.3l7.2-9.7l0.5,12.1c2.5,58.4,6.7,104.5,9.8,138.2c2,21.6,3.4,37.2,3.4,45.9c-0.1,18.9,2.9,32,9.4,40
		c6.5,8.1,16.8,11.9,32.5,12c26.3,0,31.5-17.9,41.9-62.1l3-12.6c12.9-53.6,60.7-228.3,132.5-228.3c11.7,0.1,20.8,3.6,27.2,10.4
		c15.9,16.8,14.1,51.4,12.1,91.3c-1.3,25-2.6,50.8,0,75.9c6.8,67.8,36,100.9,89.5,101.3c54.9,0,112.2-59.1,142.4-114.1l5.7-10.4
		l2.2,11.6c5.6,29.4,30.5,125.9,108.7,125.9c70.8,0,131.5-74.6,198.1-228l8.7-20.1l-0.5,21.9c-1.4,58.2-4.6,129.2-4.6,184.1
		c0,42.5,4.3,58.7,23.9,58.7c1.4,0,2.9,0.2,4.4,0.2c18.8,0,31.6-11.3,38-33.5c50.1-174.6,93.4-255.9,136.3-255.9
		c42,0.3,46,77.1,49.3,212.8l0.2,9.5c1,47.5,2.7,68.5,32.2,68.7c22.4,0,28-17.6,39.5-60.8c2.2-8.3,4.6-17.3,7.4-27.1
		c40.4-140.4,74-197.6,115.7-197.6c35.7,0.3,37.7,37.1,38.4,49.2c1.4,25.9-1.3,53.1-3.9,79.3c-2.4,24.4-4.9,49.5-3.9,73
		c2.2,54.9,32.2,85.3,84.6,85.7c68,0,129.4-79.5,183.2-236.3l3.7-10.9l4.2,10.7c9.8,24.6,22.5,58,34.2,97.8l0.5,1.5l-0.7,1.4
		c-52.1,115.6-88.8,244.3-89.3,313.1c-0.7,90.6,40.3,149.5,104.4,150l1.1,0c34.9,0,116.2-14,117.8-143.7
		c0.6-53.1-11.9-128.3-37.3-223.4l-2.9-10.9l9.4,6.2C7174.5,1852.6,7211,1864.1,7247.7,1864.4L7247.7,1864.4z"
          />
          <polyline
            id="Fill-3"
            fill="#0A0A08"
            points="7259.8,1568.1 7259.8,1568.1 7259.8,1568.1"
            className="darkFill"
          />
          <path
            id="Fill-5"
            fill="#0A0A08"
            className="darkFill"
            d="M3822.8,1598.3c-22.9,92.1-66.8,203.3-145.9,209.6c-2.2,0.2-4.4,0.3-6.4,0.3
		c-32.8,0-51.2-21.5-54.7-63.9c-4.3-53.1,18.5-115.2,58.3-158.3c25.3-27.5,53.8-42.6,80.3-42.6h0.5c33.5,0.3,56.9,27.1,67.7,52.2
		l0.6,1.3L3822.8,1598.3z M4548.5,1083.2l1.1,0c7.5,0,14,3.7,19.5,11.1c26,35.1,26.4,163.3-18.3,309.1
		c-25.5,83.3-59.8,162.1-99.2,227.6l-6.3,10.4l-1.6-12.1c-4.6-35.2-6.9-72-8.5-103.2C4423.7,1305.8,4490.4,1086.3,4548.5,1083.2z
		 M3945.8,1847.6c76.5-6.1,137.2-110.1,159.7-154.6l5.8-11.5l2.2,12.7c10.8,62.5,44.8,167.5,151.2,168.3l0.9,0
		c46,0,98.2-28.1,150.9-81.4l3.9-3.9l2.8,4.7c35.5,59.4,83.1,90.9,133.8,88.3c74.7-3.9,120-56.8,124-93.5c1-9.4-1.5-18.2-6.8-24.1
		c-6.7-7.5-16.5-8.3-23.6-1.5l-3.4,3.2c-17.4,16.7-49.8,47.8-85.1,49.6c-43.2,2.1-75.2-23.7-95.8-77l-0.9-2.2l1.5-1.9
		c118.8-155.4,202.2-392.5,193.9-551.5c-3.8-73.6-25.2-161.4-105.6-161.4c-2,0-4,0.1-6,0.2c-30.8,1.6-58.8,16.3-83.2,43.6
		c-74.6,83.4-113.6,288.8-99.4,523.3c2.9,48.7,11.4,94.2,25.1,135.2l0.8,2.3l-1.6,1.8c-44.1,51.5-87.6,79.9-122.5,79.9
		c-84,0-81.1-140.8-74.5-257.6c1.4-25.2,3.6-41.2-3.9-50.3c-5.3-6.5-14.2-9.7-27.2-9.9l-1.5,0c-29.9,0-36.1,6.1-51.4,50.5
		c-31.8,92.2-94.6,246.8-157.8,246.8c-17.5-0.1-30.9-6.1-40-17.8c-30.6-39.1-7.4-136.7,11.3-215c3.4-14.1,6.6-27.6,9.3-40.1
		c4.1-18.6,2.8-32.2-3.7-40.4c-6.6-8.2-19.7-11.5-37.1-10.1c-27,2.2-44.3,13.5-52.7,64.9l-1.7,10.7l-6.3-7.6
		c-11.8-17.4-34.9-38.2-77.6-38.2c-3.8,0-7.6,0.1-11.6,0.5c-45.6,3.8-97.6,35.5-135.6,83c-44.4,55.4-65.6,125.5-59.7,197.4
		c0.3,3.8,0.9,7.5,1.4,11.1l0.3,2l-1.4,1.5c-26.6,28.9-53.6,43.6-80.4,43.6c-41.4-0.3-65.1-31.1-65.1-82.3
		c0-86.2,58.4-346.2,58.4-440.3c0-100.1-44.6-154.7-122.1-155.3l-1.2,0c-113.1,0-201.4,128.4-285.8,392.6
		c-7.7,24.2-16.1,52.2-23.4,76.3l-11.8,39.7l3.5-41.3c7.8-92,13.2-181.6,16-266.1c4.2-127.7-13.1-209.8-52.8-251.2
		c-21.4-22.3-49.8-33.3-86.7-33.6h-1c-138.5,0-204.8,233.5-253.1,398.3c-14.6,49.7-43.3,161.9-62.5,238.7l11.4-18.5
		c8.5-96.6,33.2-338.5,31.6-469.8c-1.7-140.8-48.6-204-152-204.7h-1.3c-55.2,0-93.6,27.5-116,50.5c-36,37-58.4,91-57.2,137.6
		c0.8,28.4,18.2,53.6,31.4,53.6c8.6,0,13.3-11.5,15.4-18.3c32-105.8,73.4-157.2,126.6-157.2c22,0.2,38.4,6.7,50.7,19.9
		c41.5,44.5,34,164.8,20.3,383.2c-4.4,70.4-9.4,150.1-13.6,240.8c-0.7,15.5-1.7,30.6-2.6,44.9c-4,63-7.2,112.8,16.7,116.5
		c31.1,4.8,51.3-7.8,61.7-38.5c15.4-45.7,49.8-170.4,116.4-398.4c63.9-218.8,112.2-298.4,174-298.4c71.4,0,76.7,122.6,63.6,381.2
		c-2.8,54.8-6.5,116.9-9.4,184.6c-0.4,9.5-0.9,18.7-1.3,27.6c-2.7,55.9-4.9,100.1,11.1,117.1c5,5.3,11.8,7.9,20.8,7.9
		c30.9,0,40.2-27.1,55.7-72.1c2.7-8,5.6-16.4,8.8-25.3c106-292.5,184.4-546.3,289.7-546.3c31.6,0,56.1,25.6,56.1,75.6
		c0,115.4-56.4,338.5-56.4,451.5c0,40.5,11.7,76.9,33.7,102.4c22.6,26.2,54.2,40.2,91.5,40.5h0.8c35.2,0,71.9-17.1,109.1-50.8
		l3.5-3.2l2.8,3.8c24.5,32.9,64,50.6,106.6,47.3c65.4-5.1,120.3-63.3,140.7-118.2l4-10.8l4,10.8
		C3842.8,1804.1,3875.6,1853.3,3945.8,1847.6L3945.8,1847.6z"
          />
          <polyline
            id="Fill-7"
            fill="#0A0A08"
            points="5630.9,1007.6 5630.9,1007.6 5630.9,1007.6"
            className="darkFill"
          />
          <path
            id="Fill-8"
            fill="#0A0A08"
            d="M4189.8,1260.7c-29.2,0-52.8,23-53,51.3c-0.2,28.5,23.2,51.9,52.2,52.1l0.4,4.2v-4.2
		c28.8,0,52.4-23,52.6-51.3C4242.3,1284.3,4218.8,1260.9,4189.8,1260.7"
            className="darkFill"
          />
          <g id="Group-12" transform="translate(960.000000, 87.000000)">
            <g id="Clip-10"></g>
            <defs>
              <filter
                id="Adobe_OpacityMaskFilter"
                filterUnits="userSpaceOnUse"
                x="5013.6"
                y="1173.7"
                width="105.3"
                height="107.6"
              >
                <feColorMatrix
                  type="matrix"
                  values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"
                />
              </filter>
            </defs>
            <mask
              maskUnits="userSpaceOnUse"
              x="5013.6"
              y="1173.7"
              width="105.3"
              height="107.6"
              id="mask-2_2_"
            >
              <g filter="url(#Adobe_OpacityMaskFilter)">
                <polygon
                  id="path-1_2_"
                  fill="#FFFFFF"
                  points="6532.9,1173.7 5013.6,1173.7 5013.6,1790.8 6532.9,1790.8"
                />
              </g>
            </mask>
            <path
              id="Fill-9"
              mask="url(#mask-2_2_)"
              fill="#0A0A08"
              d="M5066.6,1173.7c-29.2,0-52.8,23-53,51.3c-0.2,28.5,23.2,51.9,52.2,52.1l0.4,4.2v-4.2
			c28.8,0,52.4-23,52.6-51.3C5119.1,1197.3,5095.7,1173.9,5066.6,1173.7"
            />
          </g>
        </g>
        <g id="Icon">
          <g>
            <path
              fill="#664F44"
              d="M2122.8,2266.1c-7.9,7.9-17.8,11.8-29.6,11.8c-11.8,0-21.7-3.9-29.6-11.8s-11.8-17.8-11.8-29.6
			c0-11.8,3.9-22.7,12.8-30.6c7.9-7.9,17.8-10.9,28.6-10.9c11.8,0,21.7,3.9,29.6,11.8c7.9,7.9,11.8,17.8,11.8,29.6
			C2135.7,2248.3,2131.7,2258.2,2122.8,2266.1 M2069.6,2211.8c-6.9,6.9-9.9,14.8-9.9,23.7c0,9.9,3,17.8,9.9,24.7
			c6.9,6.9,14.8,9.9,24.7,9.9c9.9,0,17.8-3,24.7-9.9c6.9-6.9,9.9-14.8,9.9-24.7c0-8.9-3-17.8-9.9-23.7c-6.9-6.9-14.8-10.9-24.7-10.9
			C2084.4,2202,2076.5,2204.9,2069.6,2211.8 M2107,2257.2c-3.9,3-7.9,3.9-11.8,3.9c-5.9,0-11.8-2-16.8-6.9
			c-4.9-4.9-6.9-10.9-6.9-17.8s2-12.8,5.9-17.8c3.9-4.9,9.9-6.9,16.8-6.9c3.9,0,7.9,1,11.8,3c4.9,3,8.9,7.9,9.9,15.8h-10.9
			c-1-3-2-5.9-3.9-6.9s-3.9-2-5.9-2c-3,0-5.9,1-7.9,3.9c-2,3-3,6.9-3,11.8s1,8.9,3,10.9c2,2,4.9,3.9,7.9,3.9c3,0,4.9-1,6.9-2l3-5.9
			h9.9C2114,2250.3,2111,2254.2,2107,2257.2 M2211.6,1819.2c0-3.9-1-7.9-1-11.8c0-3-1-4.9-1-6.9c-1-5.9-2-10.9-3.9-16.8
			c-3.9-10.9-9.9-21.7-17.8-30.6s-16.8-15.8-25.7-20.7c-9.9-4.9-19.7-7.9-27.6-9.9c-3,0-4.9-1-6.9-1c10.9-47.4-3-83.9-11.8-100.6
			l2-2c11.8-11.8,21.7-22.7,30.6-33.5l2-2c6.9-9.9,10.9-19.7,13.8-28.6c2-7.9,3.9-14.8,3.9-21.7v-5.9v-3.9v-8.9
			c-1-14.8-3-33.5-12.8-54.3c-4.9-9.9-11.8-20.7-20.7-30.6c-8.9-9.9-19.7-18.7-32.6-25.7c-1-1-2-1-3-2
			c-26.6-38.5-56.2-69.1-82.9-91.8c-13.8-11.8-26.6-21.7-37.5-29.6s-19.7-13.8-24.7-17.8c-11.8-8.9-20.7-13.8-24.7-15.8
			c-9.9-5.9-16.8-10.9-20.7-13.8c-3-3-4.9-3.9-5.9-4.9c0,0,0-1-1-3c-1-3-1-6.9-1-13.8c0-9.9,0-24.7,1-44.4c2-31.6,4.9-74,8.9-132.2
			c1-18.7,1-36.5,1-52.3c-1-26.6-4.9-50.3-9.9-72c-1-4.9-3-9.9-3.9-14.8c-1-3.9-2-5.9-3-7.9c-1-3-2-5.9-3.9-9.9c-1-2-1-3.9-3-5.9
			c-2-4.9-3.9-9.9-6.9-14.8l-1-1l-1-3l-1-2c-4.9-8.9-10.9-16.8-16.8-23.7c-3-13.8-7.9-33.5-16.8-56.2c-7.9-19.7-17.8-41.4-32.6-63.1
			c-4.9-7.9-10.9-14.8-16.8-21.7l2-2c3-3,72-76,127.3-170.7c101.6-176.6,68.1-284.1,21.7-343.3l-1-2l-1-2
			c-44.4-49.3-105.6-78.9-179.6-88.8c-154.9-19.7-330.5,57.2-376.9,78.9L1303,64c-7.9-7.9-15.8-15.8-24.7-21.7
			c-8.9-5.9-18.7-11.8-28.6-16.8c-19.7-8.9-41.4-14.8-65.1-17.8c-46.4-5.9-99.6,0-156.9,14.8c-85.8,21.7-181.5,65.1-282.2,125.3
			C644.9,208.1,540.4,286,438.7,378.7C314.4,491.2,211.8,610.6,136.8,725c-37.5,56.2-68.1,111.5-90.8,164.8
			c-22.7,52.3-37.5,102.6-43.4,148c-3.9,33.5-3.9,65.1,3,93.7c3,12.8,6.9,24.7,12.8,36.5c6.9,14.8,15.8,28.6,26.6,40.5l87.8,91.8
			l9.9,9.9l-1,1l-3,5.9c-2,3-3,5.9-4.9,7.9l-3,5.9c-1,3-3,5.9-3.9,8.9l-3,5.9c-1,3-3,5.9-3.9,8.9c-1,2-2,3.9-2,5.9l-3,8.9
			c-1,2-1,3.9-2,5.9c-1,3-2,5.9-3,9.9c0,1-1,2-1,3s-1,2-1,3c-1,3-2,6.9-3,9.9c0,2-1,3.9-1,5.9c0,1-1,3-1,3.9c0,2-1,3.9-1,5.9
			c0,2-1,3.9-1,5.9c-1,3.9-1,7.9-2,12.8c0,1,0,2-1,3.9v1c0,3-1,5.9-1,7.9c0,3.9-1,8.9-1,12.8v49.3c0,2,0,3.9,1,5.9c0,2,0,4.9,1,7.9
			v3c0,2,1,4.9,1,6.9c0,2,1,3.9,1,6.9c0,1,0,2,1,3c0,1,1,3,1,3.9v2c0,2,1,3.9,2,7.9c0,1,0,1,1,2c1,2,1,3.9,1,4.9l1,2c0,1,0,1,1,2
			l1,2v3c0,1,0,1,1,2l1,2c0,2,1,3,1,4.9c1,2,1,3.9,2,7.9v-1v1c0,1,0,1,1,2c1,3,2,4.9,2,5.9c1,2,2,3.9,3,7.9l1,1c1,2,1,3.9,2,4.9l1,2
			c0,1,1,2,1,3s1,3,2,3.9l1,2c1,1,1,3,2,3.9c1,1,1,2,1,3c1,2,2,3.9,3,6.9l1,1c1,1,1,2,2,3c1,1,1,3,2,3.9c1,1,2,3,3,5.9l1,1
			c1,1,1,2,2,3c1,2,2,3,2,3.9c1,1,2,3,3,4.9v1c1,1,1,2,2,3c1,2,2,3,3,3.9c1,1,2,3,3.9,5.9c1,1,2,2,2,3c1,2,2,3,3,3.9c1,1,2,3,3,4.9
			c1,1,2,3,3,3.9c1,2,2,3,3.9,3.9l3,3l1,1c2,2,3,3.9,4.9,5.9l2,2l3,3l5.9,5.9l2,2l3,3l1,1c2,2,4.9,3.9,6.9,6.9l1,1c1,1,2,1,3,3
			c3,3,5.9,4.9,8.9,7.9l2,1l1,1l2,1c3,2,4.9,3.9,7.9,5.9l1,1c1,1,2,1,2,1l2,2c2,2,3.9,3,5.9,4.9l2,1l2,2l1,1c2,1,4.9,3,6.9,3.9
			c1,1,2,2,3.9,2l2,1l1,1h1c3.9,2,6.9,3.9,9.9,5.9l2,1l11.8,5.9h1l2,1l5.9,3c1,0,2,1,3,1c1,1,2,1,3.9,2h1c2,1,3.9,2,5.9,2h1
			c2,1,3.9,2,6.9,3h1l2,1c1,0,2,1,3,1h1c3,1,5.9,2,9.9,3h1c4.9,2,9.9,3,14.8,3.9h1c3.9,1,8.9,2,12.8,3h3.9c3.9,1,7.9,2,11.8,2h3.9
			c3,0,4.9,1,7.9,1c0,1,1,2,1,3c6.9,18.7,13.8,37.5,21.7,55.2c116.4,322.6,439,573.2,807,619.6c368,45.4,761.6-199.3,921.5-529.8
			c6.9-12.8,12.8-24.7,18.7-37.5c15.8-16.8,28.6-33.5,36.5-51.3C2209.7,1859.6,2212.6,1837.9,2211.6,1819.2 M168.4,1690.9h1H168.4"
            />
          </g>
          <path
            fill="#132134"
            d="M1896.9,205.1c-17.8-19.7-38.5-34.5-61.2-45.4c-3.9-2-7.9-3-12.8-3.9c-2-1-3.9-3-5.9-3.9
		c-19.7-7.9-40.4-12.8-62.2-15.8c-106.6-13.8-228.9,25.7-297.9,53.3c-3.9,2-7.9,3-11.8,4.9c-5.9,3-11.8,4.9-17.8,7.9
		c-9.9,3.9-19.7,8.9-30.6,13.8c-2,1-3,1-4.9,2l-18.7-21.7l-8.9-9.9l-7.9-8.9l-3-3.9l-72-82.9l-5.9-6.9l-7.9-8.9h-1l-20.7,4.9
		l-23.7,4.9c-12.8-5.9-28.6-9.9-46.4-12.8c-35.5-4.9-80.9,0-132.2,12.8c-51.3,12.8-109.5,35.5-170.7,66.1
		C749.5,212,613.4,305.7,481.2,426.1c-46.4,41.4-88.8,84.8-128.3,127.3c-38.5,41.4-73,83.9-104.6,124.3
		c-64.1,83.9-112.5,164.8-144,237.8c-20.7,48.3-33.5,92.7-38.5,131.2c-3.9,27.6-3,52.3,2,72c2,6.9,3.9,11.8,5.9,17.8
		c4.9,11.8,10.9,21.7,18.7,30.6l79.9,83.9l8.9,8.9l1-1c2-3,4.9-5.9,7.9-7.9l1-1l3-3c2-3,4.9-4.9,6.9-7.9c2-2,3-3,4.9-3.9
		c2-1,3-3,4.9-3.9l2-2c2-1,3-3,4.9-3.9c2-2,4.9-3.9,6.9-5.9c1-1,3-2,3.9-3h1c3-2,4.9-3.9,7.9-5.9c2-1,3-2,4.9-3.9
		c3-2,4.9-3.9,7.9-5.9c2-1,3-2,4.9-3c3-2,5.9-3.9,8.9-4.9c2-1,3-2,4.9-3c3-2,5.9-3,8.9-4.9c2-1,3-2,4.9-3l2-1c3-1,4.9-3,7.9-3.9
		c1-1,3-1,3.9-2c2-1,3.9-2,6.9-3l5.9-3l2-1l14.8-5.9c2-1,3-1,4.9-2c2-1,3-1,4.9-2l2-1c1,0,2-1,3-1c2-1,3.9-1,6.9-2h2
		c3-1,4.9-1,7.9-2c2-1,4.9-1,6.9-2c3-1,5.9-1,8.9-2c1,0,2-1,3.9-1c1,0,3,0,3.9-1c3-1,5.9-1,8.9-1s4.9-1,7.9-1c2,0,3.9-1,5.9-1h7.9
		c9.9,0,21.7,0,33.5,1h2c11.8,1,25.7,3,39.5,5.9c3,1,6.9,1,9.9,2c1-1,1-3,2-3.9c7.9-12.8,14.8-26.6,22.7-39.5
		c44.4-74,91.8-139.1,140.1-196.3c16.8-19.7,33.5-37.5,50.3-55.2c70-73,140.1-129.2,203.2-172.7c13.8-9.9,28.6-18.7,41.4-27.6
		c18.7-11.8,36.5-21.7,53.3-31.6c13.8-7.9,26.6-14.8,39.5-20.7c20.7-10.9,39.5-19.7,56.2-26.6l14.8-5.9c6.9-3,13.8-5.9,19.7-7.9
		c-4.9-1-10.9-1-15.8-1h-19.7c-4.9,0-10.9,0-15.8,1c-9.9,1-20.7,2-31.6,3c-3.9,1-6.9,1-10.9,2c26.6-13.8,53.3-23.7,77.9-31.6
		c16.8-4.9,33.5-8.9,49.3-11.8c21.7-3.9,42.4-6.9,61.2-7.9c12.8-1,24.7-1,35.5-1c10.9,0,21.7,1,30.6,1c32.6,2,52.3,6.9,52.3,6.9
		c-4.9-6.9-10.9-11.8-16.8-16.8c-11.8-8.9-24.7-14.8-36.5-19.7c-4.9-2-8.9-3-12.8-4.9c-3-1-5.9-2-7.9-2c5.9-1,10.9-2,15.8-2
		c5.9-1,10.9-1,16.8-2c46.4-3.9,87.8,0,118.4,4.9c36.5,6.9,59.2,15.8,59.2,15.8c-4.9-9.9-12.8-18.7-21.7-26.6
		c-5.9-4.9-12.8-9.9-18.7-13.8c12.8,3,23.7,5.9,35.5,9.9c29.6,8.9,54.3,19.7,74,28.6l17.8,8.9c2,1,3,2,3.9,2
		c17.8,9.9,27.6,16.8,27.6,16.8c-3-10.9-8.9-20.7-16.8-29.6c-2-2-3.9-4.9-5.9-6.9h1c1,0,2,1,3.9,1c1,0,3,1,4.9,2
		c7.9,3,19.7,7.9,33.5,14.8c1,0,1,1,2,1l5.9,3c4.9,3,9.9,4.9,14.8,8.9l14.8,8.9c3,2,4.9,3,7.9,4.9c4.9,3.9,10.9,7.9,15.8,12.8
		c5.9,4.9,10.9,9.9,15.8,14.8l3,3c3.9,3.9,7.9,7.9,10.9,11.8c6.9,7.9,12.8,16.8,18.7,25.7c6.9,10.9,11.8,23.7,16.8,36.5c0,1,0,2,1,2
		c3-3,8.9-8.9,15.8-16.8C1828.8,554.4,2000.5,337.3,1896.9,205.1"
          />
          <g>
            <path
              fill="#90694C"
              d="M2152.4,1826.1c0,0,2-14.8-5.9-28.6c0-1-1-2-1-2l-1-1l-3-3c-2-2-3.9-3.9-6.9-5.9c-1-1-3-2-4.9-3
			c-4.9-2-9.9-3.9-16.8-4.9c-4.9-1-9.9-1-15.8-1h-7.9v2c0,1,1,2,1,3.9c0,1,0,2,1,2c1,3,2,7.9,3,11.8c0,0,0,1-1,3v1
			c-3,6.9-10.9,22.7-28.6,42.4c-3,3.9-5.9,6.9-9.9,10.9c-49.3,51.3-155.9,123.3-380.8,163.8c-76,10.9-147,11.8-206.2,3.9
			c-88.8-10.9-154.9-39.5-186.5-77.9c-3-3-4.9-6.9-7.9-9.9c-2-3-3-4.9-4.9-7.9c-1-1-1-2-2-3c-12.8-23.7-18.7-49.3-18.7-74
			c0-30.6,9.9-59.2,25.7-80.9c12.8-17.8,29.6-31.6,50.3-37.5c9.9-3,20.7-4.9,31.6-3.9c2,0,4.9,0,8.9,1c6.9,1,17.8,2,30.6,3.9
			c1,0,3,0,3.9,1c42.4,4.9,109.5,11.8,190.4,6.9c148-8.9,342.3-55.2,518.9-215.1v-1c1-13.8-1-37.5-17.8-56.2c-3-3-5.9-5.9-9.9-8.9
			c-2-2-4.9-3-6.9-4.9c-3-1-4.9-3-6.9-3.9l-8.9-5.9v-1c-1-2-2-3.9-3.9-5.9c-45.4-69.1-108.5-109.5-134.2-128.3
			c-11.8-8.9-19.7-12.8-19.7-12.8c-54.3-33.5-62.2-44.4-57.2-134.2c2-32.6,4.9-75,8.9-133.2c0-7.9,1-14.8,1-21.7v-4.9v-23.7
			c0-4.9-1-9.9-1-13.8c0-2,0-3.9-1-5.9c-1-4.9-1-9.9-2-14.8c0-2-1-4.9-1-6.9c0-3-1-4.9-2-7.9c-1-2-1-4.9-2-6.9c-1-3-2-5.9-2-8.9
			c0-1-1-2-1-3s-1-2-1-3v-1l-1-3c-1-2-2-3.9-2-5.9v-1l-1-2l-1-2c-2-3.9-3-7.9-4.9-10.9l-1-3c-2-3-3.9-6.9-5.9-9.9l-1-1l-1-1
			c-2-2-3-3.9-4.9-5.9l-2-3c-2-3-3.9-4.9-5.9-6.9v-7.9c-1-3.9-2-8.9-3-13.8c0-1-1-3-1-3.9v-1c0-2-1-3-1-4.9c-1-3.9-2-7.9-3.9-11.8
			c0-1-1-3-1-3.9l-3-8.9c-1-2-1-3.9-2-4.9c-1-3-2-5.9-3.9-8.9l-3-5.9c-1-3-3-5.9-3.9-8.9l-3-5.9c-2-3-3-5.9-4.9-8.9l-3-5.9
			c-2-3-3.9-5.9-5.9-9.9c-1-2-2-3-3-4.9c-1-1-2-3-3-3.9c-3-3-4.9-5.9-7.9-8.9l-2-1c-3.9-3.9-6.9-7.9-10.9-10.9l-1-1
			c-2-1-3.9-3-4.9-3.9h-1c-2-1-3-2-4.9-3l-1-1c-2-1-4.9-3-8.9-4.9c-1-1-3-1-4.9-2c-8.9-3-19.7-6.9-33.5-10.9c-1,0-2,0-3-1
			c-10.9-3-23.7-5.9-37.5-7.9c-4.9-1-8.9-1-13.8-2c-2,0-3.9,0-6.9-1h-3c-10.9-1-20.7-2-31.6-2h-1c-13.8,0-27.6,1-40.5,3.9
			c3-4.9,11.8-10.9,19.7-15.8c10.9-5.9,20.7-9.9,20.7-9.9c-5.9,0-11.8,1-17.8,2c-2,0-3.9,1-5.9,1c-1,0-3,0-3.9,1h-1c-1,0-2,0-3,1
			c-30.6,6.9-60.2,17.8-77,24.7l-5.9,3h-1c-2,0-3,0-4.9,1c11.8-22.7,29.6-34.5,43.4-40.4c2-1,4.9-2,6.9-3c1,0,1,0,2-1
			c2-1,3.9-1,4.9-2c3-1,4.9-1,4.9-1h-15.8c-33.5,3.9-64.1,14.8-89.8,29.6c-17.8,9.9-33.5,20.7-47.4,31.6c-3.9,1-6.9,2-10.9,2
			c-37.5,7.9-79.9,17.8-124.3,31.6c-16.8,4.9-33.5,10.9-50.3,16.8c-12.8,4.9-26.6,9.9-39.5,14.8c-9.9,3.9-18.7,7.9-28.6,11.8
			c-68.1,30.6-136.1,71-192.4,125.3l-5.9,5.9l-14.8,14.8l-5.9,5.9c-2,3-4.9,5.9-6.9,7.9c-10.9,11.8-20.7,24.7-30.6,38.5
			c-6.9,9.9-13.8,20.7-19.7,31.6l-1,1c-12.8,22.7-24.7,46.4-33.5,73c-4.9,12.8-8.9,26.6-12.8,40.5c-1,3.9-2,6.9-3,10.9l-1,2
			c-3.9,14.8-6.9,30.6-8.9,46.4c0,3-1,5.9-1,9.9c-1,7.9-2,15.8-2,24.7c-1,7.9-1,14.8-1,22.7v30.6c0,8.9,0,17.8,1,26.6v1
			c0,8.9,1,17.8,2,27.6c0,3.9,1,7.9,1,11.8v3c0,2,0,3.9,1,6.9c1,9.9,3,20.7,3.9,31.6c0,3,1,5.9,1,8.9c2,8.9,3,18.7,4.9,27.6
			c0,0-2-11.8-1-29.6c0-3.9,1-8.9,1-12.8c1-9.9,3-21.7,6.9-32.6l3-8.9c0-1,1-2,2-3.9c2-4.9,4.9-10.9,7.9-15.8c1,3.9,1,7.9,2,10.9
			v52.3c1,33.5,3.9,74,10.9,108.5c0,0,1-62.2,11.8-70c0,0,18.7,116.4,35.5,159.8c2,5.9,4.9,9.9,6.9,12.8v-11.8l-2-64.1v-6.9
			c12.8,26.6,27.6,53.3,44.4,81.9c4.9,7.9,8.9,15.8,13.8,23.7c-31.6,27.6-61.2,68.1-82.9,115.4c-3,7.9-6.9,15.8-9.9,23.7
			c-2,4.9-3,8.9-4.9,13.8c0,1-1,3-1,3.9c-1,3-2,6.9-3,9.9c0,2-1,3-1,4.9c-1,3-2,6.9-3,9.9c0,2-1,3-1,4.9l-3,14.8
			c-1,4.9-2,9.9-2,14.8c0,2,0,3-1,4.9c0,3.9-1,6.9-1,10.9c0,2,0,3.9-1,5.9c0,3.9-1,6.9-1,10.9v34.5c0,3.9,0,7.9,1,12.8v3
			c0,4.9,1,9.9,2,15.8c0,2,0,3,1,4.9c0,3.9,1,6.9,2,10.9c0,2,1,3.9,1,5.9c1,3,1,6.9,2,9.9c0,2,1,3.9,1,5.9c1,3,2,5.9,2,9.9
			c1,2,1,3.9,2,6.9c1,3,2,6.9,3,9.9c1,2,1,3.9,2,5.9c1,3,2,6.9,3,9.9c1,2,1,3.9,2,5.9c1,3.9,3,7.9,3.9,10.9c1,2,1,3,2,4.9l14.8,32.6
			c1,2,2,3,3,4.9l5.9,11.8c1,2,2,3.9,3.9,5.9c2,3,3.9,6.9,5.9,9.9c1,2,3,3.9,3.9,5.9c2,3,3.9,6.9,6.9,9.9c2,2,3,3.9,4.9,6.9
			c2,3,4.9,6.9,6.9,9.9c2,2,3,3.9,4.9,6.9c3,3,4.9,6.9,7.9,9.9c2,2,3,3.9,4.9,5.9c3,3.9,5.9,6.9,9.9,10.9l4.9,4.9
			c4.9,4.9,9.9,10.9,14.8,15.8c0,0-3-3.9-6.9-11.8c-1-1-2-3-2-4.9l-3-5.9c-1-2-2-3.9-3-6.9c-7.9-17.8-14.8-43.4-13.8-71l8.9,11.8
			c3,6.9,7.9,15.8,12.8,25.7c2,3,3.9,6.9,4.9,9.9c7.9,13.8,15.8,29.6,25.7,44.4c11.8,18.7,23.7,36.5,36.5,53.3
			c0,0-14.8-31.6-19.7-52.3c-1-5.9-2-10.9-1-13.8c1,1,2,3,3.9,3.9c1,1,2,3,3.9,3.9c2,3,4.9,6.9,8.9,11.8l2,2c2,2,3,4.9,4.9,6.9
			c2,3,3.9,5.9,6.9,8.9c20.7,26.6,49.3,62.2,69.1,80.9c6.9,5.9,12.8,10.9,16.8,12.8l-28.6-51.3c65.1,53.3,335.4,121.3,600.8,45.4
			c32.6-8.9,64.1-20.7,95.7-34.5c18.7-7.9,37.5-17.8,56.2-27.6l3-2c12.8-6.9,25.7-14.8,38.5-22.7c9.9-5.9,19.7-12.8,28.6-18.7
			c15.8-10.9,31.6-22.7,47.4-35.5c1-1,2-2,3-2c14.8-12.8,29.6-26.6,42.4-40.4c17.8-18.7,33.5-37.5,47.4-56.2l1-1
			c3.9-4.9,6.9-8.9,9.9-13.8l1-1c1-2,2-3,3-4.9c4.9-6.9,8.9-12.8,13.8-19.7c1-2,2-3,3-4.9c2-3.9,3.9-6.9,6.9-10.9c2-3,3-4.9,4.9-7.9
			c2-3,3.9-5.9,4.9-8.9c3-4.9,5.9-10.9,8.9-15.8l3-5.9l5.9-11.8l3-5.9l1-2c2-4.9,4.9-9.9,6.9-14.8v-1c8.9-7.9,14.8-15.8,20.7-22.7
			c1-1,2-2,2-3C2148.5,1854.7,2152.4,1839.9,2152.4,1826.1 M565,1634.7c0-1,0-2-1-3.9c-3-16.8-5.9-34.5-6.9-52.3v-3.9
			c-3,1-5.9,4.9-9.9,9.9c-1,2-3,3.9-3.9,6.9c-8.9,16.8-16.8,40.4-16.8,40.4c1-7.9,2-15.8,2-23.7v-3c1-7.9,2-16.8,3-24.7v-3
			c1-9.9,3-18.7,4.9-27.6c0-1,0-2,1-3.9v-1c0-1,0-2,1-3c0-2,1-3,1-4.9c1-6.9,3-13.8,3.9-20.7c1-5.9,2-11.8,3-16.8v-2c0-1,0-2,1-2v-2
			c0-1,1-3,1-3.9s0-2,1-3c0-2,1-3.9,1-4.9s1-3,1-3.9c0-2,1-3.9,1-4.9s0-2,1-3c0-1,0-2,1-2c0-2,1-3,1-3.9v-3l-2,2c-2,1-3,3-4.9,3.9
			l-2,2c-2,2-3.9,3.9-4.9,5.9l-1,1l-5.9,8.9l-1,1c0,1-1,1-1,2l-3,5.9v2c-1,3-3,5.9-3.9,8.9c-3,7.9-5.9,15.8-7.9,22.7
			c-1,2-1,4.9-2,5.9c-1,3.9-2,7.9-2,8.9v1v-1c0-3,0-4.9,1-7.9c0-4.9,1-10.9,1-15.8c0-3.9,1-8.9,2-12.8c0-1,0-3,1-3.9
			c0-2,1-3.9,1-5.9s1-3.9,1-5.9v-3c0-2,1-4.9,1-6.9v-4.9v-2c0-1,0-2,1-3c1-3,2-6.9,2-9.9v-1c1-2,1-4.9,2-6.9v-1
			c1-4.9,3-9.9,3.9-14.8v-2c0-1,0-2,1-3c2-7.9,4.9-15.8,6.9-22.7v-1c2-5.9,4.9-12.8,6.9-18.7v-1c1-3,2-4.9,3-7.9
			c2-3.9,3-7.9,4.9-11.8c0-1,1-3,3-6.9c1-1,1-3,2-3.9c1-2,2-3.9,3-6.9l1-2l16.8-30.6c1-1,1-3,2-3.9v-1l1-2c2-3,3.9-6.9,5.9-9.9
			c3-4.9,4.9-9.9,7.9-13.8c4.9-8.9,8.9-16.8,11.8-20.7c1-3,2-3.9,3-4.9c-1,0-5.9-3.9-13.8-8.9c-9.9-5.9-23.7-13.8-40.4-21.7
			c-5.9-3-10.9-4.9-16.8-7.9c-6.9-3-14.8-5.9-22.7-8.9c-2-1-3.9-1-5.9-2c-6.9-2-12.8-3.9-19.7-5.9c-4.9-1-9.9-2-13.8-3
			c-3.9-1-7.9-1-11.8-2c-2,0-3.9,0-5.9-1c-2,0-3.9,0-5.9-1c-4.9,0-10.9-1-15.8-1h-7.9c-11.8,0-22.7,1-33.5,3h-2
			c-19.7,3-38.5,7.9-57.2,14.8c-5.9,2-11.8,4.9-18.7,7.9c-2,1-3,2-4.9,2l-5.9,3c-17.8,8.9-34.5,20.7-50.3,33.5h-1
			c-13.8,10.9-25.7,23.7-36.5,36.5l-8.9,11.8c-3.9,4.9-6.9,9.9-9.9,14.8c-5.9,8.9-11.8,18.7-16.8,28.6c-2,3.9-3.9,6.9-4.9,10.9
			c0,1-1,1-1,2c-9.9,23.7-17.8,48.3-20.7,75c-1,4.9-1,9.9-2,15.8v2c0,3.9-1,7.9-1,12.8v17.8c0,3.9,0,7.9,1,11.8v4.9c0,3,1,5.9,1,8.9
			v1c0,2,0,3,1,4.9c0,3,1,5.9,1,7.9c0,2,1,3.9,1,5.9c1,3,1,4.9,2,7.9c0,2,1,3.9,2,5.9v1l1,2v2l1,2c1,2,1,3.9,2,5.9c1,2,1,4.9,2,6.9
			c1,2,2,3.9,2,6.9l1,2c1,2,1,3,2,4.9c1,2,2,3.9,3,6.9l3,5.9c1,1,1,2,2,3c1,1,1,2,2,3l3,5.9c1,2,2,4.9,3.9,6.9c1,2,2,3.9,3,4.9
			c1,2,3,4.9,3.9,6.9c1,2,2,3,3,4.9c1,2,3,4.9,4.9,6.9c1,2,2,3,3,3.9c2,2,3,4.9,4.9,6.9c1,1,2,3,3,3.9c2,2,3.9,4.9,5.9,6.9
			c1,1,2,2,3,3.9c2,2,3.9,4.9,5.9,6.9l3,3c2,2,3.9,4.9,6.9,6.9l3,3l6.9,6.9c1,1,2,2,3,2c2,2,4.9,3.9,7.9,6.9c1,1,2,1,3,2
			c3,2,4.9,3.9,7.9,5.9c1,1,2,1,2,2l8.9,5.9l2,1l8.9,5.9l2,1c3,2,6.9,3.9,9.9,5.9l2,1c3,2,6.9,3.9,9.9,4.9l2,1c3.9,2,6.9,3,10.9,4.9
			l1,1c3.9,2,7.9,3,10.9,4.9h1c3.9,1,7.9,3,11.8,3.9h1c2,1,3.9,1,5.9,2c2,1,3.9,1,6.9,2h1c3.9,1,8.9,2,12.8,3s8.9,2,12.8,2
			c13.8,2,27.6,3,41.4,3.9h9.9c4.9,0,8.9,0,13.8-1c5.9-1,12.8-1,19.7-2c5.9-1,11.8-2,16.8-3c8.9-2,17.8-3.9,25.7-6.9
			c25.7-6.9,47.4-16.8,61.2-27.6c0,0-8.9-39.5-15.8-78.9C567,1642.6,566,1638.6,565,1634.7"
            />
          </g>
          <path
            fill="#2F83C1"
            d="M1198.4,168.6l-3-39.5l19.7-43.4l7.9-2c-12.8-5.9-28.6-9.9-46.4-12.8c-35.5-4.9-80.9,0-132.2,12.8
		c-51.3,12.8-109.5,35.5-170.7,66.1C750.5,211,614.3,304.8,482.1,425.1c-46.4,41.4-88.8,84.8-128.3,127.3
		c-38.5,41.4-73,83.9-104.6,124.3c-64.1,83.9-112.5,164.8-144,237.8c-20.7,48.3-33.5,92.7-38.5,131.2c-3.9,27.6-3,52.3,2,72
		c2,6.9,3.9,11.8,5.9,17.8c4.9,11.8,10.9,21.7,18.7,30.6l79.9,83.9l8.9,8.9l1-1c2-3,4.9-5.9,7.9-7.9l1-1l3-3c2-3,4.9-4.9,6.9-7.9
		c2-2,3-3,4.9-3.9c2-1,3-3,4.9-3.9l2-2c2-1,3-3,4.9-3.9c2-2,4.9-3.9,6.9-5.9c1-1,3-2,3.9-3h1c3-2,4.9-3.9,7.9-5.9c2-1,3-2,4.9-3.9
		c3-2,4.9-3.9,7.9-5.9c2-1,3-2,4.9-3c3-2,5.9-3.9,8.9-4.9c2-1,3-2,4.9-3c3-2,5.9-3,8.9-4.9c2-1,3-2,4.9-3l2-1
		c18.7-52.3,47.4-110.5,84.8-169.7c45.4-73,103.6-150,172.7-226.9c43.4-48.3,90.8-96.7,142.1-143.1
		c66.1-60.2,134.2-114.4,201.3-161.8c45.4-31.6,89.8-60.2,133.2-84.8c3.9-2,7.9-4.9,12.8-6.9c11.8-6.9,22.7-12.8,34.5-18.7
		c24.7-12.8,49.3-24.7,73-34.5l-11.8-13.8l-2-2l-14.8-16.8l-81.9-98.7l1-71l-1-1l58.2-17.8L1198.4,168.6"
          />
          <g>
            <path
              fill="#FCE9CD"
              d="M2152.4,1826.1c0,0,2-14.8-5.9-28.6c0-1-1-2-1-2l-1-1l-3-3c-2-2-3.9-3.9-6.9-5.9c-1-1-3-2-4.9-3
			c-4.9-2-9.9-3.9-16.8-4.9c-4.9-1-9.9-1-15.8-1h-7.9v2c0,1,1,2,1,3.9c0,1,0,2,1,2c1,3,2,7.9,3,11.8c0,0,0,1-1,3v1
			c-3,6.9-10.9,22.7-28.6,42.4c-3,3.9-5.9,6.9-9.9,10.9c-49.3,51.3-155.9,123.3-380.8,163.8c-76,10.9-147,11.8-206.2,3.9
			c-88.8-10.9-154.9-39.5-186.5-77.9c-3-3-4.9-6.9-7.9-9.9c-2-3-3-4.9-4.9-7.9c-1-1-1-2-2-3c-12.8-23.7-18.7-49.3-18.7-74
			c0-30.6,9.9-59.2,25.7-80.9c12.8-17.8,29.6-31.6,50.3-37.5c9.9-3,20.7-4.9,31.6-3.9c2,0,4.9,0,8.9,1c6.9,1,17.8,2,30.6,3.9
			c1,0,3,0,3.9,1c42.4,4.9,109.5,11.8,190.4,6.9c148-8.9,342.3-55.2,518.9-215.1v-1c1-13.8-1-37.5-17.8-56.2c-3-3-5.9-5.9-9.9-8.9
			c-2-2-4.9-3-6.9-4.9c-3-1-4.9-3-6.9-3.9l-8.9-5.9v-1c-1-2-2-3.9-3.9-5.9c-45.4-69.1-108.5-109.5-134.2-128.3
			c-11.8-8.9-19.7-12.8-19.7-12.8c-54.3-33.5-62.2-44.4-57.2-134.2c2-32.6,4.9-75,8.9-133.2c0-7.9,1-14.8,1-21.7v-4.9v-23.7
			c0-4.9-1-9.9-1-13.8c0-2,0-3.9-1-5.9c-1-4.9-1-9.9-2-14.8c0-2-1-4.9-1-6.9c0-3-1-4.9-2-7.9c-1-2-1-4.9-2-6.9c-1-3-2-5.9-2-8.9
			c0-1-1-2-1-3s-1-2-1-3v-1l-1-3c-1-2-2-3.9-2-5.9v-1l-1-2l-1-2c-2-3.9-3-7.9-4.9-10.9l-1-3c-2-3-3.9-6.9-5.9-9.9l-1-1l-1-1
			c-2-2-3-3.9-4.9-5.9l-2-3c-2-3-3.9-4.9-5.9-6.9l-7.9-7.9c-3-2-5.9-3.9-8.9-4.9c-1-1-2-1-3-1l-5.9-3c-1,0-2-1-3-1c-2-1-3.9-1-4.9-2
			c-1,0-2-1-3.9-1c-2,0-3-1-4.9-1H1744c-1,0-2,0-3,1c-1,0-2,0-3,1c-2,0-3.9,1-6.9,2h-2c-5.9,2-10.9,3.9-16.8,5.9l-2,1
			c-2,1-3.9,2-6.9,3l-3,1l-5.9,3l-3,2l-5.9,3l-3,2c-2,1-3.9,3-5.9,3.9l-2,1c-2,2-4.9,3-6.9,4.9l-1,1c-3,2-4.9,3.9-7.9,4.9
			c-4.9,3-9.9,6.9-14.8,9.9c-3.9,3-7.9,5.9-12.8,8.9l-2,1c-3.9,3-8.9,5.9-12.8,7.9l-2,1c-3.9,3-8.9,4.9-12.8,7.9l-2,1
			c-3.9,3-8.9,4.9-13.8,6.9l-2,1c-4.9,2-8.9,3.9-13.8,5.9l-2,1c-4.9,2-9.9,3-14.8,4.9h-1c-4.9,1-10.9,2-15.8,3c-8.9,1-18.7,2-27.6,2
			c-14.8,0-29.6-1-45.4-3l-14.8-3c-2,0-3.9-1-4.9-1c-12.8-3-26.6-5.9-39.5-8.9c-5.9-1-10.9-3-16.8-3.9c-8.9-2-17.8-3.9-27.6-5.9
			c-2,0-3.9-1-4.9-1s-3-1-3.9-1c-2,0-3.9-1-5.9-1c-2,0-3.9-1-5.9-1h-2c-2,0-3.9-1-4.9-1c-3.9-1-7.9-2-12.8-2c-1,0-3,0-3.9-1
			c-3,0-5.9-1-8.9-1c-2,0-3,0-4.9-1c-3,0-4.9-1-7.9-1h-45.4c-4.9,0-8.9,1-13.8,1h-3.9c-3,0-5.9,1-8.9,1h-1c-2,0-3,1-4.9,1
			c-3,1-5.9,1-8.9,2c-2,0-3.9,1-4.9,1c-3,1-5.9,2-8.9,2c-2,0-3,1-4.9,2c-3,1-6.9,2-9.9,3c-2,1-3,1-3.9,2c-4.9,2-9.9,3.9-13.8,5.9
			c-37.5,21.7-70,51.3-94.7,84.8c-8.9,11.8-16.8,23.7-23.7,35.5c-7.9,12.8-13.8,26.6-19.7,40.5c-2,3.9-3,7.9-4.9,11.8
			c-2,6.9-4.9,13.8-6.9,20.7c0,1,0,2-1,2c-2,7.9-3.9,15.8-5.9,24.7c-2,8.9-3,16.8-3.9,25.7c-1,4.9-1,8.9-1,13.8
			c-1,12.8-1,24.7,0,37.5v3c0,3,0,5.9,1,9.9c1,8.9,2,17.8,3.9,26.6c1,3,2,5.9,2,9.9c1,3,1,5.9,2,8.9c5.9,22.7,14.8,43.4,25.7,61.2
			c40.5,70,107.5,112.5,156.9,135.2c18.7,7.9,38.5,15.8,59.2,22.7l-2,2c-3.9,1-7.9,2-12.8,3c-6.9,2-15.8,4.9-24.7,7.9
			c-9.9,3-21.7,7.9-33.5,12.8c-31.6,12.8-67.1,31.6-98.7,57.2c-3.9,3-7.9,6.9-11.8,9.9c-6.9,5.9-12.8,11.8-18.7,18.7l-2,2l-3,3
			c-2,3-4.9,4.9-6.9,7.9l-3,3c-3,3.9-5.9,7.9-7.9,11.8c-3,3.9-4.9,7.9-7.9,11.8c-18.7,32.6-29.6,72-26.6,119.4v-2c0-1,0-2,1-3v-1
			c0-1,1-2,1-3.9v-1c0-1,1-3,1-4.9c0-2,1-3,2-4.9v-1c1-2,1-3.9,2-5.9v-1c1-2,1-3.9,2-5.9v-1c1-2,2-3.9,3-6.9l1-1c1-2,2-4.9,3.9-6.9
			c2-3.9,3.9-6.9,5.9-10.9c1-1,2-3,3-3.9l1-1l5.9-8.9c2-2,3.9-4.9,5.9-6.9l2-2c2-2,3-3.9,4.9-4.9l2-2c2-2,4.9-3.9,6.9-6.9
			c0,0-42.4,96.7-23.7,172.7v-1v-2v-3v-3.9v-4.9c0-2,0-3.9,1-5.9c0-2,0-3.9,1-5.9v-1c0-2,1-4.9,1-6.9v-2c1-3,1-4.9,2-7.9v-1
			c1-3,2-5.9,2-7.9c0-1,1-2,1-3l1-2c1-2,2-4.9,3-6.9c1-3,3-5.9,4.9-8.9c0-1,1-2,1-2l5.9-8.9c0,0-2,23.7-2,52.3c0,4.9,0,8.9,1,13.8
			c0,3,0,5.9,1,8.9c2-30.6,7.9-59.2,15.8-85.8c-7.9,26.6-13.8,55.2-15.8,85.8c-1,19.7-1,39.5,1,60.2c3,34.5,11.8,72,26.6,110.5
			c0,0-13.8-44.4,5.9-88.8l5.9,11.8c2,12.8,3.9,31.6,7.9,52.3c3.9,34.5,19.7,138.1,90.8,210.1c0,0-26.6-34.5-22.7-78.9
			c3,5.9,5.9,10.9,7.9,15.8c11.8,22.7,35.5,64.1,62.2,93.7c0,0-20.7-40.4-15.8-49.3c0,0,69.1,85.8,90.8,93.7l-28.6-47.4l-1-1
			c99.6,73,237.8,99.6,407.5,52.3c29.6-7.9,59.2-18.7,90.8-31.6c18.7-7.9,37.5-17.8,56.2-27.6l3-2c12.8-6.9,25.7-14.8,38.5-22.7
			c9.9-5.9,19.7-12.8,28.6-18.7c15.8-10.9,31.6-22.7,47.4-35.5c1-1,2-2,3-2c14.8-12.8,29.6-26.6,42.4-40.4
			c17.8-18.7,33.5-37.5,47.4-56.2l1-1c3.9-4.9,6.9-8.9,9.9-13.8l1-1c1-2,2-3,3-4.9c4.9-6.9,8.9-12.8,13.8-19.7c1-2,2-3,3-4.9
			c2-3.9,3.9-6.9,6.9-10.9c2-3,3-4.9,4.9-7.9c2-3,3.9-5.9,4.9-8.9c3-4.9,5.9-10.9,8.9-15.8l3-5.9l5.9-11.8l3-5.9l1-2
			c2-4.9,4.9-9.9,6.9-14.8v-1c8.9-7.9,14.8-15.8,20.7-22.7c1-1,2-2,2-3C2148.5,1854.7,2152.4,1839.9,2152.4,1826.1 M557.1,1574.5
			c-3,1-5.9,4.9-9.9,9.9c-1,2-3,3.9-3.9,6.9c-8.9,16.8-16.8,40.4-16.8,40.4c1-7.9,2-15.8,2-23.7v-3c1-7.9,2-16.8,3-24.7v-3
			c1-9.9,3-18.7,4.9-27.6c0-1,0-2,1-3.9v-1c0-1,0-2,1-3c0-2,1-3,1-4.9c1-6.9,3-13.8,3.9-20.7c1-5.9,2-11.8,3-16.8v-2c0-1,0-2,1-2v-2
			c0-1,1-3,1-3.9s0-2,1-3c0-2,1-3.9,1-4.9s1-3,1-3.9c0-2,1-3.9,1-4.9s0-2,1-3c0-1,0-2,1-2c0-2,1-3,1-3.9v-3l-2,2c-2,1-3,3-4.9,3.9
			l-2,2c-2,2-3.9,3.9-4.9,5.9l-1,1l-5.9,8.9l-1,1c0,1-1,1-1,2l-3,5.9v2c-1,3-3,5.9-3.9,8.9c-3,7.9-5.9,15.8-7.9,22.7
			c-1,2-1,4.9-2,5.9c-1,3.9-2,7.9-2,8.9v1v-1c0-3,0-4.9,1-7.9c0-4.9,1-10.9,1-15.8c0-3.9,1-8.9,2-12.8c0-1,0-3,1-3.9
			c0-2,1-3.9,1-5.9s1-3.9,1-5.9v-3c0-2,1-4.9,1-6.9v-4.9v-2c0-1,0-2,1-3c1-3,2-6.9,2-9.9v-1c1-2,1-4.9,2-6.9v-1
			c1-4.9,3-9.9,3.9-14.8v-2c0-1,0-2,1-3c2-7.9,4.9-15.8,6.9-22.7v-1c2-5.9,4.9-12.8,6.9-18.7v-1c1-3,2-4.9,3-7.9
			c2-3.9,3-7.9,4.9-11.8c0-1,1-3,3-6.9c1-1,1-3,2-3.9c1-2,2-3.9,3-6.9l1-2c-1-1-3-1-3.9-2h-1l-2-1c-8.9-3.9-18.7-7.9-28.6-10.9h-2
			c-6.9-2-12.8-3-19.7-3.9c-3.9,0-7.9-1-11.8-1h-9.9c-4.9,0-9.9,1-14.8,1c-2,0-3,0-4.9,1c-2,0-4.9,1-6.9,1c-1,0-2,0-3,1
			c-2,0-3,1-4.9,1c-3,1-6.9,2-9.9,3c-1,0-3,1-3.9,1c-3.9,2-8.9,3-12.8,4.9c-2,1-3,2-4.9,2l-5.9,3l-2,1c-2,1-3.9,2-5.9,3.9
			c-2,1-3.9,2-5.9,3.9c-2,1-3.9,3-5.9,3.9c-1,1-3,2-3.9,3c-3,3-6.9,5.9-9.9,8.9l-4.9,4.9c-3,3-4.9,5.9-7.9,8.9l-1,1
			c-1,2-3,3-3.9,4.9l-3,3c-4.9,6.9-9.9,14.8-13.8,22.7c-1,1-1,2-2,3c-2,3.9-3,7.9-4.9,11.8c-1,2-1,3-2,4.9c-1,3.9-3,7.9-3.9,12.8
			v3.9c-1,3-1,5.9-2,8.9c0,2,0,3-1,4.9c0,2,0,3-1,4.9v3.9c0,4.9-1,9.9,0,14.8c0,3.9,0,7.9,1,11.8v2c0,2,1,3.9,1,5.9
			c1,7.9,3,14.8,4.9,22.7c2,5.9,3.9,10.9,5.9,15.8c3.9,9.9,8.9,18.7,14.8,27.6c11.8,17.8,27.6,33.5,46.4,45.4
			c9.9,6.9,21.7,11.8,33.5,15.8c10.9,3.9,22.7,6.9,35.5,7.9c3,0,5.9,1,7.9,1H490c3.9,0,7.9-1,11.8-1c2,0,4.9,0,6.9-1
			c5.9-1,10.9-2,16.8-3h1c2-1,3.9-1,6.9-2c6.9-2,13.8-4.9,19.7-7.9l1-1c2-1,3.9-2,4.9-2c-3-16.8-5.9-34.5-6.9-52.3
			C557.1,1576.5,557.1,1575.5,557.1,1574.5"
            />
          </g>
          <path fill="#4D9ED7" d="M1223.1,180.4l3,2L1223.1,180.4z" />
          <path
            fill="#FFFFFF"
            d="M2092.2,1785.6c0-1,0-2-1-2c-1-2-1-3-1-3.9c-4.9-15.8-10.9-27.6-16.8-36.5c-6.9-10.9-12.8-16.8-12.8-16.8
		l3-11.8c1-4.9,2-9.9,2-14.8c3.9-35.5-4.9-63.1-12.8-78.9c-1-3-2-4.9-3-6.9c-5.9,3.9-10.9,7.9-16.8,11.8
		c-106.6,72-225.9,118.4-355.2,138.1c-88.8,13.8-182.5,14.8-280.2,3l2,1c0,0-59.2,7.9-75,64.1l1,1l-4.9,37.5
		c2,51.3,24.7,82.9,51.3,99.6c27.6,10.9,61.2,18.7,98.7,23.7c64.1,7.9,139.1,5.9,214.1-5.9c100.6-13.8,242.7-67.1,344.3-136.1
		c22.7-15.8,44.4-32.6,62.2-49.3l1-1l1-1C2093.2,1793.5,2092.2,1789.6,2092.2,1785.6"
          />
          <g>
            <path
              fill="#F2D2AC"
              d="M1899.9,1298.2c-54.3-33.5-62.2-44.4-57.2-134.2c2-32.6,4.9-75,8.9-133.2c0-7.9,1-14.8,1-21.7v-4.9v-23.7
			c0-4.9-1-9.9-1-13.8c0-2,0-3.9-1-5.9c-1-4.9-1-9.9-2-14.8c0-2-1-4.9-1-6.9c0-3-1-4.9-2-7.9c-1-2-1-4.9-2-6.9
			c-7.9-11.8-16.8-21.7-27.6-30.6l-2-2l-2-1h-1c-2-1-3-2-4.9-3c-2-1-3.9-2-4.9-3c-15.8-9.9-34.5-15.8-53.3-18.7
			c-10.9-2-21.7-2-32.6-2c-4.9,0-10.9,1-15.8,1c-13.8,2-27.6,4.9-42.4,8.9c-4.9,3-9.9,6.9-14.8,9.9c-3.9,3-7.9,5.9-12.8,8.9l-2,1
			c-3.9,3-8.9,5.9-12.8,7.9l-2,1c-3.9,3-8.9,4.9-12.8,7.9l-2,1c-3.9,3-8.9,4.9-13.8,6.9l-2,1c-4.9,2-8.9,3.9-13.8,5.9l-2,1
			c-4.9,2-9.9,3-14.8,4.9h-1c-4.9,1-10.9,2-15.8,3c-8.9,1-18.7,2-27.6,2c-14.8,0-29.6-1-45.4-3l-14.8-3c-2,0-3.9-1-4.9-1
			c-12.8-3-26.6-5.9-39.5-8.9c-5.9-1-10.9-3-16.8-3.9c-8.9-2-17.8-3.9-27.6-5.9c-2,0-3.9-1-4.9-1c-7.9,0-15.8,1-22.7,2h-2
			c-22.7,3-44.4,7.9-67.1,15.8c-1,0-2,1-3.9,1c-3.9,1-6.9,3-10.9,3.9c-4.9,2-8.9,3.9-13.8,5.9c-32.6,13.8-63.1,33.5-92.7,58.2
			c-11.8,9.9-22.7,20.7-33.5,31.6c-15.8,16.8-29.6,34.5-42.4,53.3c-1,1-1,2-2,3c-6.9,9.9-12.8,20.7-17.8,30.6
			c-2,3.9-3.9,7.9-5.9,10.9v1c-5.9,12.8-10.9,24.7-14.8,37.5c0,1,0,2-1,2c-1,2-1,3.9-2,5.9c-2,5.9-3.9,11.8-4.9,17.8
			c-2,5.9-3,11.8-3.9,18.7c0,1-1,3-1,3.9c-1,3.9-1,7.9-2,10.9c-2,13.8-3,27.6-3.9,41.4v9.9c5.9,22.7,14.8,43.4,25.7,61.2
			c40.4,70,107.5,112.5,156.9,135.2c18.7,7.9,38.5,15.8,59.2,22.7l1,1l1-1c3-2,4.9-3.9,7.9-5.9c1-1,2-1,3-2l8.9-5.9c1-1,2-1,3-2
			l8.9-5.9c1-1,2-1,3-2c3-2,5.9-3.9,9.9-5.9c1-1,2-1,3-2c3-2,6.9-3.9,9.9-5.9c1-1,2-1,3-2c3-2,6.9-3.9,9.9-5.9c1-1,3-1,3.9-2
			c3-2,6.9-3.9,9.9-5.9c1-1,3-2,3.9-2c3-2,6.9-3.9,10.9-5.9c2-1,3-2,4.9-2c3.9-2,6.9-3.9,10.9-4.9c2-1,3-2,4.9-2
			c3.9-2,6.9-3,10.9-4.9l5.9-3c3.9-2,6.9-3,10.9-4.9c2-1,3.9-2,6.9-3c3.9-1,6.9-3,10.9-3.9c2-1,4.9-2,6.9-3c3.9-1,6.9-3,10.9-3.9
			c3-1,4.9-2,7.9-3c3.9-1,6.9-3,10.9-3.9l8.9-3c3-1,6.9-2,9.9-3.9c3-1,6.9-2,9.9-3c3-1,6.9-2,9.9-3c3.9-1,7.9-2,10.9-3s5.9-2,9.9-3
			c3.9-1,7.9-2,12.8-3.9c2-1,3.9-1,5.9-2c15.8-40.5,64.1-141.1,146-151.9c8.9-1,16.8-2,24.7-2h5.9c-1-5.9-2-11.8-2-18.7
			c-3-43.4,10.9-79.9,29.6-80.9c14.8-1,26.6,20.7,28.6,27.6c5.9,12.8,9.9,29.6,10.9,48.3c1,16.8-1,32.6-3.9,46.4c2,2,3,3.9,4.9,4.9
			c18.7,23.7,21.7,55.2,20.7,78.9c6.9-1,14.8-1,22.7-2c2,0,3.9,0,5.9-1h3.9c8.9-1,18.7-2,28.6-2c21.7-2,43.4-3,66.1-3.9
			C1907.8,1302.2,1899.9,1298.2,1899.9,1298.2 M2106.1,1906c-35.5,31.6-73,57.2-110.5,77.9c-18.7,9.9-37.5,18.7-55.2,26.6
			c-40.5,17.8-78.9,29.6-113.5,38.5c-76,18.7-128.3,18.7-128.3,18.7c41.4,22.7,93.7,22.7,129.2,19.7c24.7-2,41.4-6.9,41.4-6.9
			c-1,14.8-3,28.6-4.9,42.4c-3,14.8-6.9,27.6-11.8,39.5v1c-5.9,14.8-13.8,27.6-21.7,39.5c-1,1-2,3-3,3.9l3-2
			c12.8-6.9,25.7-14.8,38.5-22.7c9.9-5.9,19.7-12.8,28.6-18.7c15.8-10.9,31.6-22.7,47.4-35.5c1-1,2-2,3-2
			c14.8-12.8,29.6-26.6,42.4-40.4c17.8-18.7,33.5-37.5,47.4-56.2l1-1c3.9-4.9,6.9-8.9,9.9-13.8l1-1c1-2,2-3,3-4.9
			c4.9-6.9,8.9-12.8,13.8-19.7c1-2,2-3,3-4.9c2-3.9,3.9-6.9,6.9-10.9c2-3,3-4.9,4.9-7.9c2-3,3.9-5.9,4.9-8.9
			c3-4.9,5.9-10.9,8.9-15.8l3-5.9l5.9-11.8l3-5.9l1-2c2-4.9,4.9-9.9,6.9-14.8C2111,1901,2109,1903,2106.1,1906 M1206.3,1508.4
			c-9.9,3-21.7,7.9-33.5,12.8c-31.6,12.8-67.1,31.6-98.7,57.2c-3.9,3-7.9,6.9-11.8,9.9c-6.9,5.9-12.8,11.8-18.7,18.7l-2,2l-3,3
			c-2,3-4.9,4.9-6.9,7.9l-3,3c-3,3.9-5.9,7.9-7.9,11.8c-3,3.9-4.9,7.9-7.9,11.8c-18.7,32.6-29.6,72-26.6,119.4v-2c0-1,0-2,1-3v-1
			c0-1,1-2,1-3.9v-1c0-1,1-3,1-4.9c0-2,1-3,2-4.9v-1c1-2,1-3.9,2-5.9v-1c1-2,1-3.9,2-5.9v-1c1-2,2-3.9,3-6.9l1-1c1-2,2-4.9,3.9-6.9
			c2-3.9,3.9-6.9,5.9-10.9c1-1,2-3,3-3.9l1-1l5.9-8.9c2-2,3.9-4.9,5.9-6.9l2-2c2-2,3-3.9,4.9-4.9l2-2c2-2,4.9-3.9,6.9-6.9
			c0,0-42.4,96.7-23.7,172.7v-1v-2v-3v-3.9v-4.9c0-2,0-3.9,1-5.9c0-2,0-3.9,1-5.9v-1c0-2,1-4.9,1-6.9v-2c1-3,1-4.9,2-7.9v-1
			c1-3,2-5.9,2-7.9c0-1,1-2,1-3l1-2c1-2,2-4.9,3-6.9c1-3,3-5.9,4.9-8.9c0-1,1-2,1-2l5.9-8.9c0,0-2,23.7-2,52.3c0,4.9,0,8.9,1,13.8
			c0,3,0,5.9,1,8.9c2-30.6,7.9-59.2,15.8-85.8c2-6.9,4.9-14.8,6.9-21.7c0-1,1-2,1-3c3-6.9,4.9-12.8,7.9-19.7c1-1,1-2,2-3.9
			c3-5.9,5.9-12.8,8.9-18.7c1-1,1-2,1-3c6.9-12.8,13.8-24.7,20.7-35.5c1-1,1-2,2-3c3-3.9,5.9-7.9,8.9-12.8c1-1,1-2,2-3c1-1,1-2,2-3
			c3.9-4.9,6.9-9.9,10.9-14.8c1-1,1-2,2-2c3.9-4.9,7.9-9.9,11.8-13.8c4.9-5.9,9.9-10.9,14.8-16.8c3.9-3.9,6.9-7.9,10.9-10.9l3.9-3.9
			c7.9-7.9,15.8-15.8,23.7-22.7c21.7-19.7,39.5-32.6,45.4-36.5c-3.9,1-7.9,2-12.8,3C1224.1,1502.5,1216.2,1505.4,1206.3,1508.4"
            />
            <path
              fill="#F2D2AC"
              d="M1774.6,1294.3c0,0,0-2-1-5.9c-3.9-13.8-17.8-47.4-72-42.4c-57.2,5.9-77,52.3-81.9,68.1c-1,3-1,4.9-1,4.9
			l2,1c6.9-5.9,20.7-5.9,33.5,3c5.9,3.9,9.9,7.9,13.8,12.8c4.9,7.9,5.9,15.8,3,21.7l-3,3c0,0,2,1,5.9,2
			c15.8,4.9,60.2,13.8,83.9-16.8c1-2,2-3,3-4.9c-4.9-2-6.9-8.9-6.9-17.8c0-3.9,1-7.9,2-12.8
			C1758.8,1298.2,1767.7,1291.3,1774.6,1294.3 M1489.4,1716.6c-37.5,7.9-70,13.8-94.7,17.8c1,0,3,0,3.9,1
			c42.4,4.9,109.5,11.8,190.4,6.9c148-8.9,342.3-55.2,518.9-215.1v-1c1-13.8-1-37.5-17.8-56.2c-3-3-5.9-5.9-9.9-8.9c-1,0-1-1-2-1
			c-4.9-3-8.9-4.9-12.8-7.9l-8.9-5.9v-1c-10.9,15.8-23.7,30.6-37.5,44.4c-4.9,4.9-9.9,9.9-15.8,14.8
			c-48.3,43.4-111.5,80.9-179.6,110.5C1711.4,1664.3,1585.1,1696.8,1489.4,1716.6 M554.2,1459.1c-2,1-3,3-4.9,3.9l-2,2
			c-2,2-3.9,3.9-4.9,5.9l-1,1l-5.9,8.9l-1,1c0,1-1,1-1,2l-3,5.9v2c-1,3-3,5.9-3.9,8.9c-3,7.9-5.9,15.8-7.9,22.7c-1,2-1,4.9-2,5.9
			c-1,3.9-2,7.9-2,8.9v1v-1c0-3,0-4.9,1-7.9c0-4.9,1-10.9,1-15.8c0-3.9,1-8.9,2-12.8c0-1,0-3,1-3.9c0-2,1-3.9,1-5.9c0-2,1-3.9,1-5.9
			v-3c0-2,1-4.9,1-6.9v-4.9v-2c0-1,0-2,1-3c1-3,2-6.9,2-9.9v-1c1-2,1-4.9,2-6.9v-1c1-4.9,3-9.9,3.9-14.8v-2c0-1,0-2,1-3
			c2-7.9,4.9-15.8,6.9-22.7v-1c2-5.9,4.9-12.8,6.9-18.7v-1c1-3,2-4.9,3-7.9c2-3.9,3-7.9,4.9-11.8c0-1,1-3,3-6.9c1-1,1-3,2-3.9
			c1-2,2-3.9,3-6.9l1-2c-1-1-3-1-3.9-2h-1l-2-1c-8.9-3.9-18.7-7.9-28.6-10.9h-2c-6.9-2-12.8-3-19.7-3.9c-3.9,0-7.9-1-11.8-1h-9.9
			c-4.9,0-9.9,1-14.8,1c-2,0-3,0-4.9,1c-2,0-4.9,1-6.9,1c-1,0-2,0-3,1c-2,0-3,1-4.9,1c-3,1-6.9,2-9.9,3c-1,0-3,1-3.9,1
			c-3.9,2-8.9,3-12.8,4.9c-2,1-3,2-4.9,2l-5.9,3l-2,1c-2,1-3.9,2-5.9,3.9c-2,1-3.9,2-5.9,3.9c-2,1-3.9,3-5.9,3.9c-1,1-3,2-3.9,3
			c-3,3-6.9,5.9-9.9,8.9l-4.9,4.9c-3,3-4.9,5.9-7.9,8.9l-1,1c-1,2-3,3-3.9,4.9l-3,3c-4.9,6.9-9.9,14.8-13.8,22.7c-1,1-1,2-2,3
			c-2,3.9-3,7.9-4.9,11.8c-1,2-1,3-2,4.9c0,1,1,2,1,3c1,3.9,2,6.9,3,10.9v2c1,3,3,6.9,3.9,9.9c3.9,9.9,7.9,18.7,12.8,26.6
			c1,1,1,2,2,3v1l1,1c3,5.9,5.9,10.9,8.9,14.8c4.9,6.9,8.9,11.8,11.8,15.8l2,2c0,1,1,1,1,1l2,2c0-2-1-3.9-1-4.9
			c-3-12.8-3.9-24.7-3.9-37.5v-2c0-7.9,0-15.8,1-22.7l3,8.9c1,3.9,3,7.9,3.9,11.8v2c5.9,16.8,12.8,30.6,19.7,41.4
			c7.9,13.8,14.8,22.7,19.7,28.6c1,1,2,2,2,3l2,2c-3-13.8-4.9-26.6-4.9-39.5v-12.8c0-5.9,0-10.9,1-15.8c2,8.9,4.9,17.8,7.9,25.7v1
			c10.9,28.6,23.7,49.3,32.6,62.2l1,1c2,2,3,3.9,3.9,5.9c1,1,2,2,2,3l2,2l1,1v-2c0-2-1-3.9-1-5.9c-1-6.9-2-13.8-3-19.7
			c0-3.9-1-8.9-1-12.8V1539v-2v-7.9c0-4.9,1-10.9,1-15.8c1,3,2,6.9,3,9.9c1,2,2,4.9,3,6.9v1l1,1c1,3,2,5.9,3,7.9
			c2,4.9,3.9,9.9,6.9,13.8c3,5.9,4.9,10.9,7.9,14.8c2,3,3,4.9,3.9,6.9c2,3,3.9,5.9,4.9,7.9c2,2,3,3.9,3.9,5.9l1,1c1,2,3,3,3.9,4.9
			c1,1,2,2,2,3c2,2,3,3.9,3.9,3.9l1,1v-3c1-7.9,2-16.8,3-24.7v-3c1-9.9,3-18.7,4.9-27.6c0-1,0-2,1-3.9v-1c0-1,0-2,1-3c0-2,1-3,1-4.9
			c1-6.9,3-13.8,3.9-20.7c1-5.9,2-11.8,3-16.8v-2c0-1,0-2,1-2v-2c0-1,1-3,1-3.9s0-2,1-3c0-2,1-3.9,1-4.9c0-1,1-3,1-3.9
			c0-2,1-3.9,1-4.9c0-1,0-2,1-3c0-1,0-2,1-2c0-2,1-3,1-3.9v-3L554.2,1459.1 M1749.9,1871.4c13.8-4.9,25.7-8.9,35.5-12.8
			c18.7-7.9,28.6-13.8,28.6-15.8c-1-2-11.8-1-31.6,3.9c-9.9,2-21.7,3.9-36.5,7.9c-6.9,2-14.8,3-22.7,3.9s-16.8,3-25.7,3.9
			c-17.8,3-37.5,4.9-59.2,6.9l-32.6,3c-10.9,1-22.7,1-34.5,2c-47.4,1-99.6-1-152.9-10.9c-27.6-4.9-66.1-17.8-92.7-32.6l-1-1
			c15.8-56.2,75-64.1,75-64.1l-2-1h-3c-8.9-1-15.8-2-22.7-3c-4.9-1-8.9-1-11.8-2c-3,0-5.9-1-6.9-1c-35.5-2-55.2,27.6-61.2,40.5
			l-3,5.9c-4.9,11.8-8.9,24.7-9.9,39.5c-2,26.6,4.9,55.2,25.7,79.9c13.8,16.8,37.5,31.6,68.1,43.4c-26.6-16.8-50.3-49.3-51.3-99.6
			c30.6,12.8,65.1,20.7,91.8,25.7c56.2,10.9,111.5,11.8,159.8,8.9c11.8-1,24.7-2,35.5-3c11.8-2,22.7-3,33.5-3.9
			c21.7-3.9,40.5-6.9,59.2-10.9c8.9-2,17.8-3.9,25.7-6.9C1736.1,1876.4,1743,1874.4,1749.9,1871.4"
            />
          </g>
          <g>
            <path
              fill="#664F44"
              d="M1703.5,1212.4c29.6,1,49.3,9.9,63.1,23.7c3-12.8,4.9-28.6,3.9-46.4c-1-18.7-4.9-35.5-10.9-48.3
			c-2-7.9-13.8-28.6-28.6-27.6c-18.7,1-32.6,37.5-29.6,80.9C1701.6,1200.6,1702.5,1206.5,1703.5,1212.4 M1752.9,1323.9
			c0,7.9,3,14.8,6.9,17.8c6.9,3.9,15.8-3.9,19.7-16.8c0-1,1-2,1-3c3-11.8,0-23.7-6.9-26.6c-6.9-3.9-15.8,3.9-19.7,16.8
			C1753.9,1316,1752.9,1319.9,1752.9,1323.9 M1618.7,1345.6c3,4.9,7.9,9.9,13.8,13.8c12.8,7.9,26.6,8.9,34.5,2l3-3
			c3-5.9,2-13.8-3-21.7c-3-4.9-7.9-9.9-13.8-12.8c-12.8-7.9-26.6-8.9-33.5-3c-1,1-2,2-3,3.9
			C1613.8,1329.8,1614.7,1337.7,1618.7,1345.6 M2109,1526.1C2108,1526.1,2108,1526.1,2109,1526.1 M2093.2,1799.4
			c-18.7,17.8-39.5,34.5-63.1,50.3c-101.6,69.1-243.7,123.3-344.3,136.1c-76,11.8-150,13.8-214.1,5.9c-37.5-4.9-70-12.8-98.7-23.7
			c-30.6-11.8-54.3-26.6-68.1-43.4c-20.7-24.7-26.6-54.3-25.7-79.9c1-13.8,3.9-27.6,9.9-39.5l3-5.9c6.9-12.8,25.7-43.4,61.2-40.4
			c1,0,3.9,0,6.9,1c3,0,7.9,1,11.8,2c6.9,1,13.8,2,22.7,3h3c97.7,11.8,191.4,10.9,280.2-3c129.2-19.7,248.6-66.1,355.2-138.1
			c5.9-3.9,10.9-7.9,16.8-11.8l58.2-86.8c-176.6,160.8-371,207.2-518.9,216.1c-80.9,4.9-148-1-190.4-6.9c-2,0-3,0-3.9-1
			c-12.8-2-22.7-3-30.6-3.9c-3.9,0-6.9-1-8.9-1c-10.9-1-21.7,1-31.6,3.9c-19.7,5.9-37.5,19.7-50.3,37.5
			c-15.8,21.7-25.7,51.3-25.7,80.9c0,24.7,5.9,50.3,18.7,74c1,1,1,2,2,3c2,3,3,4.9,4.9,7.9c2,3,4.9,6.9,7.9,9.9
			c30.6,38.5,97.7,66.1,186.5,77.9c60.2,7.9,130.2,6.9,206.2-3.9c224.9-40.4,331.5-113.5,380.8-163.8c3.9-3.9,6.9-6.9,9.9-10.9
			c17.8-20.7,25.7-36.5,28.6-42.4v-1c1-2,1-3,1-3L2093.2,1799.4"
            />
          </g>
          <g>
            <path
              fill="#E9B784"
              d="M1378,1020c-6.9-3.9-14.8-6.9-22.7-8.9c-4.9-1-9.9-2-15.8-3c-2,0-3.9,0-5.9-1c-26.6-2-49.3,2-49.3,2
			c-4.9,1-9.9,2-13.8,3.9c-35.5,10.9-60.2,29.6-77.9,51.3c-13.8,17.8-23.7,36.5-28.6,55.2c-1,2-1,3-1,4.9c-1,4.9-3,9.9-3.9,13.8
			c-3,15.8-4.9,29.6-4.9,41.4c0,15.8,1,25.7,1,25.7c5.9-13.8,12.8-25.7,19.7-37.5c16.8-28.6,34.5-52.3,53.3-71
			c35.5-36.5,72-56.2,99.6-66.1C1357.2,1021,1378,1020,1378,1020 M1805.2,969.7l-1-2c-3.9-4.9-8.9-9.9-14.8-13.8
			c-13.8-8.9-32.6-13.8-58.2-8.9c0,0-30.6,6.9-48.3,27.6c0,0,53.3-22.7,110.5,17.8c4.9,3.9,10.9,7.9,15.8,12.8
			c4.9,4.9,10.9,9.9,15.8,16.8c0,0-2-24.7-16.8-46.4C1807.1,971.7,1806.1,970.7,1805.2,969.7"
            />
          </g>
          <path
            fill="#664F44"
            d="M1384.9,1362.4c20.7-5.9,42.4-11.8,67.1-17.8c3.9-18.7,4.9-41.4,2-66.1c-6.9-57.2-32.6-101.6-58.2-98.7
		c-25.7,3-40.5,52.3-33.5,109.5C1366.1,1319,1374,1344.6,1384.9,1362.4"
          />
          <path
            fill="#FFD250"
            d="M1267.5,72.9l-53.3,11.8l10.9,96.7l-141.1-72l-58.2,17.8l1,1l171.7,195.3l2-1l2-1c4.9-3,10.9-6.9,15.8-9.9
		c2-1,3.9-2,5.9-3.9c7.9-4.9,15.8-9.9,23.7-13.8l-73-83.9l117.4,57.2c3.9-2,6.9-3.9,10.9-5.9l-10.9-83.9l53.3,62.2h1
		c4.9-2,8.9-4.9,13.8-6.9l2-1l2-1c1,0,2-1,2-1l5.9-3c0,0,1,0,1-1c5.9-3,12.8-5.9,18.7-8.9L1267.5,72.9"
          />
          <g>
            <path
              fill="#4D9ED7"
              d="M1215.2,87.7v-3V87.7 M1215.2,84.7l7.9-2c-12.8-5.9-28.6-9.9-46.4-12.8c-35.5-4.9-80.9,0-132.2,12.8
			c-51.3,12.8-109.5,35.5-170.7,66.1C750.5,210,614.3,303.8,482.1,424.1c-46.4,41.4-88.8,84.8-128.3,127.3l159.8,197.3
			c43.4-47.4,89.8-94.7,141.1-141.1c68.1-62.2,137.1-117.4,205.2-164.8c66.1-46.4,132.2-85.8,194.4-117.4l-27.6-126.3l1-71l-1-1
			l58.2-17.8l113.5,58.2l-3-39.5L1215.2,84.7"
            />
          </g>
          <g>
            <path
              fill="#132134"
              d="M1215.2,84.7l-19.7,43.4l3,40.4l27.6,13.8L1215.2,84.7z M1174.7,210l118.4,58.2l-45.4,25.7L1174.7,210z
			 M1293.1,177.5l10.9,83.9l42.4-21.7L1293.1,177.5z"
            />
          </g>
          <path
            fill="#FFFFFF"
            d="M1739.1,146.9h21.7c19.7,0,38.5,3,56.2,6.9c1,0,2,0,3,1h1l2,1h2c-2-1-3.9-3-5.9-3.9
		c-19.7-7.9-40.5-12.8-62.2-15.8c-106.6-13.8-228.9,25.7-297.9,53.3c-3.9,2-7.9,3-11.8,4.9c-5.9,3-11.8,4.9-17.8,7.9
		c-9.9,3.9-19.7,8.9-30.6,13.8c-2,1-3,1-4.9,2c-5.9,3-12.8,5.9-18.7,8.9c0,0-1,0-1,1l-5.9,3c-1,0-2,1-2,1h-3.9l-1,1
		c-4.9,2-8.9,3.9-13.8,6.9h-1c-1,1-2,1-3,2h-1c-1,0-2,1-2,1c-6.9,3.9-13.8,6.9-20.7,10.9c-4.9,3-9.9,4.9-14.8,7.9h-1
		c-3.9,2-6.9,3.9-10.9,5.9c-11.8,5.9-22.7,12.8-34.5,19.7c-1,1-2,1-3.9,2c-1,1-2,1-3,2s-2,1-3,2l-1,1c-7.9,4.9-15.8,8.9-23.7,13.8
		c-2,1-3.9,2-5.9,3.9c-4.9,3-10.9,6.9-15.8,9.9l-2,1l-2,1l-44.4,29.6c-24.7,16.8-48.3,33.5-72,51.3c-3,2-5.9,4.9-8.9,6.9
		c-62.2,46.4-121.3,96.7-178.6,148C834.4,614.5,776.1,670.8,721.9,728c-29.6,29.6-55.2,56.2-75,77.9c-41.4,44.4-64.1,72-63.1,73
		c0,0,3.9-3,10.9-9.9c-4.9,5.9-10.9,12.8-15.8,18.7c90.8-96.7,190.4-195.3,297.9-288.1c65.1-56.2,133.2-110.5,203.2-160.8
		c30.6-21.7,62.2-43.4,93.7-64.1c9.9-6.9,20.7-12.8,30.6-19.7c4.9-3,9.9-5.9,15.8-8.9c3-2,5.9-3.9,8.9-4.9c5.9-3,10.9-6.9,16.8-9.9
		c4.9-3,10.9-5.9,15.8-9.9c3-2,5.9-3,8.9-4.9l20.7-11.8c3.9-2,7.9-4.9,11.8-6.9c12.8-6.9,25.7-13.8,39.5-20.7c1-1,2-1,3-2l5.9-3
		c3-1,4.9-3,7.9-3.9c1-1,2-1,3-2l5.9-3c0,0,1,0,1-1h1c5.9-3,11.8-5.9,16.8-7.9c1,0,2-1,3-2l5.9-3c6.9-3,12.8-5.9,19.7-8.9
		c2-1,3.9-2,4.9-3c1,0,2-1,3-1c2-1,3.9-2,5.9-2c7.9-3,14.8-6.9,22.7-9.9c6.9-3,12.8-5.9,19.7-8.9c37.5-15.8,72-28.6,104.6-39.5
		C1637.4,159.7,1691.7,148.9,1739.1,146.9"
          />
        </g>
      </svg>
    );
  }
}

export default Mailchimp;
