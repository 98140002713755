import React, { Component } from "react";

class Jquery extends Component {
  render() {
    return (
      <svg
        version="1.1"
        id="Layer_1"
        x="0px"
        y="0px"
        viewBox="0 0 2500 566"
        style={{ enableBackground: "new 0 0 2500 566" }}
        xmlSpace="preserve"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#1A1918"
          className="darkFill"
          d="M2390.4,42.2l104.5,0.6c0,0-194.3,263.3-210.2,284.8c-5.2,6.9-13,14.4-17,23.7c-10,23.4-20.3,74.6-20.3,74.6
	l-95.6,0.5c0,0,14.9-53.4,14.3-81.8c-0.5-24.8-13.2-49.7-20.3-74.6c-7.2-25.2-17-74.6-17-74.6h98.3l13.6,54.2L2390.4,42.2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#1A1918"
          className="darkFill"
          d="M2192.5,97.7c19.3,11.6,31.1,75.8,31.1,75.8s-100.9,1.5-155.8,1.5h-47.5l-57.6,250.9h-88.1
	c0,0,54.9-272.6,67.8-322.1c4.2-16,28.7-13.3,70.2-13.3h66.2C2121.5,90.5,2171.5,85.1,2192.5,97.7 M1782.1,219.9
	c0,0,2.7-16.1-9.3-34.8c-10.3-15.9-19.8-19.6-47-20.4c-76.6-2.4-75.1,54.3-75.1,54.3L1782.1,219.9z M1865.2,154.9
	c11.2,40.5-10.2,135.6-10.2,135.6s-111.7-1.9-172.9,0c-19,0.6-41.8-2.1-47.5,6.8c-8,12.4,2.9,31.5,12.1,39.6
	c10.6,9.3,31.3,10.3,38.7,11.3c49.7,6.3,153.6,1.6,153.6,1.6l-18,79.7c0,0-146.9,7.4-220.3-10.2c-14.5-3.5-28.1-10.4-37.3-20.3
	c-2.5-2.7-4.7-6.4-7.1-9.6c-1.9-2.5-7.4-10.9-9.9-17.5c-16.8-44.7,2-130.4,20.3-176.3c2.1-5.3,4.8-12.1,7.6-17.8
	c3.5-7.1,6.5-15.2,9.3-19.5c1.9-2.9,4.2-4.8,6.2-7.6c2.3-3.3,4.9-6.5,7.4-9.3c6.8-7.9,14.5-13.4,23.7-20.3
	c30.6-22.9,86-40.7,152.6-33.9c25.1,2.6,62.9,11.5,81.4,37.3C1860,131.3,1862.4,144.9,1865.2,154.9L1865.2,154.9z M1460.9,90.2
	l85.5-0.5c0,0-47.6,225.9-71.9,336.1h-98.3c-68.7,0-129.9,8.5-155.9-40.7c-25.3-47.8-1.2-126.3,10.2-179.7c8-37.8,24.9-115,24.9-115
	l88.5-0.8c0,0-25,120.2-35.4,180.2c-3.7,21.5-11.4,41.7-3.4,57.6c7.6,15.1,18.3,16.2,50.8,17c10.3,0.2,44.1,0,44.1,0L1460.9,90.2
	 M1060.9,252.9c8.9-31.8,22-66.3,20.3-94.9c-1.8-30.6-27.5-58.5-44.1-64.4c-49.9-17.6-96.7,7-115.3,27.1c-24,26-32.2,56.4-40.7,98.3
	c-6.3,31.1-11.5,63.9,0,88.1c20.4,42.9,84,33.5,152.6,33.9C1043.9,313.8,1052.4,283.2,1060.9,252.9L1060.9,252.9z M1179.6,127.5
	c9.8,71-27.7,149.3-53.5,217.5c26.3,2.4,49.4-0.4,51,1.6c1.7,2.5-17.6,81.3-24.4,81.5c-20.9,0.5-90.1,1.1-118.9,1.1
	c-94.1,0-182.2,6.1-227.1-40.7c-18.2-19-30.9-51.6-33.9-74.6c-5.1-38.3,1.3-80.5,10.2-115.3c8-31.4,18.3-61,33.9-88.1
	C856.4,41.5,925.2-9.7,1040.6,2.1c22.4,2.3,54.5,12.1,74.6,23.7c1.4,0.8,6.2,4.7,7.4,5.4C1149.6,48.4,1174.3,89.6,1179.6,127.5
	L1179.6,127.5z M763,90.6c-5.7,25.9-11.4,55.3-17.4,81h-94.9c7.4-28.4,13-56.6,20.3-81.4C701.4,90.2,729.7,90.6,763,90.6
	 M647.3,195.3h91.5c-12,66.2-35.7,173.1-54.2,247.5c-11.6,46.5-19.4,80.5-50.9,105.1c-2.8,2.2-4.4,1.2-6.8,3.4
	c-5.8,5.5-32.6,11.9-47.5,13.6c-24.1,2.6-51.4,0.3-81.1-0.3c7.8-28.4,16.5-62.4,23.5-87.9c72.3,8.8,74.1-59.6,88.1-125.4
	C618.1,313.2,642.7,210.6,647.3,195.3"
        />
        <g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#21609B"
            d="M433.7,368.2l-2,5.1c-33.4,65.1-92.7,105-177.1,106.8c-39.5,0.8-77.1-9.3-101.7-20.3
		c-53-23.8-97.7-68-122-115.3c-35-67.7-41.3-161.9,31.4-232.2c1.1,0-8.8,15.3-7.7,15.3l-2,6.5c-52.4,164,109,304.5,265.8,288.4
		C356.2,418.6,404.2,397.9,433.7,368.2"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#21609B"
            d="M338.8,300.4c39.5,0.3,80.7-18.2,105.1-44.1c-21.4,54.2-83.2,89.1-166.1,78c-69.4-9.3-135.6-79.2-142.4-149.2
		c-5-50.9,12.8-81.4,42-118.2c-10.2,19.3-14,34.2-14.9,43.7C151.4,220.8,250.6,299.8,338.8,300.4"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#21609B"
            d="M439.9,163.5c-6.1,14.1-41.5,40.6-57.1,44c-61.8,13.2-101.3-16.1-122-49.5c-3.1-5-8.8-18.6-9.8-22
		c-8-27.3-4.3-68.7,19.6-90c-7.2,20.3-8.3,44-4.5,59.3c2.3,9.3,9.3,25.5,16.3,35.7c12.8,18.6,20.9,23.1,37.3,33
		c7.4,4.4,15.3,8,18.7,9C354.1,187.5,387,199.6,439.9,163.5"
          />
        </g>
      </svg>
    );
  }
}

export default Jquery;
