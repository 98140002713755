import React, { Component } from "react";
import { Helmet } from "react-helmet";
import TrackVisibility from "react-on-screen";
import ScrollMagic from "scrollmagic";
// import ReactGA from "react-ga";
import TagManager from "react-gtm-module";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Map from "./common/map";
import FormNewProject from "./common/formNewProject";
import FormJoinTeam from "./common/formJoinTeam";
import FormEnquiry from "./common/formEnquiry";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
// import "scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap";

class Contact extends Component {
  constructor(props) {
    super(props);
    this.controller = new ScrollMagic.Controller();
  }

  state = {
    label: ""
  };

  componentDidMount() {
    // ReactGA.pageview("/contact");
    const tagManagerArgs = {
      dataLayer: {
        event: "pageviewCustomEvent",
        pagePath: "/contact",
        pageTitle: "Contact"
      },
      dataLayerName: "PageDataLayer"
    };
    TagManager.dataLayer(tagManagerArgs);

    var self = this;
    new ScrollMagic.Scene({
      triggerElement: ".contact-form",
      duration: 600 // scroll distance
    })
      // .on("enter", this.setState({ label: "Contact Us" }))
      .on("enter", function(e) {
        self.updateLabel("Contact Us");
      })
      .addTo(this.controller);
    new ScrollMagic.Scene({
      triggerElement: ".address",
      duration: 700 // scroll distance
    })
      // .on("enter", this.setState({ label: "Location" }))
      .on("enter", function(e) {
        self.updateLabel("Location");
      })
      .addTo(this.controller);
  }
  updateLabel = label => {
    this.setState({ label });
  };

  componentWillUnmount() {
    this.controller.destroy();
  }

  render() {
    return (
      <div id="page-contact">
        <Helmet>
          <title>Contact - Simplicitic Innovations Sdn Bhd</title>
          <meta
            name="description"
            content="We are located at UOA Business Park, Shah Alam (Next to Subang Jaya LRT Station)"
          />
        </Helmet>
        <div className="label-container">
          <div className="label">{this.state.label}</div>
        </div>
        <section className="contact-form wrapper">
          <TrackVisibility once>
            {({ isVisible }) => (
              <div className={isVisible ? "in-view" : ""}>
                <h1 className="text-center animate-item">
                  How can we help you?
                </h1>
              </div>
            )}
          </TrackVisibility>
          <TrackVisibility once partialVisibility>
            {({ isVisible }) => (
              <Tabs>
                <TabList className={`nav-tabs ${isVisible ? "in-view" : ""}`}>
                  <Tab className="nav-item animate-item">
                    <span>New Project</span>
                  </Tab>
                  <Tab className="nav-item animate-item">
                    <span>Join the Team</span>
                  </Tab>
                  <Tab className="nav-item animate-item">
                    <span>Something Else</span>
                  </Tab>
                </TabList>
                <TabPanel className="tab-pane">
                  <FormNewProject />
                </TabPanel>
                <TabPanel className="tab-pane">
                  <FormJoinTeam />
                </TabPanel>
                <TabPanel className="tab-pane">
                  <FormEnquiry />
                </TabPanel>
              </Tabs>
            )}
          </TrackVisibility>
        </section>
        <TrackVisibility once>
          {({ isVisible }) => (
            <section
              className={`address wrapper text-center ${
                isVisible ? "in-view" : ""
              }`}
            >
              <h3 className="animate-item">Address</h3>
              <address className="animate-item">
                Unit 2-1, Level 2, The Podium, Tower 3, UOA Business Park,
                <br /> No. 1, Jalan Pengaturcara U1/51A, Seksyen U1,
                <br /> 40150 Shah Alam, Selangor Darul Ehsan.
                <br />
                <span>
                  <FontAwesomeIcon icon={["fas", "subway"]} /> Next to Subang
                  Jaya LRT/KTM Station
                </span>
              </address>
            </section>
          )}
        </TrackVisibility>
        <TrackVisibility once partialVisibility className="wrapper">
          {({ isVisible }) => (
            <div className={isVisible ? "in-view" : ""}>
              <Map />
            </div>
          )}
        </TrackVisibility>
      </div>
    );
  }
}

export default Contact;
