import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import Logo from "../../images/logo";

class Header extends Component {
  state = {};
  render() {
    return (
      <header className="header-container">
        <div className="wrapper">
          <div className="logo">
            <Logo />
          </div>
          <nav className="nav-container">
            <ul>
              <li>
                <NavLink exact to="/" activeClassName="active">
                  Work
                </NavLink>
              </li>
              <li>
                <NavLink to="/about" activeClassName="active">
                  About
                </NavLink>
              </li>
              <li>
                <NavLink to="/careers" activeClassName="active">
                  Careers
                </NavLink>
              </li>
              <li>
                <NavLink to="/contact" activeClassName="active">
                  Contact
                </NavLink>
              </li>
            </ul>
          </nav>
        </div>
      </header>
    );
  }
}

export default Header;
