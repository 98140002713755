import React, { Component } from "react";
import Input from "./input";
import Textarea from "./textarea";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class Form extends Component {
  state = {
    data: {},
    errors: {},
    focus: {},
    sending: false,
    mailSent: false,
    mailError: false
  };

  async validate() {
    const options = { abortEarly: false };
    try {
      await this.schema.validate(this.state.data, options);
      return null;
      // this.doSubmit();
    } catch (err) {
      const errors = {};
      // console.log(err.message);
      for (let item of err.inner) errors[item.path] = item.message;
      this.setState({ errors: errors || {} });
      return errors;
      // errors[err.path] = err.message;
    }
  }

  handleSubmit = async e => {
    e.preventDefault();
    const result = await this.validate();
    // console.log("handleSubmit with error", result);
    if (!result) {
      this.setState({ mailSent: false, mailError: false });
      this.doSubmit();
    }
  };

  handleChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    // const errorMessage = this.validateProperty(input);
    // if (errorMessage) errors[input.name] = errorMessage;
    // else delete errors[input.name];

    const data = { ...this.state.data };
    data[input.name] = input.value;
    if (input.value) delete errors[input.name];

    this.setState({ data, errors });
  };

  handleFileChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    // const errorMessage = this.validateProperty(input);
    // if (errorMessage) errors[input.name] = errorMessage;
    // else delete errors[input.name];

    const data = { ...this.state.data };
    data[input.name] = input.files[0];
    if (input.files[0]) delete errors[input.name];

    this.setState({ data, errors });
  };

  handleFocus = ({ currentTarget: input }) => {
    const focus = { ...this.state.focus };
    // console.log(input.name);
    focus[input.name] = true;
    this.setState({ focus });
  };

  handleBlur = ({ currentTarget: input }) => {
    const focus = { ...this.state.focus };
    if (!input.value) delete focus[input.name];
    this.setState({ focus });
    // console.log(input.name);
    // focus[input.name] = true;
    // this.setState({ focus });
  };

  // Submit Button
  renderSubmitButton(label) {
    return (
      <div className="text-center">
        <button
          //disabled={this.validate()}
          disabled={this.state.sending}
          type="submit"
          className="btn btn-primary animate-item"
        >
          {label}
        </button>
      </div>
    );
  }

  // Input Text
  renderInput(name, label, type = "text", disabled) {
    const { data, errors, focus } = this.state;
    if (type === "file") {
      return (
        <Input
          type={type}
          name={name}
          label={label}
          disabled={disabled}
          onChange={this.handleFileChange}
          error={errors[name]}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          focus={focus[name]}
        />
      );
    }
    return (
      <Input
        type={type}
        name={name}
        value={data[name]}
        label={label}
        disabled={disabled}
        onChange={this.handleChange}
        error={errors[name]}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
        focus={focus[name]}
      />
    );
  }

  // Textarea
  renderTextarea(name, label) {
    const { data, errors, focus } = this.state;
    return (
      <Textarea
        name={name}
        value={data[name]}
        label={label}
        onChange={this.handleChange}
        error={errors[name]}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
        focus={focus[name]}
      />
    );
  }

  // Success Message
  renderMsgSuccess() {
    return (
      <div className="message message-success">
        <span className="icon">
          <FontAwesomeIcon icon={["far", "check-circle"]} size="lg" />
        </span>
        Thank you for your email. We will contact you shortly.
      </div>
    );
  }

  // Failed Message
  renderMsgFailed() {
    return (
      <div className="message message-error">
        <span className="icon">
          <FontAwesomeIcon icon={["far", "times-circle"]} size="lg" />
        </span>
        Failed to send out email. Please try again later.
      </div>
    );
  }
}

export default Form;
