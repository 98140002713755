import React, { Component } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
// import ReactGA from "react-ga";
// import TagManager from "react-gtm-module";
// import { finalConfig } from "../config";
import ScrollIntoView from "./common/scrollIntoView";
import Work from "./work";
import About from "./about";
import Contact from "./contact";
import Career from "./career";
import Header from "./common/header";
import Footer from "./common/footer";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faTimesCircle as farTimesCircle,
  faCheckCircle as farCheckCircle
} from "@fortawesome/free-regular-svg-icons";
import { faSubway } from "@fortawesome/free-solid-svg-icons";
import SimpliciticLogo from "./common/simpliciticLogo";
import { workingHours } from "./common/workingHours";

library.add(farTimesCircle, farCheckCircle, faSubway);

class App extends Component {
  state = {
    scrollY: 0,
    isScrollDown: false,
    loaded: false,
    documentHeight: 0,
    windowHeight: 0
  };
  componentDidMount() {
    // ReactGA.initialize(finalConfig.gaKey);
    this.scrollY = window.scrollY;
    window.addEventListener("scroll", this.handleScroll, { passive: true });
    setTimeout(() => {
      this.setState({ loaded: true });
    }, 3000);
    this.setState({
      documentHeight: document.documentElement.offsetHeight,
      windowHeight: window.innerHeight
    });
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  componentDidUpdate(prevProps, prevState) {
    const documentHeight = window.document.body.offsetHeight;
    if (this.state.documentHeight !== documentHeight) {
      this.setState({ documentHeight });
    }
  }

  handleScroll = event => {
    // let scrollY;
    const { isScrollDown, documentHeight, windowHeight } = this.state;
    if (this.scrollY < window.scrollY) {
      if (window.scrollY + 10 >= documentHeight - windowHeight) {
        isScrollDown && this.setState({ isScrollDown: false });
      } else {
        !isScrollDown && this.setState({ isScrollDown: true });
      }
    } else {
      isScrollDown && this.setState({ isScrollDown: false });
    }
    this.scrollY = window.scrollY;
  };
  checkWorkingHour() {
    if (workingHours()) return "";
    return "dark-theme";
    // const WORKING_HOUR = {
    //   start: Moment("16:30:00", "hh:mm:ss"),
    //   end: Moment("18:30:00", "hh:mm:ss")
    // };
    // if (Moment().day() < 1 || Moment().day() > 5) return "dark-theme";
    // if (!Moment().isBetween(WORKING_HOUR.start, WORKING_HOUR.end))
    //   return "dark-theme";
    // return "";
  }
  render() {
    const { isScrollDown, loaded } = this.state;
    // console.log(window.document.body.offsetHeight);
    return (
      <div className={`${loaded ? "loaded" : ""}`}>
        <div className={`${this.checkWorkingHour()}`}>
          <div
            ref={contentElement => (this.contentElement = contentElement)}
            className={`${isScrollDown ? "scroll-down" : ""}`}
          >
            <BrowserRouter>
              <ScrollIntoView>
                <Header />
                <div className="main-container">
                  <Switch>
                    <Route path="/" exact component={Work} />
                    <Route path="/about" exact component={About} />
                    <Route path="/careers" exact component={Career} />
                    <Route path="/contact" exact component={Contact} />
                    <Redirect to="/" />
                  </Switch>
                </div>
                <Footer />
                {/* <CTA /> */}
              </ScrollIntoView>
            </BrowserRouter>
            <SimpliciticLogo style={{ width: "150px" }} />
            <div className="mask" />
          </div>
        </div>
      </div>
    );
  }
}

export default App;
