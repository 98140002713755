import React, { Component } from "react";
import VSI from "../../images/portfolio/vsi.jpg";

class PortfolioVSI extends Component {
  state = {};
  render() {
    return (
      <div className="portfolio-img animate-item">
        <div className="portfolio-thumb" />
        <div className="portfolio-full">
          <img src={VSI} alt="V.S. I" />
        </div>
      </div>
    );
  }
}

export default PortfolioVSI;
