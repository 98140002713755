import React, { Component } from "react";

class Member4 extends Component {
  state = {};
  render() {
    return (
      <svg
        x="0px"
        y="0px"
        viewBox="0 0 80 80"
        style={{ enableBackground: "new 0 0 80 80" }}
        xmlSpace="preserve"
      >
        <g className="member">
          <path d="M28.3,38.7C28.3,38.7,28.3,38.7,28.3,38.7C28.3,38.7,28.3,38.7,28.3,38.7z" />
          <path d="M28.4,39.1c0,0,0-0.1,0-0.1C28.3,39,28.4,39.1,28.4,39.1z" />
          <path d="M36.6,41.7c1.5,1.5,5.8-0.6,4.4-1.2c-0.1,0-4,0.2-4.2,0.3c-0.5,0.1-0.6,0.3-0.3,0.7C36.5,41.6,36.5,41.7,36.6,41.7z" />
          <path d="M31.8,30.1c0.3-0.3,0.5-0.6,0.8-0.9c0.9-0.3-3.3,0.6-2,1.9C31.1,31.1,31.5,30.8,31.8,30.1z" />
          <path d="M31.5,33.1c0.4,0.2,2.5-3.2,2.9-3.6c0.4,0.2,0.6-1-0.3-0.6C33.5,29.1,31.1,32.9,31.5,33.1z" />
          <path
            d="M34.7,31.7C34.7,31.7,34.7,31.8,34.7,31.7C34.7,31.8,34.7,31.8,34.7,31.7c-0.1,0.2-0.9,0.9-0.8,1
           C34.3,33.1,35,31.3,34.7,31.7z"
          />
          <path d="M44.1,28.9C44.1,28.9,44.1,28.8,44.1,28.9c0.5-0.5-0.6-0.4-1.1,0.3C41.3,31.5,43.2,30.2,44.1,28.9z" />
          <path d="M44.1,30.7C44.1,30.7,44.1,30.7,44.1,30.7C44.1,30.7,44.1,30.7,44.1,30.7z" />
          <path d="M44,30.8C44,30.8,44,30.8,44,30.8C44,30.8,44,30.8,44,30.8z" />
          <polygon points="44,32.2 44,32.2 44,32.2 44,32.2 	" />
          <path d="M44.4,30.3C44.4,30.3,44.4,30.3,44.4,30.3C44.4,30.3,44.4,30.3,44.4,30.3z" />
          <path
            d="M43.3,32.8c0.3-0.1,0.5-0.3,0.7-0.6c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0.1-0.1c0,0,0,0,0,0
           c0.1-0.1,0.2-0.2,0.3-0.4c0,0,0,0.1-0.1,0.1c0.1-0.1,0.2-0.2,0.3-0.4c0,0,0,0,0,0c0.7-0.9,1.4-1.8,2-2.8c0.4-0.4-0.3-0.4-0.4-0.4
           c-0.4,0-1.5,1.7-1.6,1.8c0,0,0,0,0,0c0,0,0,0.1-0.1,0.1c0,0,0,0,0,0c-0.1,0.1-0.2,0.2-0.3,0.3c0,0,0,0,0,0c0,0,0,0.1-0.1,0.1
           c0,0,0,0,0,0c0,0,0,0.1-0.1,0.1c0,0,0,0,0-0.1c0,0,0,0.1-0.1,0.1c0,0,0,0,0,0c-0.3,0.4-0.7,0.9-1,1.3C42.8,32.4,43,32.8,43.3,32.8z
            M44,32.2C44,32.2,44,32.2,44,32.2C44,32.2,44,32.2,44,32.2z M44,30.9C44,30.8,44,30.8,44,30.9C44,30.8,44,30.8,44,30.9
           C44,30.9,44,30.9,44,30.9C44,30.9,44,30.9,44,30.9C44,30.9,44,30.9,44,30.9z"
          />
          <polygon points="44,32.2 44,32.2 44,32.2 	" />
          <path d="M45.5,31.8c-1.5,1.7,3.6-1.9,1.6-1.6C46.6,30.3,45.7,31.5,45.5,31.8z" />
          <path d="M17.9,72.4c-0.3-1.1-0.7-4.4-2.1-4.5c-1.9-0.2,0,3.9,0.2,4.6c0.3,1,0.5,4.5,1.8,4.6C19.7,77.1,18.1,73.2,17.9,72.4z" />
          <path
            d="M65.8,67.7c-0.3-0.2-0.6-0.1-0.8,0c-1.6-0.4-2.7,5.7-2.7,7c0,1.5,0.4,2.8,1.8,1.2c0.5-0.6,0.7-3.2,0.9-4.1
           c0.4-1.1,0.8-2.1,1.3-3.2C66.3,68.3,66.1,68,65.8,67.7z"
          />
          <path
            d="M71.3,75.9c-0.7-3.1-0.3-6.3-1.7-9.3c0-0.1,0-0.2,0-0.3c-3.1-5.1-10.4-5.1-15.1-8.4c0-0.2,0-0.3-0.1-0.5
           c-0.6-1.6-3.7-6.7-5.9-6.4c-0.1-2-0.1-4.1,0.2-6.2c0.1-1,1.1-3.8,0.7-4.7c0.2-0.6,0.2-1.3,0.3-1.9c1.8-1.3,6.2-8.1,3.8-10
           c0.1-0.3,0.3-0.6,0.4-0.9c1.7-0.4,1-3.3,0.9-4.5c2-0.6,0.1-4.8-0.3-5.8c3.9-0.5-1.6-4.7-2.9-6c-2.7-3.1-5.6-6.6-9.9-7.3
           C39,3.2,35.1,4,33.9,6.6c-0.2,0.3-0.5,0.6-0.6,1c-0.3-0.2-1.5,0-1.2,0c-1.5-0.2-3.3,0.3-4.4,1.3c-2.4,1.2-4,4.3-5,6.6
           c-0.2,0.4-2.1,6-0.6,5c0.1,0.1,0.3,0.1,0.4,0c-0.1,1.1-0.8,3.4,0.7,4c0.1,1.4-0.1,2.6,0.9,3.7c-2.5,0.7-1.1,3.9,0.1,5
           c0.8,1.2,1.9,2.3,2.8,3.4c0.1,0.1,0.6,1.1,1.1,1c0,0,0.1,0.1,0.1,0.1c0.3,2.2,0.7,4,2,5.9c1.4,2.6,1.3,5.7,0.4,8.4
           c-0.4-0.2-0.7-0.3-1.1-0.3c-1.6,0.2-4.6,3.9-4.8,5.2c-4.4,2.7-10.9,3.5-13.6,8.3c-1.2,2.2-2,4.7-2.3,7.1c0,0.2-0.6,5.8,1.3,3.9
           c0-0.1,0.1-0.1,0.1-0.2c0.1-0.1,0.1-0.3,0.1-0.4c0-0.3,0-0.5,0.1-0.8c0.4-1.8,0.6-3.7,1.2-5.5c0.4-1.4,2.1-3.3,2.2-4.6
           c3-3.1,8-4.2,11.6-6.4c2.4,2,5,3.6,7.2,5.8c0.9,0.9,2.5,3.6,3.8,3.2c0.7-0.2,2-4.4,2.5-5.2c0.3,0.4,0.6,0.8,0.8,1.3
           c0,0.2,0.1,0.4,0.2,0.5c0,0,0,0,0,0c-0.7,0.9-0.1,5-0.1,6.1c-0.4,1.3,1.7,1.4,1.4,0.3c0,0,0,0,0,0c0,0,0-0.1,0-0.1
           c-0.1-1.5,0.7-5-0.2-6.2c0.6-0.7,0.7-2.1,1-2.9c0.2,1.4,2.4,5.4,3.5,6.1c2.1,1.5,2.6-2.1,3.8-3.6c1.7-1.5,3-3.4,4.7-4.8
           c3.8,2.8,8.8,3.1,12.7,5.7c0.4,0.4,0.7,0.9,1,1.4c0.8,2.2,1.5,4.5,1.8,6.9c0.1,0.9-0.3,6,1.4,5c0,0,0,0,0,0
           c0.3-0.2,0.4-0.5,0.4-0.8C71.4,76.8,71.4,76.3,71.3,75.9z M48.8,52.3c0.1,0,0.2,0,0.3,0.1c0.1,0,0.2,0.1,0.2,0.1
           c1.5,1.3,2.2,3.6,3.7,5c-2.5,2.1-5.5,5-6.8,8c-0.7-1.5-2.2-3.2-2.5-4.8c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0,0,0,0
           c1.6-1.7,4-2.3,5.1-4.5c1-1.2,1.4-2.6-0.1-3.6c0-0.1,0-0.1,0-0.2c0,0,0,0,0,0C48.7,52.3,48.8,52.3,48.8,52.3z M42.5,33.5
           c-1.6-1.3-1.8-4.2-0.1-5.6c6-4,8.7,5,3,6.3c0,0-0.1,0-0.1,0c-0.4-0.2-0.9-0.2-1.4-0.1C43.4,34,42.9,33.8,42.5,33.5z M40.6,27.9
           c-0.3,0.6-0.5,1.2-0.6,1.8c-0.7,0-1.5,0.1-2.2,0.2c-0.2,0-0.4,0-0.5,0c-0.7-4.1-6-5.1-8.5-1.9c-0.1-1.5-0.1-3.1-0.1-4.6
           c1.4,0.4,2.5-5.2,3.4-6.3c0,0,0,0-0.1,0.1c0.2-0.2,0.3-0.4,0.5-0.6c0.3,1.3,8.8,8,9.8,5.7c1,0.9,2.2,1.5,3.4,2.1
           c0.3,0.3,1.2,1.4,1.8,1.2c0.2,0.3,0.4,0.6,0.5,0.9C45.8,25.1,41.9,25.7,40.6,27.9z M30.2,33.8c-3-2.5-0.5-6.4,2.8-6.4
           c3.1,0.1,4.8,3.4,2.9,6.1c0,0,0,0,0,0C33.8,33.7,32.6,35.5,30.2,33.8z M25.2,32.6c-0.2-0.1-0.3-0.3-0.5-0.4
           c-0.3-0.7-0.6-1.7-0.1-2.4c0,0,0,0,0,0c0.2-0.1,0.4-0.2,0.6-0.3c0.8,0.7,1.3,1.6,1.8,2.5c0,0.4,0.2,0.7,0.5,0.9c0.1,1,0.2,2,0.3,3
           C27.3,34.6,26.3,33.5,25.2,32.6z M51.2,32.9c0.3-1.2,1-2.3,1.6-3.4c0.1,0.1,0.2,0.1,0.2,0.2c0,0.1,0.1,0.1,0.1,0.2
           c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0,0.2,0,0.2c-0.4,1.4-1.7,2.4-2.5,3.5C51,33.7,51.1,33.3,51.2,32.9z M50.4,34.5
           c0.1-0.1,0.2-0.1,0.3-0.2c-0.1,0.2-0.2,0.3-0.3,0.5C50.4,34.6,50.4,34.5,50.4,34.5z M24.1,21c0.1-0.9,0.3-1.8-0.5-2.4
           c-0.1,0-0.2-0.1-0.2-0.1c0.9-3.5,3.3-9,7.4-9.8c0.6,0,1.2,0,1.8,0.2c0,0,0,0,0,0c0.2,0.2,0.9,0.3,1,0C34,9.2,34.3,9,34.4,8.7
           c0.6-0.2,1-1.6,1.3-2c0-0.1,0.1-0.1,0.1-0.2c7-4.7,14.2,3.9,18,8.8c-0.4,0-0.9,0.1-1.2,0.5c-0.5,0.2-0.3,0.7-0.2,1.1
           c0.2,1.3,0.9,2.7,1.2,4c-1.1,0.4-1,1.7-0.7,2.6c0.1,0.6,0.2,1.2,0.2,1.7c-1.3,0.2-1.8,2.4-2.2,3.4c-0.6-2.1-1.7-4.4-3-6.2
           c-0.1-0.1-0.2-0.1-0.3-0.2c-0.4-0.2-1-0.1-1.2,0.2c-0.8-0.5-5.7-5.3-6.1-3.7c-0.4,0.1-0.4,0.6-0.3,0.9c-2.1-0.8-4.7-1.7-5.8-3.8
           c-0.2-0.7,0.1-1.6-0.2-2.2c0.2-0.5-0.1-0.9-0.6-1.1c-0.7-0.2-1.4,2-1.6,2.2c-1.4,2-2.9,3.3-3.4,5.8c-0.2,0-0.3,0-0.5,0.1
           c-0.2-0.1-0.6,0-0.7,0.2c-0.5,1.3-0.5,3.1-0.2,4.5c0,0.7,0,1.4,0,2c-0.2,0.1-0.3,0.3-0.3,0.5c-0.1,0-0.1-0.1-0.2-0.1
           c-0.3-0.5-0.7-0.9-1.2-1.1c0,0,0,0,0,0C25.1,26.3,25,26,25,25.7c-0.1-0.6,0-1.6-0.3-2.2c0-0.5-0.2-0.9-0.8-1
           C23.9,22,24,21.5,24.1,21z M47.7,55.2L47.7,55.2C47.7,55.2,47.7,55.2,47.7,55.2C47.7,55.2,47.7,55.2,47.7,55.2z M29.6,39.1
           c-0.5-1.9-0.8-4-1.1-5.9c1.4,2.6,5.3,3.6,7.6,1.5c0.3,0,0.5-0.1,0.7-0.3c-0.2,1.3-0.9,3.3,0.2,4.3c0.2,0.2,1.5,0.8,1.9,0.6
           c1.1-0.7-0.9-1.2-1.3-1.4c-0.2-2.2,0.4-4.3,0.3-6.5c0.7,0.1,1.5,0.1,2.2-0.1c0.2,2.1,1.8,4,4.1,4c1.1,2.1,4.4-1.4,4.8-2.2
           c0,0.3,0,0.6,0,0.9c-0.1,1.7-0.2,3.5-0.6,5.1c0,0.1-0.1,0.2-0.1,0.3c-0.8,2.9-3.9,7.4-6.6,8.9c-0.1,0-0.2,0-0.3,0.1
           c-0.1,0-0.2,0.1-0.2,0.1c-0.8-0.7-3.4,0.1-4.5-0.3C33.6,46.2,30.4,42.5,29.6,39.1z M37.5,37.7C37.5,37.7,37.5,37.7,37.5,37.7
           L37.5,37.7C37.5,37.7,37.5,37.7,37.5,37.7z M31.4,54.4C31.4,54.4,31.4,54.4,31.4,54.4C31.4,54.4,31.4,54.4,31.4,54.4
           C31.4,54.4,31.4,54.4,31.4,54.4z M29.5,53C29.5,53,29.5,53,29.5,53c0.2,0,0.4,0.1,0.5,0.2c0,0,0,0.1,0,0.1
           c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1-0.1-0.3-0.2-0.4-0.3C29.3,53.1,29.4,53.1,29.5,53z M37.8,60.9c-0.9,1.4-1.7,2.8-2.2,4.3
           c-2.8-3.1-6.4-5.3-9.5-8c0-0.2-0.1-0.3-0.2-0.4c0,0,0,0,0,0c0.5-1.1,1.6-1.9,2.4-2.7C29.5,56.4,35.5,60,37.8,60.9
           C37.9,60.9,37.9,60.9,37.8,60.9z M39.7,63.5c0,0,0-0.1,0-0.1c0,0,0,0,0,0C39.7,63.4,39.7,63.4,39.7,63.5z M41,59.7
           c-0.5,0.6-0.7,1.5-0.9,2.2c-0.2-0.3-0.4-0.5-0.5-0.8c0-0.1,0-0.1,0-0.2c0.4-0.7-6.9-5.5-8-6.4c0.7-2.9,1.1-5.8,0.5-8.7
           c1.7,1.9,6.2,5.7,9.1,3.6c1.5,1.3,5.8-5.2,6.4-6.2c-0.7,3.4-1.9,8.8,0.1,12c-1,1.9-3.7,2.6-5.3,4c-0.3,0-0.6,0-0.9,0.1
           C41.3,59.4,41.1,59.5,41,59.7z"
          />
        </g>
      </svg>
    );
  }
}

export default Member4;
