import React, { Component } from "react";
import TechbondThumb from "../../images/portfolio/techbond-thumb.jpg";
import TechbondFull from "../../images/portfolio/techbond.jpg";

class PortfolioTechbond extends Component {
  state = {};
  render() {
    return (
      <div className="portfolio-img animate-item">
        <div className="portfolio-thumb">
          <img src={TechbondThumb} alt="Techbond" />
        </div>
        <div className="portfolio-full">
          <img src={TechbondFull} alt="Techbond" />
        </div>
      </div>
    );
  }
}

export default PortfolioTechbond;
