import React from "react";

const Input = ({ name, label, focus, error, ...rest }) => {
  return (
    <div
      className={`form-group animate-item ${
        focus ? "focus" : ""
      } input-${name}`}
    >
      <label htmlFor={name}>{label}</label>
      <input
        {...rest}
        name={name}
        id={name}
        className={`form-control ${error ? "error" : ""}`}
      />
      {error && <div className="msg error-msg">{error}</div>}
    </div>
  );
};

export default Input;
