import React from "react";

const Textarea = ({ name, label, focus, error, value, ...rest }) => {
  return (
    <div className={`form-group animate-item ${focus ? "focus" : ""}`}>
      <label htmlFor={name}>{label}</label>
      <textarea
        {...rest}
        name={name}
        id={name}
        className="form-control"
        rows="4"
        value={value}
      />
      {error && <div className="alert alert-danger">{error}</div>}
    </div>
  );
};

export default Textarea;
