import React, { Component } from "react";
import GoogleMapReact from "google-map-react";
import MapMarker from "../../images/map-marker.png";
import { workingHours } from "./workingHours";

class Map extends Component {
  state = {
    darkTheme: false
  };
  static defaultProps = {
    center: {
      lat: 3.085935,
      lng: 101.586582
    },
    zoom: 15
  };
  componentDidMount() {
    if (!workingHours()) {
      this.setState({ darkTheme: true });
    }
  }

  render() {
    const { darkTheme } = this.state;
    const styles = [
      {
        featureType: "all",
        elementType: "labels.text.fill",
        stylers: [
          { saturation: 80 },
          { color: darkTheme ? "#ffffff" : "#000000" },
          { lightness: darkTheme ? 70 : 0 }
        ]
      },
      {
        featureType: "all",
        elementType: "labels.text.stroke",
        stylers: [{ visibility: "off" }]
      },
      {
        featureType: "administrative",
        elementType: "all",
        stylers: [{ saturation: darkTheme ? "100" : "-100" }]
      },
      {
        featureType: "administrative.province",
        elementType: "all",
        stylers: [{ visibility: "off" }]
      },
      {
        featureType: "landscape",
        elementType: "all",
        stylers: [
          { saturation: -100 },
          { lightness: darkTheme ? -70 : 65 },
          { visibility: "on" }
        ]
      },
      {
        featureType: "poi",
        elementType: "all",
        stylers: [
          { saturation: -100 },
          { lightness: darkTheme ? "-50" : "50" },
          { visibility: "simplified" }
        ]
      },
      {
        featureType: "poi",
        elementType: "labels.text.fill",
        stylers: [{ color: darkTheme ? "#aaaaaa" : "#555555" }]
      },
      {
        featureType: "road",
        elementType: "all",
        stylers: [
          {
            saturation: darkTheme ? "-200" : "-100",
            lightness: darkTheme ? -100 : 100
          }
        ]
      },
      {
        featureType: "road.highway",
        elementType: "all",
        stylers: [{ visibility: "simplified" }]
      },
      {
        featureType: "road.highway",
        elementType: "geometry.fill",
        stylers: [{ lightness: darkTheme ? -80 : 0 }]
      },
      {
        featureType: "road.arterial",
        elementType: "all",
        stylers: [{ lightness: darkTheme ? "-50" : "30" }]
      },
      {
        featureType: "road.local",
        elementType: "all",
        stylers: [{ lightness: darkTheme ? "-80" : "40" }]
      },
      {
        featureType: "transit",
        elementType: "all",
        stylers: [{ saturation: -100 }, { visibility: "simplified" }]
      },
      {
        featureType: "water",
        elementType: "geometry",
        stylers: [
          { hue: "#ffff00" },
          { lightness: darkTheme ? -45 : -25 },
          { saturation: -100 }
        ]
      },
      {
        featureType: "water",
        elementType: "labels",
        stylers: [{ lightness: darkTheme ? 55 : -25 }, { saturation: -100 }]
      }
    ];
    const GoogleMapMarker = () => (
      <div>
        <img src={MapMarker} alt="Map" />
      </div>
    );
    return (
      // Important! Always set the container height explicitly
      <div style={{ height: "500px", width: "100%" }} className="animate-item">
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyB7y6slJXnTC_1kWhmJTkW0TK-Q3wbtmME" }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
          options={{ styles }}
        >
          <GoogleMapMarker lat={3.088935} lng={101.586582} text="Simplicitic" />
        </GoogleMapReact>
      </div>
    );
  }
}

export default Map;
