import React, { Component } from "react";

class Member3 extends Component {
  state = {};
  render() {
    return (
      <svg
        x="0px"
        y="0px"
        viewBox="0 0 80 80"
        style={{ enableBackground: "new 0 0 80 80" }}
        xmlSpace="preserve"
      >
        <g className="member">
          <path d="M47.6,53.9C47.6,53.9,47.6,53.9,47.6,53.9C47.6,53.9,47.6,53.9,47.6,53.9z" />
          <path d="M47.6,53.9C47.6,53.9,47.6,53.9,47.6,53.9C47.6,53.9,47.6,53.9,47.6,53.9z" />
          <path d="M47.5,54C47.5,53.9,47.5,53.9,47.5,54C47.5,53.9,47.5,53.9,47.5,54z" />
          <path
            d="M38.6,34.4c0.3,0.2,0.6,0.2,0.8,0.4c-0.1,0-0.1,0-0.2-0.1c1.7,0.7,3-1,1.2-1.2c-0.3-0.1-0.9-0.2-1.2-0.3c0,0,0,0-0.1-0.1
           c0,0-0.1-0.1-0.1-0.1c0,0,0,0,0-0.1c0-0.4,1.5-8.2-0.2-6.7C38,27,37.3,33.6,38.6,34.4z"
          />
          <path
            d="M43.2,37.8c-0.9-0.5-2.3-0.5-3.3-0.3c-0.1,0-0.2,0-0.3,0c-0.8-0.1-1.9-0.4-2.6,0.3c-1.1,1.1,0.8,1,1.6,0.6
           c0.8,0.5,1.4,0.2,2.1,0c0,0,0,0,0,0C41.2,38.4,44.8,38.5,43.2,37.8z"
          />
          <path d="M41.7,39.4c0,0-0.1,0-0.1,0c0,0,0,0,0,0c-0.5,0-1.8-0.1-2.2,0.3c-0.8-0.1-0.8,0.8,0,0.9C40.7,41.5,42.8,39.7,41.7,39.4z" />
          <path
            d="M43,25C43,25,43,25,43,25C43.1,25,43.1,25,43,25c1,0.1,2.3-0.2,3.2-0.6c0.3,0,0.7,0,1-0.1c0.1,0,0.3-0.1,0.3-0.2
           c0.1,0,2.8,0.2,2.9,0.2c1-0.7,0.2-1.2-1-1.4c0,0,0,0,0,0c-1.2-0.2-2.3,0-3.5,0.3c0,0,0,0,0,0c-0.6-0.1-2.9,0.1-3.3,0.8
           C42.1,24.3,42.4,25.2,43,25z"
          />
          <path
            d="M47.6,28c0.1-0.3,0.1-0.7,0-1c0,0,0,0,0,0c0.2,0.1,0.4,0.1,0.6,0.2c0.2,0.3,0.3,0.7,0.7,0.9c0.4,0.3,0.8-0.1,0.8-0.5
           c0-0.5-0.7-1.3-1.1-1.3c-0.4-0.6-2-0.5-2.6-0.4c-0.2-0.2-0.6,0-0.8,0.1c-1-1.8-2.8,3-1.7,1.5c0,0,0,0,0,0c0-0.1,0.1-0.1,0.1-0.2
           c0.3-0.1,0.6-0.2,0.9-0.3C44.5,28.7,46.7,30,47.6,28z M48.3,27.2C48.3,27.2,48.3,27.2,48.3,27.2C48.3,27.2,48.3,27.2,48.3,27.2
           L48.3,27.2z M46.4,27.1C46.4,27.1,46.4,27.1,46.4,27.1C46.4,27.1,46.4,27.1,46.4,27.1L46.4,27.1L46.4,27.1z"
          />
          <path
            d="M31.2,23.1C31.2,23.1,31.2,23.1,31.2,23.1c1.1,0.2,2.2,0.5,3.2,0.7c0.3,0.1,0.6,0.1,0.9,0.2c0.2,0.1,0.4,0.1,0.5,0.2l0,0
           c0,0,0.1,0,0.1,0c2,0.7,0.4-1.3-0.9-1.3c0,0,0,0,0,0c0,0-0.1,0-0.1,0c-0.2-0.1-0.3-0.1-0.5-0.1c-1.1-0.4-2.1-1.3-3.3-1.1
           C29.3,22,28.9,24.1,31.2,23.1C31.1,23.1,31.2,23.1,31.2,23.1z"
          />
          <path
            d="M37,27.5c1.8-1.1-3.4-3.1-4.7-2.6c-0.6,0.2-1.9,1.2-2,2c0,1.7,2.2-0.8,2.2-0.9c0.1,0,0.2,0,0.3-0.1c-1.6,1.9,3.6,3,2.6,0.4
           C35.8,26.7,36.3,27.9,37,27.5z M34.1,26.6L34.1,26.6L34.1,26.6L34.1,26.6L34.1,26.6z"
          />
          <path
            d="M74.6,74.2c-0.2-4.8-0.7-10.5-4.1-14.3C67,56.1,61.3,56,57,53.5c-1.8-1.1-5-2.9-6.1-4.7c-1.4-2.3-0.8-6.4-0.2-8.8
           c1.3-5,6.9-8.6,5.9-13.8c-1-4.9,1-9.6-1.7-14.5c-2.7-4.9-8.3-7-13.6-7.5C35.9,3.7,30.5,5,27.1,9.3c-3.6,4.5-3.9,11.1-0.7,15.9
           c-5.3,1.5,0.9,7.9,2.1,9.6c2.8,3.9,3.7,9.2,1.9,13.7c-6.4,3.2-15,6-20,11.3C8.7,61.5,2,76.5,7.4,76.3c2.1-0.1,1.2-7,1.4-8.2
           c0.5-2.9,1.8-5.6,4.1-7.6c4.5-4.1,11-4.9,15.1-9.5c2.4,2.6,8.1,10.5,12,10.3c4-0.1,9.4-6.5,11.9-9.3c2.8,2.6,6.2,4.5,9.8,5.8
           c3.2,1.1,6.3,1.7,8.3,4.7c2.1,3.2,1.8,7.2,2.3,10.9C72.6,76.2,74.8,77.9,74.6,74.2z M29.8,50.3c0,0.1-0.1,0.2-0.1,0.3
           c-0.1-0.1-0.1-0.2-0.2-0.3C29.5,50.3,29.7,50.3,29.8,50.3C29.8,50.2,29.8,50.3,29.8,50.3z M55.3,26.6c0-0.1,0.1-0.2,0.1-0.3
           c0,0,0,0.1,0.1,0.1c0.8,2.2-1.5,4.6-2.9,6.1c0.1-0.4,0.2-0.8,0.2-1.2c0.6-0.2,1.3-3.1,1.6-3.8C54.8,27.3,55.1,27,55.3,26.6z
            M28.1,32c-1-1.2-2.4-2.9-2.3-4.5c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0.1-0.1,0.1-0.2c0,0,0,0,0,0c0,0,0,0,0,0c0.4,0.1,0.9,0.4,1.2,0.7
           c0.1,0.1,0.2,0.3,0.2,0.5c0.1,1.4,0.4,2.7,0.8,4.1C28.2,32.1,28.1,32.1,28.1,32z M28,18.2c-0.9,2-1,4-0.9,6.3
           c-5.2-9.7,3-19,13-18.3c10.8,0.8,17.6,8.4,13.8,19.2c0.1-2.8-0.3-9.2-2.8-11.1c-2.9-2.2-6.3,1-9.1,1.2c-1.6,0.1-3.3-1.2-4.8-1.6
           c-1.6-0.5-3.5-0.9-5.2-0.8c-2.4,0.1-1.2,0.2-2.1,1.3C29.4,15,28.4,17.3,28,18.2z M49,52.8c-2.8,2.6-7.3,8.3-11.4,5.8
           c-1.8-1.1-4.9-3.7-5.7-5.7c-0.7-1.6,0-2.6,0.4-4.4c0.5-2.5,0.6-5.1,0.1-7.6c3.8,6.4,12,7.1,16.4,1.4c-0.4,2-0.5,4-0.3,6
           C48.6,49.4,49.8,52,49,52.8z M49.8,37.2c-3.7,7.8-11.9,10.5-17.2,2.3c-4.6-7.3-5.9-18.1-0.6-25.3c3,1,7.6,3.5,10.8,2.9
           c2.5-0.5,5.4-3.2,7.5-1c1.9,1.9,2,7.6,1.9,10.1C52.3,29.9,51.4,33.7,49.8,37.2z M51.4,51.1C51.3,51,51.1,51,51,51
           c0-0.1-0.1-0.1-0.1-0.2C51.1,50.9,51.2,51,51.4,51.1C51.4,51.1,51.4,51.1,51.4,51.1z"
          />
          <path
            d="M50.9,50.9C50.9,50.9,50.9,50.9,50.9,50.9L50.9,50.9C50.9,50.9,50.9,50.9,50.9,50.9C50.9,50.9,50.9,50.9,50.9,50.9
           C50.9,50.9,50.9,51,50.9,50.9L50.9,50.9C51,51,50.9,51,50.9,50.9z"
          />
          <path d="M41.2,17.5c-0.1,0-0.1-0.1-0.2-0.1C41.1,17.4,41.2,17.4,41.2,17.5z" />
        </g>
      </svg>
    );
  }
}

export default Member3;
