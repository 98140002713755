import React, { Component } from "react";

class SimpliciticLogo extends Component {
  state = {};
  componentDidMount() {
    this.logoAnimate();
  }

  logoAnimate() {
    const path_top = this.logoTop;
    const path_bottom = this.logoBottom;
    const length_top = path_top.getTotalLength();
    const length_bottom = path_bottom.getTotalLength();

    path_top.style.transition = path_top.WebkitTransition = "none";
    path_bottom.style.transition = path_bottom.WebkitTransition = "none";

    path_top.style.strokeDasharray = length_top + " " + length_top;
    path_top.style.strokeDashoffset = length_top;

    path_bottom.style.strokeDasharray = length_bottom + " " + length_bottom;
    path_bottom.style.strokeDashoffset = length_bottom;

    path_top.getBoundingClientRect();
    path_bottom.getBoundingClientRect();

    path_top.style.transition = path_top.style.WebkitTransition =
      "stroke-dashoffset 2.5s ease-in-out";
    path_bottom.style.transition = path_bottom.style.WebkitTransition =
      "stroke-dashoffset 2.5s ease-in-out";

    path_top.style.strokeDashoffset = "0";
    path_bottom.style.strokeDashoffset = "0";
  }
  render() {
    return (
      <div className="simplicitic-logo">
        <svg
          id="Layer_1"
          x="0px"
          y="0px"
          viewBox="0 0 75 75"
          enableBackground="new 0 0 75 75"
        >
          <g>
            <defs>
              <rect id="SVGID_1_" x="1.5" y="26.8" width="72" height="46.785" />
            </defs>
            <clipPath id="SVGID_2_">
              <use xlinkHref="#SVGID_1_" overflow="visible" />
            </clipPath>
            <path
              className="logo-bottom"
              ref={e => {
                this.logoBottom = e;
              }}
              clipPath="url(#SVGID_2_)"
              fill="none"
              stroke="#000000"
              strokeWidth="3.9509"
              strokeMiterlimit="10"
              d="M33.9,31.7h13.8c5.9,0,10.7,4.8,10.7,10.7l0,0c0,5.9-4.8,10.7-10.7,10.7H8
				c5.8,10.3,16.8,17.3,29.4,17.3c18.6,0,33.7-15.1,33.7-33.7c0-3.6-0.6-7-1.6-10.3l-0.2-0.5"
            />
          </g>
          <g>
            <defs>
              <rect id="SVGID_3_" x="1.5" y="1.5" width="72" height="46.305" />
            </defs>
            <clipPath id="SVGID_4_">
              <use xlinkHref="#SVGID_3_" overflow="visible" />
            </clipPath>
            <path
              className="logo-top"
              ref={e => {
                this.logoTop = e;
              }}
              clipPath="url(#SVGID_4_)"
              fill="none"
              stroke="#000000"
              strokeWidth="3.9509"
              strokeMiterlimit="10"
              d="M41,42.6H27.2c-5.9,0-10.7-4.8-10.7-10.7l0,0c0-5.9,4.8-10.7,10.7-10.7h39.7
				C61.1,10.8,50.1,3.8,37.5,3.8C18.9,3.8,3.8,18.9,3.8,37.5c0,3.6,0.6,7,1.6,10.3l0.3,0.8"
            />
          </g>
        </svg>
      </div>
    );
  }
}

export default SimpliciticLogo;
