import React from "react";

const TeamMember = props => {
  return (
    <div className="our-team-member animate-item">
      {/* <img src={props.image} alt="{props.name}" /> */}
      {props.image}
      <div className="our-team-position">{props.name}</div>
    </div>
  );
};

export default TeamMember;
