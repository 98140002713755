import React, { Component } from "react";
import { Helmet } from "react-helmet";
import ScrollMagic from "scrollmagic";
import TrackVisibility from "react-on-screen";
// import ReactGA from "react-ga";
import TagManager from "react-gtm-module";
import Member1 from "../images/member1";
import Member2 from "../images/member2";
import Member3 from "../images/member3";
import Member4 from "../images/member4";
import Member5 from "../images/member5";
import IconAngular from "../images/angular.svg";
import IconFirebase from "../images/firebase.svg";
import IconGWD from "../images/google-web-designer.svg";
// import IconMailChimp from "../images/mailchimp.svg";
import IconMySQL from "../images/mysql.svg";
import IconNodeJS from "../images/nodejs.svg";
import IconPHP from "../images/php.svg";
import IconReact from "../images/react.svg";
import IconWordpress from "../images/wordpress.svg";
// import IconJquery from "../images/jquery.svg";
// import IconAI from "../images/illustrator.svg";
// import IconPSD from "../images/photoshop.svg";
import Mailchimp from "../images/mailchimp";
import Jquery from "../images/jquery";
import Illustrator from "../images/illustrator";
import Photoshop from "../images/photoshop";
import Row from "./common/row";
import Col from "./common/col";
import TeamMember from "./common/teamMember";
import CTA from "./common/cta";

class About extends Component {
  constructor(props) {
    super(props);
    this.controller = new ScrollMagic.Controller();
  }
  state = {
    label: ""
  };
  componentDidMount() {
    // ReactGA.pageview("/about");
    const tagManagerArgs = {
      dataLayer: {
        event: "pageviewCustomEvent",
        pagePath: "/about",
        pageTitle: "About"
      },
      dataLayerName: "PageDataLayer"
    };
    TagManager.dataLayer(tagManagerArgs);

    var self = this;
    new ScrollMagic.Scene({
      triggerElement: ".about-us",
      duration: 400 // scroll distance
    })
      .on("enter", function(e) {
        self.updateLabel("About Us");
      })
      .addTo(this.controller);
    new ScrollMagic.Scene({
      triggerElement: ".our-team",
      duration: 700 // scroll distance
    })
      .on("enter", function(e) {
        self.updateLabel("Our Team");
      })
      .addTo(this.controller);
    new ScrollMagic.Scene({
      triggerElement: ".our-services",
      duration: 700 // scroll distance
    })
      .on("enter", function(e) {
        self.updateLabel("Our Services");
      })
      .addTo(this.controller);
    new ScrollMagic.Scene({
      triggerElement: ".our-tools",
      duration: 700 // scroll distance
    })
      .on("enter", function(e) {
        self.updateLabel("Our Tools");
      })
      .addTo(this.controller);
  }
  updateLabel = label => {
    this.setState({ label });
  };

  componentWillUnmount() {
    this.controller.destroy();
  }

  render() {
    return (
      <div id="page-about">
        <Helmet>
          <title>About - Simplicitic Innovations Sdn Bhd</title>
          <meta
            name="description"
            content="Digital agency based in Subang Jaya, Malaysia. We offer website design, app development, ecommerce setup and wordpress development"
          />
        </Helmet>
        <div className="label-container">
          <div className="label">{this.state.label}</div>
        </div>
        <TrackVisibility once>
          {({ isVisible }) => (
            <section
              className={`masthead text-center wrapper about-us ${
                isVisible ? "in-view" : ""
              }`}
            >
              <h1 className="animate-item">
                <span>With us, it's simple</span> Your business and product,
                <br /> Our expertise and design
              </h1>
              <p className="animate-item">
                We are a digital agency that focuses on making people understand
                how technology works easily by creating a simple solution. We
                always strive to challenge the boundaries of design perfection
                and technical constraints. Simply put, we are here to offer you
                a one-stop solution to all your online marketing needs.
              </p>
            </section>
          )}
        </TrackVisibility>
        <section className="wrapper our-team">
          <TrackVisibility once>
            {({ isVisible }) => (
              <div className={isVisible ? "in-view" : ""}>
                <h2 className="text-center animate-item">
                  Simple people bringing together complex ideas
                </h2>
              </div>
            )}
          </TrackVisibility>
          <TrackVisibility once>
            {({ isVisible }) => (
              <div className={`our-team-members ${isVisible ? "in-view" : ""}`}>
                <TeamMember name={"UI / UX Designer"} image={<Member1 />} />
                <TeamMember name={"Front-end Developer"} image={<Member2 />} />
                <TeamMember name={"Back-end Developer"} image={<Member3 />} />
                <TeamMember name={"Mobile App Developer"} image={<Member4 />} />
                <TeamMember name={"Project Manager"} image={<Member5 />} />
              </div>
            )}
          </TrackVisibility>
          <TrackVisibility once offset={50}>
            {({ isVisible }) => (
              <Row className={`${isVisible ? "in-view" : ""}`}>
                <Col span={6} className="animate-item">
                  <p>
                    We bring together an experienced and energetic group of
                    people with a focused mission - to transform traditional
                    businesses to win in the digital revolution. Our team's
                    strong ambitions, ceiling standards and agility extends from
                    the beginning of your project all the way to the finish
                    line.
                  </p>
                </Col>
                <Col span={6} className="animate-item">
                  <p>
                    Comprised of a group of strategists, designers and
                    developers, we don't focus exclusively on either design or
                    functionality, our focus is on both; we believe they are
                    both important. Each project is carefully curated to ensure
                    it meets both design and functionality.
                  </p>
                </Col>
              </Row>
            )}
          </TrackVisibility>
        </section>
        <section className="our-services">
          <TrackVisibility once partialVisibility>
            {({ isVisible }) => (
              <div className={`row wrapper ${isVisible ? "in-view" : ""}`}>
                <Col span={6}>
                  <h2 className="animate-item">
                    A vast array of technological services,
                    <br /> all in one simple place
                  </h2>
                </Col>
                <Col span={6}>
                  <div className="row">
                    <div className="our-service animate-item">
                      <h3>Design</h3>
                      <ul>
                        <li>UI & UX Design</li>
                        <li>Website Design</li>
                        <li>Responsive Design</li>
                        <li>Brand Identity</li>
                        <li>Icon Design</li>
                      </ul>
                    </div>
                    <div className="our-service animate-item">
                      <h3>Development</h3>
                      <ul>
                        <li>Wordpress</li>
                        <li>iOS/Android Development</li>
                        <li>System Customisation</li>
                        <li>CMS Development</li>
                        <li>Ecommerce Setup</li>
                      </ul>
                    </div>
                    <div className="our-service animate-item">
                      <h3>Maintenance</h3>
                      <ul>
                        <li>Domain/Hosting</li>
                        <li>Content Update</li>
                        <li>Technical Support</li>
                      </ul>
                    </div>
                    <div className="our-service animate-item">
                      <h3>Digital Marketing</h3>
                      <ul>
                        <li>eNewsletter Design</li>
                        <li>Web Banner Design</li>
                        <li>Search Engine Optimisation</li>
                      </ul>
                    </div>
                  </div>
                </Col>
              </div>
            )}
          </TrackVisibility>
        </section>
        <section className="our-tools wrapper">
          <TrackVisibility once>
            {({ isVisible }) => (
              <div className={`${isVisible ? "in-view" : ""}`}>
                <h2 className="animate-item">
                  Navigating the complexity of technology to find you a simple
                  solution
                </h2>
              </div>
            )}
          </TrackVisibility>
          <TrackVisibility once partialVisibility>
            {({ isVisible }) => (
              <ul className={`row ${isVisible ? "in-view" : ""}`}>
                <li className="animate-item">
                  <img src={IconAngular} alt="Angular" />
                </li>
                <li className="animate-item">
                  <img src={IconFirebase} alt="Firebase" />
                </li>
                <li className="animate-item">
                  <img src={IconGWD} alt="GWD" />
                </li>
                <li className="animate-item">
                  {/* <img src={IconMailChimp} alt="Mailchimp" /> */}
                  <Mailchimp />
                </li>
                <li className="animate-item">
                  <img src={IconMySQL} alt="MySQL" />
                </li>
                <li className="animate-item">
                  <img src={IconNodeJS} alt="NodeJS" />
                </li>
                <li className="animate-item">
                  <img src={IconPHP} alt="PHP" />
                </li>
                <li className="animate-item">
                  <img src={IconReact} alt="React" />
                </li>
                <li className="animate-item">
                  <img src={IconWordpress} alt="Wordpress" />
                </li>
                <li className="animate-item">
                  {/* <img src={IconJquery} alt="JQuery" /> */}
                  <Jquery />
                </li>
                <li className="animate-item">
                  {/* <img src={IconAI} alt="Illustrator" /> */}
                  <Illustrator />
                </li>
                <li className="animate-item">
                  {/* <img src={IconPSD} alt="Photoshop" /> */}
                  <Photoshop />
                </li>
              </ul>
            )}
          </TrackVisibility>
        </section>
        <CTA />
      </div>
    );
  }
}

export default About;
