const devConfig = {
  gaKey: "UA-136258045-2",
  gtmId: "GTM-W7XK6FD"
};

const prodConfig = {
  gaKey: "UA-52697059-1"
};

export const FILE_SIZE = 2000 * 1024;  // 2MB

export const SUPPORTED_FORMATS = ['pdf', 'docx', 'doc', 'txt'];

function getConfig() {
  console.log(process.env.REACT_APP_DEV_ENV);
  switch (process.env.REACT_APP_DEV_ENV) {
    case "development":
      return devConfig;
    case "production":
      return prodConfig;
    default:
      return devConfig;
  }
}

export const finalConfig = getConfig();
