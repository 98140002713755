import React from "react";

const Col = props => {
  return (
    <div
      className={`col-${props.span} ${props.className ? props.className : ""}`}
    >
      {props.children}
    </div>
  );
};

export default Col;
