import { Component } from "react";
import moment from "moment-timezone";

class Clock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      time: moment.tz("Asia/Kuala_Lumpur").format(this.props.format)
    };
  }

  componentDidMount() {
    // add the interval
    this.interval = setInterval(this.updateReminderTime.bind(this), 100);
  }

  componentWillUnmount() {
    // remove the interval listener
    clearInterval(this.interval);
  }

  updateReminderTime() {
    this.setState({
      time: moment()
        .tz("Asia/Kuala_Lumpur")
        .format(this.props.format)
    });
  }

  render() {
    return this.state.time;
  }
}

export default Clock;
