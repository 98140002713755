import React, { Component } from "react";
import Vimeo from "@u-wave/react-vimeo";

class PortfolioWisdomPark extends Component {
  constructor(props) {
    super(props);
    const { isHovered } = this.props;
    this.state = {
      isHovered: isHovered,
      isReady: false
    };
  }
  componentDidUpdate(prevProps) {
    const { isHovered } = this.props;
    if (isHovered !== prevProps.isHovered) {
      this.setState({ isHovered });
    }
  }
  handlePlayerReady() {
    this.setState({ isReady: true });
  }
  render() {
    const { isHovered, isReady } = this.state;
    return (
      <div className="portfolio-img animate-item">
        <div className="portfolio-thumb">
          <h5>Wisdom Park</h5>
          <div>
            An integrated holistic educational facility for the community.
          </div>
        </div>
        <div className={`portfolio-cover ${isReady ? "ready" : ""}`} />
        <div className="portfolio-full">
          <Vimeo
            video={263630297}
            muted
            background
            paused={!isHovered}
            onProgress={() => this.handlePlayerReady()}
          />
        </div>
      </div>
    );
  }
}

export default PortfolioWisdomPark;
