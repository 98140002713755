import React, { Component } from "react";

class Photoshop extends Component {
  render() {
    return (
      <svg
        version="1.1"
        id="Layer_1"
        x="0px"
        y="0px"
        viewBox="0 0 580 180"
        style={{ enableBackground: "new 0 0 580 180" }}
        xmlSpace="preserve"
      >
        <g id="Text">
          <path
            fill="#1D1D1B"
            className="darkFill"
            d="M227,58.4l-5.3,16.1h-6.8l17.4-51.2h8l17.5,51.2h-7.1l-5.5-16.1H227z M243.8,53.2l-5-14.7
		c-1.1-3.3-1.9-6.4-2.7-9.3H236c-0.8,3-1.6,6.2-2.6,9.3l-5,14.8H243.8z"
          />
          <path
            fill="#1D1D1B"
            className="darkFill"
            d="M295.8,20.5V65c0,3.3,0.1,7,0.3,9.5h-6l-0.3-6.4h-0.2c-2.1,4.1-6.5,7.2-12.5,7.2c-8.9,0-15.7-7.5-15.7-18.7
		c-0.1-12.2,7.5-19.8,16.5-19.8c5.6,0,9.4,2.7,11.1,5.6h0.2v-22H295.8z M289.2,52.7c0-0.8-0.1-2-0.3-2.8c-1-4.3-4.6-7.8-9.7-7.8
		c-6.9,0-11,6.1-11,14.2c0,7.4,3.6,13.6,10.9,13.6c4.5,0,8.6-3,9.8-8c0.2-0.9,0.3-1.8,0.3-2.9V52.7z"
          />
          <path
            fill="#1D1D1B"
            className="darkFill"
            d="M340.2,55.8c0,13.6-9.4,19.5-18.3,19.5c-10,0-17.6-7.3-17.6-18.9c0-12.3,8.1-19.5,18.2-19.5
		C333.1,36.9,340.2,44.6,340.2,55.8z M311,56.2c0,8.1,4.6,14.1,11.2,14.1c6.4,0,11.2-6,11.2-14.3c0-6.2-3.1-14.1-11-14.1
		C314.5,41.9,311,49.2,311,56.2z"
          />
          <path
            fill="#1D1D1B"
            className="darkFill"
            d="M348.4,74.5c0.2-2.5,0.3-6.2,0.3-9.5V20.5h6.6v23.1h0.2c2.4-4.1,6.6-6.8,12.5-6.8c9.1,0,15.6,7.6,15.5,18.8
		c0,13.1-8.3,19.7-16.5,19.7c-5.3,0-9.6-2.1-12.3-6.9h-0.2l-0.3,6.1H348.4z M355.3,59.8c0,0.8,0.2,1.7,0.3,2.4
		c1.3,4.6,5.2,7.8,10,7.8c7,0,11.2-5.7,11.2-14.1c0-7.4-3.8-13.7-10.9-13.7c-4.6,0-8.8,3.1-10.2,8.2c-0.2,0.8-0.4,1.7-0.4,2.7V59.8z
		"
          />
          <path
            fill="#1D1D1B"
            className="darkFill"
            d="M395.6,57.3c0.2,9,5.9,12.8,12.6,12.8c4.8,0,7.7-0.8,10.2-1.9l1.1,4.8c-2.4,1.1-6.4,2.3-12.2,2.3
		c-11.3,0-18.1-7.4-18.1-18.5c0-11.1,6.5-19.8,17.3-19.8c12,0,15.2,10.6,15.2,17.3c0,1.4-0.2,2.4-0.2,3.1H395.6z M415.2,52.5
		c0.1-4.3-1.7-10.9-9.3-10.9c-6.8,0-9.7,6.2-10.3,10.9H415.2z"
          />
          <path
            fill="#1D1D1B"
            className="darkFill"
            d="M218.3,106c3.3-0.6,8-1.1,14.3-1.1c6.8,0,11.9,1.4,15.1,4.2c3,2.5,5,6.5,5,11.3c0,4.9-1.5,8.9-4.4,11.6
		c-3.7,3.7-9.6,5.5-16.2,5.5c-1.7,0-3.3-0.1-4.6-0.3v19.3h-9.3V106z M227.6,129.8c1.1,0.3,2.7,0.4,4.6,0.4c7,0,11.2-3.4,11.2-9.4
		c0-5.7-4-8.7-10.4-8.7c-2.6,0-4.4,0.2-5.4,0.5V129.8z"
          />
          <path
            fill="#1D1D1B"
            className="darkFill"
            d="M260.2,102.5h9.4v22h0.2c1.1-1.7,2.7-3.2,4.6-4.3c1.8-1.1,4-1.7,6.4-1.7c6.3,0,12.9,4.2,12.9,16v21.8h-9.3
		v-20.7c0-5.4-2-9.4-7.1-9.4c-3.6,0-6.2,2.4-7.2,5.2c-0.3,0.8-0.4,1.7-0.4,2.8v22.1h-9.4V102.5z"
          />
          <path
            fill="#1D1D1B"
            className="darkFill"
            d="M338.9,137.6c0,13.6-9.6,19.7-19,19.7c-10.5,0-18.5-7.2-18.5-19.1c0-12.2,8-19.6,19.2-19.6
		C331.5,118.6,338.9,126.4,338.9,137.6z M311,138c0,7.1,3.5,12.5,9.2,12.5c5.3,0,9-5.2,9-12.7c0-5.8-2.6-12.4-9-12.4
		C313.6,125.5,311,131.9,311,138z"
          />
          <path
            fill="#1D1D1B"
            className="darkFill"
            d="M357.3,108.9v10.6h8.9v7h-8.9v16.3c0,4.5,1.2,6.8,4.8,6.8c1.6,0,2.8-0.2,3.6-0.5l0.2,7.1
		c-1.4,0.5-3.8,0.9-6.8,0.9c-3.4,0-6.3-1.1-8.1-3c-2-2.1-2.9-5.5-2.9-10.3v-17.4h-5.3v-7h5.3v-8.4L357.3,108.9z"
          />
          <path
            fill="#1D1D1B"
            className="darkFill"
            d="M407.9,137.6c0,13.6-9.6,19.7-19,19.7c-10.5,0-18.5-7.2-18.5-19.1c0-12.2,8-19.6,19.2-19.6
		C400.6,118.6,407.9,126.4,407.9,137.6z M380.1,138c0,7.1,3.5,12.5,9.2,12.5c5.3,0,9-5.2,9-12.7c0-5.8-2.6-12.4-9-12.4
		C382.7,125.5,380.1,131.9,380.1,138z"
          />
          <path
            fill="#1D1D1B"
            className="darkFill"
            d="M415.2,147.9c2.1,1.3,6.2,2.7,9.5,2.7c4.1,0,5.9-1.7,5.9-4.1c0-2.5-1.5-3.8-6.1-5.4
		c-7.2-2.5-10.3-6.5-10.2-10.8c0-6.5,5.4-11.6,14-11.6c4.1,0,7.7,1.1,9.8,2.2l-1.8,6.6c-1.6-0.9-4.6-2.1-7.8-2.1
		c-3.3,0-5.2,1.6-5.2,3.9c0,2.4,1.7,3.5,6.5,5.2c6.7,2.4,9.8,5.9,9.9,11.3c0,6.7-5.2,11.6-15,11.6c-4.5,0-8.5-1.1-11.2-2.6
		L415.2,147.9z"
          />
          <path
            fill="#1D1D1B"
            className="darkFill"
            d="M447.3,102.5h9.4v22h0.2c1.1-1.7,2.7-3.2,4.6-4.3c1.8-1.1,4-1.7,6.4-1.7c6.3,0,12.9,4.2,12.9,16v21.8h-9.3
		v-20.7c0-5.4-2-9.4-7.1-9.4c-3.6,0-6.2,2.4-7.2,5.2c-0.3,0.8-0.4,1.7-0.4,2.8v22.1h-9.4V102.5z"
          />
          <path
            fill="#1D1D1B"
            className="darkFill"
            d="M526,137.6c0,13.6-9.6,19.7-19,19.7c-10.5,0-18.5-7.2-18.5-19.1c0-12.2,8-19.6,19.2-19.6
		C518.6,118.6,526,126.4,526,137.6z M498.1,138c0,7.1,3.5,12.5,9.2,12.5c5.3,0,9-5.2,9-12.7c0-5.8-2.6-12.4-9-12.4
		C500.7,125.5,498.1,131.9,498.1,138z"
          />
          <path
            fill="#1D1D1B"
            className="darkFill"
            d="M533.6,131.8c0-4.9-0.2-8.8-0.3-12.3h8.2l0.5,5.7h0.2c2.7-4.2,7.1-6.5,12.7-6.5c8.5,0,15.6,7.3,15.6,18.8
		c0,13.4-8.4,19.8-16.9,19.8c-4.6,0-8.5-2-10.4-4.9H543v19.2h-9.3V131.8z M543,141c0,0.9,0.1,1.7,0.3,2.6c0.9,3.8,4.3,6.5,8.2,6.5
		c5.9,0,9.4-4.9,9.4-12.2c0-6.5-3.2-11.9-9.2-11.9c-3.9,0-7.4,2.8-8.4,6.9c-0.2,0.8-0.4,1.6-0.4,2.4V141z"
          />
        </g>
        <g id="Icon">
          <radialGradient
            id="PSD_GID"
            cx="-183.0376"
            cy="-147.6605"
            r="5.612288e-02"
            gradientTransform="matrix(2291.6716 0 0 -2218.7549 419525.4062 -327577.9062)"
            gradientUnits="userSpaceOnUse"
          >
            <stop
              offset="0"
              style={{ stopColor: "#34364E", stopOpacity: "0.98" }}
            />
            <stop offset="1" style={{ stopColor: "#0C0824" }} />
          </radialGradient>
          <path fill="url(#PSD_GID)" d="M7.7,7.7h169.2v164.6H7.7V7.7z" />
          <path
            fill="#31C5F0"
            d="M7.7,7.7h169.2v164.6H7.7V7.7z M0,180h184.6V0H0V180z M124.5,73.8c-6.1,0-8.2,3.1-8.2,5.6
		c0,2.8,1.4,4.7,9.5,8.9c12.1,5.8,15.8,11.5,15.8,19.7c0,12.3-9.4,18.9-22.1,18.9c-6.7,0-12.5-1.4-15.8-3.3
		c-0.5-0.2-0.6-0.6-0.6-1.2v-11.3c0-0.8,0.4-1,0.9-0.6c4.8,3.2,10.4,4.5,15.5,4.5c6.1,0,8.6-2.5,8.6-6c0-2.8-1.8-5.2-9.5-9.2
		c-10.9-5.2-15.5-10.5-15.5-19.4c0-9.9,7.8-18.2,21.2-18.2c6.6,0,11.2,1,13.8,2.2c0.6,0.4,0.8,1,0.8,1.5v10.5c0,0.6-0.4,1-1.2,0.8
		C134.5,75.2,129.5,73.8,124.5,73.8L124.5,73.8z M58.3,83c1.8,0.2,3.2,0.2,6.2,0.2c9,0,17.5-3.2,17.5-15.4c0-9.8-6.1-14.7-16.3-14.7
		c-3.1,0-6,0.2-7.4,0.2V83z M44.6,42.2c0-0.5,1.1-0.9,1.7-0.9c4.9-0.2,12.2-0.4,19.8-0.4c21.3,0,29.6,11.7,29.6,26.6
		c0,19.5-14.2,27.9-31.5,27.9c-2.9,0-3.9-0.2-6-0.2v29.5c0,0.6-0.2,0.9-0.9,0.9H45.5c-0.6,0-0.9-0.2-0.9-0.9L44.6,42.2L44.6,42.2z"
          />
        </g>
      </svg>
    );
  }
}

export default Photoshop;
