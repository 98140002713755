import React, { Component } from "react";

class Footer extends Component {
  state = {};
  render() {
    return (
      <footer className="footer-container wrapper">
        Ⓒ Copyright 2019. Simplicitic Innovations Sdn. Bhd.
      </footer>
    );
  }
}

export default Footer;
