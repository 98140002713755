import React from "react";
import TrackVisibility from "react-on-screen";
import axios from "axios";
import * as yup from "yup";

import Form from "./form";
import Row from "./row";
import Col from "./col";
// import { FILE_SIZE, SUPPORTED_FORMATS } from "../../config";

const FILE_SIZE = 2000 * 1024; // 2MB
const SUPPORTED_FORMATS = ["pdf", "docx", "doc", "txt"];

class FormJoinTeam extends Form {
  state = {
    data: {
      name: "",
      email: "",
      phone: "",
      position: "",
      resume: "",
      about: ""
    },
    errors: {},
    focus: {},
    sending: false,
    mailSent: false,
    mailError: false
  };
  schema = yup.object().shape({
    name: yup.string().required("Please enter your name"),
    email: yup
      .string()
      .email()
      .required("Please enter your email"),
    phone: yup.string().required("Please enter your phone number"),
    position: yup
      .string()
      .required("Please enter a position you want to apply"),
    resume: yup
      .mixed()
      .test("fileSize", "File size must be below 2mb", value =>
        value.size ? value.size <= FILE_SIZE : false
      )
      .test(
        "fileType",
        "File format must be pdf or doc",
        value =>
          value.name && SUPPORTED_FORMATS.includes(value.name.split(".")[1])
      )
      .test("fileRequired", "Please upload your resume", value => value.name),
    about: yup.string().required("Say something about you")
  });
  doSubmit = async () => {
    this.setState({ sending: true });
    try {
      console.log(this.state.data);
      const bodyData = new FormData();
      for (const key of Object.keys(this.state.data)) {
        bodyData.append(key, this.state.data[key]);
      }
      const result = await axios({
        method: "post",
        // url: "http://localhost:8080/contact-form-submit.php?form=join-team",
        url: "contact-form-submit.php?form=join-team",
        data: bodyData
      });
      this.setState({
        mailSent: result.data.sent,
        data: {
          name: "",
          email: "",
          phone: "",
          position: "",
          resume: "",
          about: ""
        },
        sending: false
      });
    } catch (error) {
      this.setState({ mailError: error.message, sending: false });
    }
  };
  render() {
    const { mailSent, mailError } = this.state;
    return (
      <TrackVisibility once partialVisibility>
        {({ isVisible }) => (
          <form
            onSubmit={this.handleSubmit}
            className={isVisible ? "in-view" : ""}
          >
            <Row>
              <Col span={6}>
                {this.renderInput("name", "Name *")}
                {this.renderInput("email", "Email *", "email")}
              </Col>
              <Col span={6}>
                {this.renderInput("phone", "Phone Number *", "tel")}
                {this.renderInput("position", "Position *")}
              </Col>
            </Row>
            {this.renderInput("resume", "Resume (.pdf & .doc only) *", "file")}
            {this.renderTextarea("about", "About Yourself *")}
            {mailSent && this.renderMsgSuccess()}
            {mailError && this.renderMsgFailed()}
            {this.renderSubmitButton("Send")}
          </form>
        )}
      </TrackVisibility>
    );
  }
}

export default FormJoinTeam;
