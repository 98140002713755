import React, { Component } from "react";

class Member1 extends Component {
  state = {};
  render() {
    return (
      <svg
        version="1.1"
        id="Layer_1"
        x="0px"
        y="0px"
        viewBox="0 0 80 80"
        style={{ enableBackground: "new 0 0 80 80" }}
        xmlSpace="preserve"
      >
        <g className="member">
          <path d="M53.6,20.9c0,0,0,0.1,0,0.1C53.6,21,53.6,20.9,53.6,20.9z" />
          <path d="M27.9,30.3C27.9,30.3,27.9,30.3,27.9,30.3C27.9,30.3,27.9,30.3,27.9,30.3z" />
          <path d="M33.8,41.7c0,0,0,0.1,0,0.1C33.8,41.8,33.8,41.7,33.8,41.7z" />
          <path d="M48.2,50C48.2,50,48.2,50,48.2,50C48.2,50,48.2,50.1,48.2,50C48.2,50.1,48.2,50,48.2,50z" />
          <path d="M45.4,54.3C45.4,54.3,45.4,54.3,45.4,54.3C45.4,54.3,45.4,54.3,45.4,54.3z" />
          <path d="M39.4,30.3C39.4,30.3,39.4,30.3,39.4,30.3C39.4,30.3,39.4,30.3,39.4,30.3z" />
          <path
            d="M39.4,33.3c0.4,0.5,2.2,1.3,2.9,0.8c1.4-0.8-1.6-1.2-1.8-1.3c-0.2-0.1-0.4-0.2-0.5-0.4c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1
           c0-0.1,0-0.2,0-0.3c0,0,0,0.1,0,0.1c0.1-0.7,0.7-7.3-0.2-6.8c-0.3,0.2,0,2.9-0.1,3c0,0,0,0,0,0c0,0.2-0.1,0.5-0.1,0.7c0,0,0,0,0,0
           c-0.1,0.4-0.1,0.8-0.2,1.3c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0,0-0.1C39.3,31.2,38.6,32.5,39.4,33.3z"
          />
          <path d="M31.7,24.4c1.6,0.4,4-0.2,5.8,0c0.2,0.1,0.5,0.2,0.7-0.1c1.4-1-5.3-1.4-6.2-1.3C31.2,23,31,24.2,31.7,24.4z" />
          <path
            d="M44,25.1c0.2,0,0.3-0.1,0.5-0.1c0.3,0.4,2.8-0.3,3.5-0.3c0.6,0.2,1.3,0.3,1.9,0.5c0,0,0,0,0,0c0.1,0,0.2,0.1,0.3,0
           c0.8,0.2,0.5-0.5,0.4-0.8c-0.2-0.7-2.1-1.1-2.6-1.2c0,0,0,0,0.1,0c-0.9-0.1-1.8,0-2.6,0.2c-0.7,0-2,0.1-2.1,1
           C43.3,24.8,43.5,25.1,44,25.1z"
          />
          <path
            d="M38.3,27.3c-0.1-2.1-2.5-2.4-4.1-1.9c-2.9,0.4-1.8,3.6-0.7,1.9c0.1-0.2,0.3-0.3,0.5-0.4c0,0.7,0.8,1,1.4,0.9
           c0.6,0.1,1.2-0.5,1.1-1.1c0,0,0,0,0,0c-0.2-0.2,0.6,0.6,0.7,0.6C37.3,27.9,38.3,28.1,38.3,27.3z"
          />
          <path
            d="M49.3,26.9c-0.3-0.4-0.5-0.8-1-0.8c-0.4-0.2-0.7-0.3-1.1-0.4c-0.3-0.1-0.6-0.1-0.9,0c-0.3,0-0.7,0.1-1,0.2
           c-0.5,0-2.8,1.6-1.4,2c0.4-0.1,0.6-0.3,0.5-0.8c0.2,0.1,0.6-0.1,0.8-0.2c-0.1,0.4,0.2,0.9,0.6,1c0.3,0.5,0.8,0.6,1.4,0.3
           c0.5-0.1,0.8-0.5,0.9-0.9C48.5,28.6,50.1,27.8,49.3,26.9z"
          />
          <path
            d="M38,36.4c-0.2,0.8,1.8,0.9,2.2,1c0,0,0,0-0.1,0c0.8,0.1,1.7-0.1,2.5-0.2c0.3,0,2.3-0.4,1.2-1l0,0
           C43.4,35.8,37.9,36.7,38,36.4z M40.1,37.3C40.1,37.3,40.1,37.3,40.1,37.3C40.1,37.3,40.1,37.3,40.1,37.3z M40.1,37.3
           C40.1,37.3,40.1,37.3,40.1,37.3c0,0-0.1,0-0.1,0C40,37.3,40.1,37.3,40.1,37.3z"
          />
          <path d="M41.6,38.3c-0.6-0.1-2.1-0.2-1.8,0.6C40.2,40.4,43,38.5,41.6,38.3z" />
          <path
            d="M77.2,73.5c-0.4-6.6-4.4-12.4-10.1-15.6c-5.2-2.8-11.7-4.1-15.5-9c-3.2-4.1-2.4-10.2,0.3-14.5c1.1-1.8,7.4-6.6,4.3-8.9
           c0.1-0.1,0.1-0.2,0.1-0.4c2.1-0.1,1-3.5,0.7-4.4c0-0.1,0.1-0.2,0.1-0.3c1.5-2.7,2-3.3,0.2-6.2c0.1-0.2,0.1-0.3,0.2-0.5
           c0,0.1,0,0.1-0.1,0.2c0.1-0.3,0.3-0.7,0.3-1c0.3-1.5-0.7-3.3-1.9-4.1c0.1-0.8,0-1.6-0.5-2.2c-1.4-2.6-3.8-2.4-6.3-2.5
           c-0.1,0-0.2-0.1-0.4-0.1c-0.1-0.1-0.2-0.1-0.3-0.2c0,0,0,0,0,0c-0.2-0.4-0.4-0.6-0.7-0.9c-1.7-2.2-3.8-1.3-5.7-0.4c0,0-0.1,0-0.1,0
           c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0c-1.7-1-3.4-1.3-5.2-0.1c-0.9,0.5-1.3,1.3-1.6,2.2c-0.5,0.1-1,0.1-1.4,0.2c-0.7-0.2-1.1,0-1.7,0.3
           c-1.9,0.6-2.6,2-3,3.8c-0.4,0.3-0.8,0.6-1.1,1c-2.3,1-1.8,2.9-1.3,4.8c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c-1.5,1.8-2.1,3.3-0.4,5.3
           c0,0.1,0.1,0.1,0.1,0.2c0.1,0.2,0.1,0.4,0.2,0.6c0.1,1.6-0.3,2.8,1.1,3.9c-2.5,1.7,1.2,5.7,2.3,7.2c0.5,2.5,1.6,5.8,3.2,7.8
           c0,2.9,0,5.9-0.9,8.7c-2.7-0.8-6.3,3.5-8.2,4.8c-3,2.2-6.6,3.4-9.7,5.5C9.3,62.1,4,69.3,6.5,75.1c2-1.2,2.2-4.7,2.8-6.7
           c0.8-2.4,1.9-4.7,3.9-6.4c4.5-4,10.6-5.3,15.4-8.6c4,11.3,20.5,11.5,24.1-0.2c5.2,3.7,12.3,4.2,17,8.7c2.5,2.4,3.8,5.4,4.7,8.7
           c0.3,0.9,0.6,4.3,1.4,4.9C77.3,76.2,77.3,74.5,77.2,73.5z M48.2,50c-0.1,0.5,0,0.9-0.1,1.4c-0.5,1.1-1.8,2.2-2.7,2.9c0,0,0,0,0,0
           c-0.1,0-0.1,0.1-0.2,0.1c0,0,0.1-0.1,0.1-0.1c-4.1,3.2-10,1.2-12.7-2.9c0.2-0.1,0.3-0.3,0.4-0.5c1.2-3,1.2-6.6,0.6-9.7
           c1.5,1.3,5.8,5.6,8.2,4.2c1.2-0.2,2.6-1.2,3.6-2.1c0.9-0.6,1.8-1.2,2.6-1.9c-0.6,2.6-0.5,5.8,0.3,8.3C48.3,49.8,48.3,49.8,48.2,50
           C48.2,49.9,48.2,49.9,48.2,50C48.2,49.9,48.2,49.9,48.2,50z M31.1,50.7C31.1,50.7,31,50.7,31.1,50.7C31,50.7,31,50.7,31.1,50.7
           C31,50.7,31.1,50.7,31.1,50.7z M30.9,50.7C30.9,50.7,30.9,50.7,30.9,50.7C30.9,50.7,30.9,50.7,30.9,50.7L30.9,50.7z M34.9,6.2
           c1.9-0.7,1.6-2.8,3.8-2.8c0.8,0,1.6,0.9,2.4,1c1.4,0.1,1.2-0.4,2.4-0.5c1.7-0.1,0.9-0.7,2.5,0.3c1.2,0.7,1.1,1.5,2.6,1.9
           c2,0.6,3.7-0.5,4.9,1.6c0.4,0.7-0.1,1.7,0.4,2.5c0.4,0.6,1.6,0.7,1.9,1.4c0.4,1-0.4,1.3-0.4,2.3c0,1,0.4,1.2,0.6,2.1
           c0.2,1,0.7,0.7,0.3,2c-0.2,0.6-1.2,0.9-1.5,1.6c-0.6,1.3,1.6,5.6-0.9,6.1c-0.3-2.2-0.9-4.6-0.8-6.8c0-1.6,1.4-4.4-1-5
           c-0.7-0.2-3.3,0.6-4.1,0.8c-1.3,0.4-1.2,0-2.3,0.9c-0.4,0.4-0.4,1.4-1,1.6c-1.7,0.6-1.5-1.3-3-1.7c-0.8-0.2-1.4,0.7-2.1,0.7
           c-1-0.1-1-0.5-1.8-0.9c-1.5-0.9-5.5-4.4-5.4-0.6c-3.5-1.6-4.4,7.7-4,9.5c-0.5-1.9-1.7-4.5-1.5-6.5c0.1-1,1-1.6,1.2-2.6
           c0.2-0.7-0.5-1.9-0.2-2.6c0.5-1.3,1.8-1.7,2.4-3.3C31.6,6.5,32,7.2,34.9,6.2z M57.1,20.8C57.1,20.8,57.1,20.8,57.1,20.8
           C57.1,20.8,57.1,20.8,57.1,20.8C57.1,20.8,57.1,20.8,57.1,20.8z M51.9,15.5C52,15.5,52,15.5,51.9,15.5C52,15.5,52,15.5,51.9,15.5
           C51.9,15.5,51.9,15.5,51.9,15.5z M41.5,17.1C41.5,17.1,41.5,17.1,41.5,17.1C41.5,17.1,41.5,17.1,41.5,17.1L41.5,17.1z M49.8,14.9
           C49.8,14.9,49.8,14.9,49.8,14.9C49.9,14.9,49.9,14.9,49.8,14.9C49.8,14.9,49.8,14.9,49.8,14.9z M27.9,23.2
           C27.9,23.2,28,23.2,27.9,23.2C28,23.2,27.9,23.2,27.9,23.2C27.9,23.2,27.9,23.2,27.9,23.2z M30.9,31.8c-0.1-0.6-0.2-1.2-0.3-1.8
           c0,0,0,0.1,0,0.1c-0.1-0.8,0.2-3.4-0.4-4c0-1.5-0.6-3.5-0.2-4.9c1-1.9,1-2.1,1.1-4.3c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0,0,0,0
           c0.1,0,0.1-0.1,0.2-0.1c0.9-0.4,2.2-0.6,2.7-1.6c0.7,0.5,1.4,0.7,2.3,0.7c1.3,2.2,2.8,2.5,5.1,1.3c1.5,2.5,4.3,3,5.4-0.3
           c0.3-0.3,0.6-0.5,1-0.7c0.8,0.7,2,0.7,2.8,0.1c0.4-0.2,0.7-0.5,1-0.8c0,0,0,0,0.1,0c0.2,0.1,0.4,0.3,0.6,0.5c0,0,0,0,0,0
           c-1,1.6-1.4,2.3-0.5,4.1c0,0,0,0.1,0,0.1c0.1,2-1,2.8,0.6,4.6c0,0,0,0,0,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2,0,0.3
           c-0.1,0.2-0.1,0.4-0.1,0.7c-0.5,0.2-0.4,1.8-0.4,2.2c0,0,0-0.1,0-0.1c-0.5,2.6-1.3,5.1-2.2,7.6c-1.2,3.2-2.8,4.2-5.3,6.4
           c-0.9,0.5-3.2,0.7-3.8,1.4c-0.6-0.3-1.4-0.4-2.1-0.7c0,0,0,0,0,0c0,0-0.1,0-0.1-0.1c0,0,0.1,0,0.1,0c-2.5-1.1-4.9-3.1-6.3-5.5
           C31.9,35.5,31.2,33.7,30.9,31.8z M48,44.1c0,0,0,0.1,0,0.1C48,44.1,48,44.1,48,44.1z M55.6,27.6c-0.7,1.6-1.9,3-3.1,4.2
           c0.2-1.1,0.4-2.1,0.6-3.2c0.4-0.8,0.9-1.7,1.8-2.1c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0c0,0,0.1,0,0.1,0c0.1,0,0.1,0.1,0.2,0.1
           c0,0,0.1,0.1,0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0.1,0.2,0.1,0.2c0,0,0,0.1,0,0.1C55.7,27.3,55.7,27.5,55.6,27.6z M57.1,20.9
           C57.1,20.9,57.1,20.9,57.1,20.9C57.1,21,57.1,21,57.1,20.9C57.1,21,57.1,21,57.1,20.9z M28.6,29.4c-0.6-0.9-1.4-2.1-1.3-3.2
           c0-0.1,0-0.1,0.1-0.2c0,0,0-0.1,0.1-0.1c0.1-0.1,0.2-0.2,0.3-0.2c0,0,0,0,0.1,0c0.1,0,0.1,0,0.2,0c0,0,0,0,0.1,0
           c0.2,0.1,0.3,0.2,0.5,0.3c0.1,0.2,0.2,0.4,0.4,0.5c-0.1,1.2,0,2.4,0.1,3.7C28.8,29.8,28.7,29.6,28.6,29.4z M27.3,51.5
           C27.3,51.5,27.3,51.5,27.3,51.5L27.3,51.5C27.3,51.5,27.3,51.5,27.3,51.5z M51.3,52.7c0,5.5-6.4,7.5-10.6,8c0.1,0,0.1,0,0.2,0
           c-5.5,0.6-10.3-3.6-11.4-8.9c0.1-0.1,0.1-0.2,0.2-0.3c0.3-0.3,0.5-0.5,0.8-0.7c0.1,0,0.3-0.1,0.4-0.1c0.1,0.2,0.3,0.3,0.5,0.3
           c-0.3,1.3,2.7,4,3.5,4.6c1.8,1.3,4,2.1,6.3,2.1c3.7-0.1,8-3.4,8.5-7.2c0,0,0.1,0,0.1-0.1c0.5,0.3,1,0.4,1.6,0.7c0,0,0,0,0,0
           c0,0,0,0,0,0c0,0.1,0,0.2,0.1,0.2c0,0.2,0,0.3,0,0.5c0,0,0,0,0-0.1C51.4,52.2,51.3,52.4,51.3,52.7z"
          />
          <path
            d="M18.3,73.4c-0.2-0.6-0.4-1.1-0.6-1.7c-0.2-0.8-0.3-3.4-1.8-2.8c-0.4-0.3-1.1,0.1-1,0.6c0,0.1,0,0.1,0,0.1
           c0.5,1.9,1.1,4.1,1.9,6C18.4,78,18.5,74,18.3,73.4z"
          />
          <path d="M65.9,73.4c0-0.1,0.1-0.1,0.1-0.2C66,73.3,66,73.3,65.9,73.4z" />
          <path
            d="M68.1,71.2c-0.2-0.7-1-0.5-1.2,0.1c-0.4,0.4-0.5,1.1-0.7,1.6c0,0,0-0.1,0-0.1c0,0.1-0.1,0.1-0.1,0.2c0,0,0,0,0,0
           c0,0.1-0.1,0.2-0.1,0.2c0-0.1,0.1-0.1,0.1-0.2c-0.1,0.3-0.2,0.6-0.4,0.8c0-0.1,0.1-0.1,0.1-0.2c0,0.1-0.1,0.1-0.1,0.2c0,0,0,0,0,0
           c0,0.1,0,0.1-0.1,0.2c0,0,0,0,0-0.1c-0.6,1.4-1.4,3-1.5,4.6c0,0,0,0,0,0c0,2.5,1.9-0.5,2.1-0.9c0.7-1.4,1.2-3,1.7-4.5
           C68.4,72.6,68.5,71.6,68.1,71.2z"
          />
          <path d="M66.2,72.9C66.2,72.9,66.1,72.9,66.2,72.9C66.1,72.9,66.2,72.9,66.2,72.9z" />
          <path d="M43.9,44.4c0,0-0.1,0.1-0.1,0.1C43.9,44.5,43.9,44.4,43.9,44.4z" />
        </g>
      </svg>
    );
  }
}

export default Member1;
