import React from "react";
import TrackVisibility from "react-on-screen";
import Form from "./form";
import Row from "./row";
import Col from "./col";
import axios from "axios";
import * as yup from "yup";

class FormEnquiry extends Form {
  state = {
    data: {
      name: "",
      email: "",
      company: "",
      subject: "",
      message: ""
    },
    errors: {},
    focus: {},
    sending: false,
    mailSent: false,
    mailError: false
  };
  schema = yup.object().shape({
    name: yup.string().required("Please enter your name"),
    email: yup
      .string()
      .email()
      .required("Please enter your email"),
    company: yup.string().required("Please enter your company's name"),
    subject: yup.string().required("Please enter a subject"),
    message: yup.string().required("Please enter your message")
  });
  doSubmit = async () => {
    this.setState({ sending: true });
    try {
      const bodyData = new FormData()
      for (const key of Object.keys(this.state.data)) {
        bodyData.append(key, this.state.data[key])
      }
      const result = await axios({
        method: "post",
        // url: "http://localhost:8080/contact-form-submit.php",
        url: "contact-form-submit.php",
        // headers: { "content-type": "application/json" },
        data: bodyData
      });
      this.setState({
        mailSent: result.data.sent,
        data: {
          name: "",
          email: "",
          company: "",
          subject: "",
          message: ""
        },
        sending: false
      });
    } catch (error) {
      this.setState({ mailError: error.message, sending: false });
    }
  };
  render() {
    const { mailSent, mailError } = this.state;
    return (
      <TrackVisibility once partialVisibility>
        {({ isVisible }) => (
          <form
            onSubmit={this.handleSubmit}
            className={isVisible ? "in-view" : ""}
          >
            <Row>
              <Col span={6}>
                {this.renderInput("name", "Name *")}
                {this.renderInput("email", "Email *", "email")}
              </Col>
              <Col span={6}>
                {this.renderInput("company", "Company *")}
                {this.renderInput("subject", "Subject *")}
              </Col>
            </Row>
            {this.renderTextarea("message", "Message *")}
            {mailSent && this.renderMsgSuccess()}
            {mailError && this.renderMsgFailed()}
            {this.renderSubmitButton("Send")}
          </form>
        )}
      </TrackVisibility>
    );
  }
}

export default FormEnquiry;
