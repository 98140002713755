import React, { Component } from "react";
import MoodboardThumb from "../../images/portfolio/moodboard-thumb.jpg";
import MoodboardFull from "../../images/portfolio/moodboard.gif";

class PortfolioMoodboard extends Component {
  state = {};
  render() {
    return (
      <div className="portfolio-img animate-item">
        <div className="portfolio-thumb">
          <img src={MoodboardThumb} alt="Moodboard" />
        </div>
        <div className="portfolio-full">
          <img src={MoodboardFull} alt="Moodboard" />
        </div>
      </div>
    );
  }
}

export default PortfolioMoodboard;
